import { Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { UserInfo } from 'app/lib/components/UserInfo'
import { UserAccountDetails } from 'app/models/scribe.models'

import { EmptyStateContainer } from '../user-profile/UserInfoTable'

type Props = {
  userAccountDetails: UserAccountDetails | undefined
}

export const UserAccount = ({ userAccountDetails }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 4, mt: 8 }}>
        <Typography variant="h2">{t('userAccount.title')}</Typography>
      </Stack>
      {userAccountDetails ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xxl={4}>
            <UserInfo userAccountDetails={userAccountDetails} />
          </Grid>
        </Grid>
      ) : (
        <EmptyStateContainer>
          <Typography variant="body1">{t('userAccount.emptyStateMessage')}</Typography>
        </EmptyStateContainer>
      )}
    </>
  )
}
