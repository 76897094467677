import React from 'react'

import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import InputFormField from 'app/lib/components/form/InputFormField'
import { OrganizationRoute, OrganizationReportingColumns } from 'app/models/scribe.models'

import { FormRow } from './FormRow'

type AttributeSchema = {
  [key: string]: string
}

interface ReportingColumnsProfileProps {
  name: string
  attributeSchema: AttributeSchema
}

export const ReportingColumnsProfile: React.FC<ReportingColumnsProfileProps> = ({
  name,
  attributeSchema,
}) => {
  const { t } = useTranslation()
  const { organizationId } = useParams() as OrganizationRoute
  const { control } = useFormContext()
  const reportingColumnLabels = [
    OrganizationReportingColumns.REPORTING_COLUMN_ONE,
    OrganizationReportingColumns.REPORTING_COLUMN_TWO,
    OrganizationReportingColumns.REPORTING_COLUMN_THREE,
    OrganizationReportingColumns.REPORTING_COLUMN_FOUR,
    OrganizationReportingColumns.REPORTING_COLUMN_FIVE,
    OrganizationReportingColumns.REPORTING_COLUMN_SIX,
    OrganizationReportingColumns.REPORTING_COLUMN_SEVEN,
  ]
  const columnsToDisplay = reportingColumnLabels.map((column) => ({
    key: column,
    label: attributeSchema?.[column] || '',
  }))
  return (
    <Card>
      <CardContent sx={{ p: 3, mt: 2, ml: '38px' }}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box display="flex" alignItems="center" sx={{ paddingBottom: '25px' }}>
            <Typography variant="subtitle2" sx={{ minWidth: '250px' }}>
              {t('organizationPage.form.reportingColumns.organizationId')}
            </Typography>
            <Typography variant="body2">{organizationId}</Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ paddingBottom: '30px' }}>
            <Typography variant="subtitle2" sx={{ minWidth: '250px' }}>
              {t('organizationPage.form.reportingColumns.organizationName')}
            </Typography>
            <Typography variant="body2">{name}</Typography>
          </Box>
          <Box sx={{ paddingTop: '12px' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 700, fontSize: '12px' }}>
              {t('organizationPage.form.reportingColumns.reportingColumns')}
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={1} sx={{ paddingTop: '40px' }}>
          {columnsToDisplay.map((column, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <FormRow
                label={`${index + 1}.`}
                maxWidth
                field={
                  <Controller
                    name={`attributeSchema.${column.key}`}
                    control={control}
                    defaultValue={column.label}
                    render={({ field }) => (
                      <InputFormField
                        data-testid="input-reports"
                        {...field}
                        label=""
                        variant="outlined"
                        hideLabel
                      />
                    )}
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}
