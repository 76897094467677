import { useCallback } from 'react'

import {
  TableBody,
  TableCell,
  Table as MaterialTable,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { ClickableRow } from 'app/lib/components/ClickableRow'
import TableContent from 'app/lib/components/TableContent'
import { DEFAULT_PAGE_LIMIT } from 'app/lib/constants'
import { userProfile } from 'app/lib/routes'
import { formatDate } from 'app/lib/utils/date'
import { GetUsersParams } from 'app/models/api'
import { useGetUsersQuery } from 'app/redux/scribeApi'

export type HeaderValues = {
  userId: string
  firstName: string
  lastName: string
  dateOfBirth: string
  email: string
  status: string
}

interface HeaderType {
  name: keyof HeaderValues
  width?: number
}

const HEADER_NAMES = [
  { name: 'userId', width: 275 },
  { name: 'firstName' },
  { name: 'lastName' },
  { name: 'dateOfBirth' },
  { name: 'email' },
  { name: 'status', width: 100 },
] as HeaderType[]

export const Table = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { t: tHeader } = useTranslation(undefined, {
    keyPrefix: 'usersList.table',
  })

  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const { search, offset } = Object.fromEntries(searchParams.entries()) as GetUsersParams
  const { data, isFetching } = useGetUsersQuery({ offset, search })

  const records = data?.data || []
  const page = Number(offset || 0) / DEFAULT_PAGE_LIMIT
  const count = data?.meta.totalItems || 0

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setSearchParams({
        offset: String(pageNumber * DEFAULT_PAGE_LIMIT),
        search: search || '',
      })
    },
    [setSearchParams, search],
  )

  const cells = HEADER_NAMES.map(({ name, width }) => (
    <TableCell key={name} sx={{ width }} align="left">
      {tHeader(name)}
    </TableCell>
  ))

  const rows = records.map(({ id, firstName, lastName, dateOfBirth, email, authId }) => (
    <ClickableRow key={id} to={userProfile.get(id)}>
      <TableCell>{id}</TableCell>
      <TableCell sx={{ maxWidth: 100, overflowWrap: 'break-word' }}>
        <Typography variant="subtitle2">{firstName}</Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: 100, overflowWrap: 'break-word' }}>
        <Typography variant="subtitle2">{lastName}</Typography>
      </TableCell>
      <TableCell>{dateOfBirth && formatDate(dateOfBirth, resolvedLanguage)}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        {authId ? t('userProfile.info.signedUp') : t('userProfile.info.notSignedUp')}
      </TableCell>
    </ClickableRow>
  ))

  return (
    <TableContent
      isLoading={isFetching}
      count={records.length}
      notFound={t('usersList.noResults.noUsersFound')}
      searchNoMatch={t('usersList.noResults.searchNoMatch')}
      iconName="no_member_found"
    >
      <MaterialTable>
        <TableHead>
          <TableRow>{cells}</TableRow>
        </TableHead>
        <TableBody data-testid="data-table">{rows}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={DEFAULT_PAGE_LIMIT}
              rowsPerPageOptions={[]}
              count={count}
              showFirstButton
              showLastButton
              onPageChange={(_event, pageValue) => onPageChange(pageValue)}
              getItemAriaLabel={(type) => t(`global.pagination.${type}`)}
            />
          </TableRow>
        </TableFooter>
      </MaterialTable>
    </TableContent>
  )
}
