import { useCallback } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { HttpError, HttpErrorType } from 'app/lib/legacy-api/helpers'

type Props = Readonly<{
  email: string
  open: boolean
  removeAdmin: () => Promise<void>
  onClose: () => void
}>

export const RemoveDialog: React.FC<Props> = ({ email, open, removeAdmin, onClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = useCallback(() => {
    removeAdmin().catch((err: HttpError) => {
      if (err.type === HttpErrorType.CLIENT) {
        enqueueSnackbar(t('global.clientError'), {
          variant: 'warning',
        })
      } else {
        enqueueSnackbar(t('global.unknownError'), {
          variant: 'error',
        })
      }
    })
  }, [removeAdmin, enqueueSnackbar, t])

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        {t('organizationProfileAdmins.removeDialog.title', { email: email })}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t('organizationProfileAdmins.removeDialog.areYouSure', { email: email })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('global.dialog.cancel')}
        </Button>
        <Button
          data-testid={'confirm-remove-admin-button'}
          variant="contained"
          type="submit"
          onClick={onSubmit}
        >
          {t('global.dialog.remove')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
