import { useCallback } from 'react'

import { Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { BasicDialog } from 'app/lib/components/dialogs'
import { EligibilityRecord } from 'app/models/scribe.models'
import { useResendMemberInvitationEmailMutation } from 'app/redux/scribeApi'

interface Props {
  open: boolean
  onClose: () => void
  record: EligibilityRecord
}

export const ResendEmailDialog = ({ open, record, onClose }: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [resendWelcomeEmail, { isLoading }] = useResendMemberInvitationEmailMutation()

  const { attributes, id, organization } = record
  const { firstName, lastName, uniqueIdentifier } = attributes
  const name = `${firstName} ${lastName}`

  const handleConfirm = useCallback(async () => {
    const body = {
      email: uniqueIdentifier,
      eligibilityId: id,
      brandId: organization.brandId,
    }
    await resendWelcomeEmail({ body })
      .unwrap()
      .then(() =>
        enqueueSnackbar(t('resendWelcomeEmail.successfulQueryMember'), { variant: 'success' }),
      )
      .catch(() => enqueueSnackbar(t('resendWelcomeEmail.errorQuery'), { variant: 'error' }))

    onClose()
  }, [id, organization, uniqueIdentifier, enqueueSnackbar, onClose, resendWelcomeEmail, t])

  return (
    <BasicDialog
      open={open}
      title={t('resendWelcomeEmail.dialog.title')}
      confirmText={t('resendWelcomeEmail.dialog.submit')}
      onClose={onClose}
      onConfirm={handleConfirm}
      loading={isLoading}
    >
      <Typography variant="body2" mb={1}>
        {t('resendWelcomeEmail.dialog.confirmationOneMember', {
          name,
        })}
      </Typography>
    </BasicDialog>
  )
}
