import { PropsWithChildren } from 'react'

import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'

let getAccessTokenSilentlyFunction: (
  options?: GetTokenSilentlyOptions | undefined,
) => Promise<string>

export const tokenProvider = {
  getAccessTokenSilently: () => getAccessTokenSilentlyFunction,
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  ) => (getAccessTokenSilentlyFunction = func),
}

export const TokenProvider = (props: PropsWithChildren<{}>) => {
  const { getAccessTokenSilently } = useAuth0()
  // set getAccessTokenSilently in global module to reuse it outside a React component
  tokenProvider.setAccessTokenSilently(getAccessTokenSilently)

  return <>{props.children}</>
}
