import { useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import {
  Box,
  Breakpoint,
  Button,
  Card,
  CardContent,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InferType, array, boolean, mixed, object, string } from 'yup'

import CheckboxGroupFormField from 'app/lib/components/form/CheckBoxGroupFormField'
import { FormRow } from 'app/lib/components/form/FormRow'
import InputFormField from 'app/lib/components/form/InputFormField'
import RadioGroupFormField from 'app/lib/components/form/RadioGroupFormField'
import SelectFormField from 'app/lib/components/form/SelectFormField'
import { ALPHANUMERIC_REGEX, ScribePermission } from 'app/lib/constants'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { useBreakpoint } from 'app/lib/hooks/use-breakpoint'
import { getSortedArrayPerColumn } from 'app/lib/utils/helpers'
import { getCurrencySymbol } from 'app/lib/utils/regions'
import { slufigy } from 'app/lib/utils/slufigy'
import { numericSchema, requiredStringSchema } from 'app/lib/utils/yupSchemas'
import { ChargeStrategy, Cobrand, Feature } from 'app/models/scribe.models'

const CHARGE_STRATEGIES = Object.values(ChargeStrategy)

const planFormSchema = object({
  default: boolean().required(),
  name: requiredStringSchema.matches(ALPHANUMERIC_REGEX, 'planPage.errors.invalidName'),
  label: requiredStringSchema,
  model: mixed<ChargeStrategy>().oneOf(CHARGE_STRATEGIES).required('form.errors.required'),
  cobrand: string(),
  cost: numericSchema.min(0, 'planPage.errors.negativePrice').required(),
  services: array(string().required()).required(),
})

export type PlanFormSchema = InferType<typeof planFormSchema>

type Column = {
  label: string
  value: string | undefined
}

const defaultValues: Partial<PlanFormSchema> = {
  default: false,
  name: '',
  label: '',
  cost: 0,
  services: [],
  cobrand: '',
}

interface PlanFormProps {
  onCancel: () => void
  onSubmit: (values: PlanFormSchema) => void
  onRemove?: () => void
  onArchive?: () => void
  availableFeatures: Feature[]
  initialValues?: PlanFormSchema
  coBrandResponse?: Cobrand[]
}

type CobrandOption = {
  label: string
  value: string
}

const attributesColsCount: { [key in Breakpoint]: number } = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 3,
}

const gridCols = 12

const currencySymbol = getCurrencySymbol()

export const PlanForm: React.FC<PlanFormProps> = ({
  initialValues = defaultValues,
  availableFeatures,
  onSubmit,
  onRemove,
  onArchive,
  onCancel,
  coBrandResponse,
}) => {
  const formMethods = useForm<PlanFormSchema>({
    defaultValues: { ...defaultValues, ...initialValues },
    resolver: yupResolver(planFormSchema),
  })
  const {
    formState: { isDirty },
    handleSubmit,
    watch,
    setValue,
  } = formMethods
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()

  const isEdit = Boolean(initialValues.name)

  const userPermissions = useUserPermissions()
  const canViewCoBrandField = userPermissions?.includes(ScribePermission.CREATE_PLAN)

  const watchDefault = watch('default')
  const isChangingDefaultPlan = useMemo(
    () => !initialValues.default && watchDefault,
    [initialValues.default, watchDefault],
  )

  // Autofill plan label while typing in plan name (Only at plan creation)
  const watchName = watch('name').trim()
  useEffect(() => {
    !isEdit && setValue('label', slufigy(watchName))
  }, [isEdit, watchName, setValue])

  const planOptions = [
    { label: t('global.yes'), value: true },
    { label: t('global.no'), value: false },
  ]

  const currentBreakpoint = useBreakpoint()

  const featureItems = useMemo(
    () =>
      getSortedArrayPerColumn(
        availableFeatures
          .map(({ label, localizedName }) => ({
            label: localizedName[resolvedLanguage] ?? label,
            value: label,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        attributesColsCount[currentBreakpoint],
      ),
    [availableFeatures, resolvedLanguage, currentBreakpoint],
  )

  const strategyOptions: Column[] = useMemo(
    () =>
      CHARGE_STRATEGIES.map((strategy) => ({
        value: strategy,
        label: t(`global.chargeStrategyStatus.${strategy}`),
      })),
    [t],
  )

  const transformCoBrandData = (data: Cobrand[] = []): CobrandOption[] => {
    return data.map((item: Cobrand) => ({
      label: item.attributes.name,
      value: item.id,
    }))
  }

  const coBrandOptions: Column[] = useMemo(() => {
    return transformCoBrandData(coBrandResponse)
  }, [coBrandResponse])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2.5} py={2}>
            <Typography variant="h2">
              {isEdit
                ? t(`planPage.title.edit`, { name: initialValues.name })
                : t('planPage.title.new')}
            </Typography>
            <Stack direction="row" justifyItems="flex-end" spacing={2}>
              <Button variant="outlined" onClick={onCancel}>
                {t('global.dialog.cancel')}
              </Button>
              <Button
                type="submit"
                key="preventDoubleSubmit"
                variant="contained"
                color="primary"
                data-testid="save-button"
                disabled={!isDirty}
              >
                {t('actions.save')}
              </Button>
            </Stack>
          </Stack>
          <Card>
            <CardContent>
              <FormRow
                label={t('planPage.isDefault')}
                field={
                  <RadioGroupFormField
                    name="default"
                    options={planOptions}
                    type="boolean"
                    helperText={isChangingDefaultPlan ? t('planPage.isChangingDefaultPlan') : ''}
                    optionProps={{ mr: 8 }}
                    row
                  />
                }
              />
              <FormRow
                label={t('planPage.planName')}
                field={
                  <InputFormField
                    name="name"
                    label={t('planPage.planName')}
                    variant="outlined"
                    hideLabel
                  />
                }
              />
              <FormRow
                label={t('planCard.chargingModel')}
                field={
                  <SelectFormField
                    name="model"
                    label={t('planCard.chargingModel')}
                    variant="outlined"
                    options={strategyOptions}
                    hideLabel
                  />
                }
              />
              <FormRow
                label={t('planCard.cost')}
                field={
                  <InputFormField
                    name="cost"
                    type="number"
                    label={t('planCard.cost')}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{currencySymbol}</InputAdornment>
                      ),
                    }}
                    hideLabel
                  />
                }
              />
              {canViewCoBrandField && (
                <FormRow
                  label={t('planCard.coBrand')}
                  field={
                    <SelectFormField
                      data-testid="select-cobrand"
                      name="cobrand"
                      label={t('planCard.coBrand')}
                      variant="outlined"
                      options={coBrandOptions}
                      hideLabel
                    />
                  }
                />
              )}
              <FormRow
                label={t('planCard.planLabel')}
                field={
                  <InputFormField
                    name="label"
                    label={t('planCard.planLabel')}
                    variant="outlined"
                    hideLabel
                  />
                }
              />
              <FormRow
                align="top"
                fullWidthField
                label={t('planCard.planServices')}
                field={
                  <CheckboxGroupFormField
                    name="services"
                    options={featureItems}
                    optionGridProps={{
                      xs: gridCols / attributesColsCount.xs,
                      md: gridCols / attributesColsCount.md,
                      xl: gridCols / attributesColsCount.xl,
                    }}
                  />
                }
              />
              {isEdit && (
                <Box display="flex" justifyContent="end" gap={2}>
                  <Button
                    startIcon={<DeleteForeverIcon />}
                    variant="text"
                    color="error"
                    onClick={onRemove}
                  >
                    {t('organizationProfilePlans.buttons.delete')}
                  </Button>
                  <Button startIcon={<Inventory2OutlinedIcon />} variant="text" onClick={onArchive}>
                    {t('actions.archive')}
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Stack>
      </form>
    </FormProvider>
  )
}
