import { useCallback, useMemo } from 'react'

import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import { Button, ButtonProps, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { PlanCard } from 'app/lib/components/plans/PlanCard'
import { ScribePermission } from 'app/lib/constants'
import withProtectedComponent from 'app/lib/hoc/withProtectedComponent'
import { organizationAddPlan, organizationEditPlan } from 'app/lib/routes'
import { getDefaultAndOtherPlans, isArchived } from 'app/models/Organization'
import { Plan } from 'app/models/scribe.models'
import { useLegacyGetOrganizationPlansQuery, useUpdatePlanMutation } from 'app/redux/scribeApi'

interface Props {
  organizationId: string
}

const ProtectedButton = withProtectedComponent<ButtonProps>(Button)

export const OrganizationPlans: React.FC<Props> = ({ organizationId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: plans = [] } = useLegacyGetOrganizationPlansQuery(organizationId)
  const [updatePlan] = useUpdatePlanMutation()

  const [defaultPlan, otherPlans = []] = useMemo(() => getDefaultAndOtherPlans(plans), [plans]) as [
    Plan,
    Plan[],
  ]
  const nonArchivedPlans = otherPlans.filter((el) => !isArchived(el))
  const archivedPlans = otherPlans.filter(isArchived)
  const orderedOtherPlans = [...nonArchivedPlans, ...archivedPlans]

  const hasPlans = plans?.length > 0

  const navigateToAddPlanPage = useCallback(() => {
    navigate(organizationAddPlan.get(organizationId))
  }, [navigate, organizationId])

  const navigateToEditPlan = useCallback(
    (planId: string) => {
      navigate(organizationEditPlan.get(organizationId, planId))
    },
    [navigate, organizationId],
  )

  const onUnArchive = useCallback(
    (planId: string) => {
      // Update plan period to accept any eligible interval
      const body = { startDate: '-inf', endDate: 'inf' }
      return updatePlan({ planId, body }).unwrap()
    },
    [updatePlan],
  )
  const getPlanCard = useCallback(
    (plan: Plan) => {
      const isPlanArchived = isArchived(plan)

      const editButton = (
        <ProtectedButton
          permission={ScribePermission.UPDATE_PLAN}
          variant="outlined"
          data-testid={'edit-plan-' + plan.attributes.label}
          onClick={() => navigateToEditPlan(plan.id)}
        >
          {t('organizationProfilePlans.buttons.edit')}
        </ProtectedButton>
      )
      const unArchivePlan = (
        <Button
          startIcon={<Inventory2OutlinedIcon />}
          variant="text"
          onClick={() => onUnArchive(plan.id)}
        >
          {t('actions.unArchive')}
        </Button>
      )
      return (
        <PlanCard
          plan={plan}
          isArchived={isPlanArchived}
          actionButton={isPlanArchived ? unArchivePlan : editButton}
        />
      )
    },
    [navigateToEditPlan, t, onUnArchive],
  )

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        mb={2.5}
        py={2}
      >
        <Typography variant="h2">{t('organizationProfilePlans.title')}</Typography>
        <ProtectedButton
          permission={ScribePermission.CREATE_PLAN}
          variant="contained"
          onClick={navigateToAddPlanPage}
        >
          {t('organizationProfilePlans.buttons.add')}
        </ProtectedButton>
      </Stack>
      {hasPlans ? (
        <Grid container spacing={2}>
          {[defaultPlan, ...orderedOtherPlans].map((plan) => (
            <Grid data-testid="plan-item" item key={plan.id} xs={12} md={6} xxl={4}>
              {getPlanCard(plan)}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body2">{t('organizationProfilePlans.noPlans')}</Typography>
      )}
    </Stack>
  )
}
