import React, { useEffect } from 'react'

import { TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'app/lib/utils/date'
import { EligibilityRecord, Pagination } from 'app/models/scribe.models'
import { UserInfoTable } from 'app/pages/user-profile/UserInfoTable'

export const DEFAULT_LIMIT = 5

const TABLE_HEADERS = ['eligibleIntervals.startDate', 'eligibleIntervals.endDate'] as const

type TableProps = Readonly<{
  eligibleIntervals: EligibilityRecord['eligibleIntervals']
  pagination: Pagination
  setPagination: (params: Pagination) => void
}>

export const EligibleIntervals: React.FC<TableProps> = ({
  eligibleIntervals,
  pagination,
  setPagination,
}) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const cells = TABLE_HEADERS.map((i18nKey) => (
    <TableCell key={i18nKey} align="left" padding="normal">
      {t(i18nKey)}
    </TableCell>
  ))

  useEffect(() => {
    if (eligibleIntervals.length !== pagination.total) {
      setPagination({ ...pagination, total: eligibleIntervals.length })
    }
  }, [eligibleIntervals.length, pagination.total, pagination, setPagination])

  const paginatedData = eligibleIntervals.slice(
    pagination.offset,
    pagination.offset + pagination.limit,
  )

  const rows = paginatedData.map((interval) => (
    <TableRow key={interval.planId}>
      <TableCell>{formatDate(interval.startDate, resolvedLanguage)}</TableCell>
      <TableCell>{formatDate(interval.endDate, resolvedLanguage)}</TableCell>
    </TableRow>
  ))
  return (
    <UserInfoTable
      cells={cells}
      rows={rows}
      pagination={pagination}
      setPagination={setPagination}
      hasEntries={eligibleIntervals.length > 0}
      tableTitle={t('eligibleIntervals.header')}
      emptyStateMessage={t('eligibleIntervals.emptyStateMessage')}
    />
  )
}
