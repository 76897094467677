import { useCallback } from 'react'

import { useNavigate, useParams } from 'react-router'

import Page from 'app/layout/Page'
import { organizationProfile } from 'app/lib/routes'
import { PostPlanBody } from 'app/models/Organization'
import { OrganizationRoute } from 'app/models/scribe.models'
import {
  useAddOrganizationPlanMutation,
  useGetCoBrandsQuery,
  useGetFeaturesQuery,
} from 'app/redux/scribeApi'

import { PlanForm, PlanFormSchema } from './PlanForm'

export const AddPlanPage: React.FC = () => {
  const navigate = useNavigate()
  const { organizationId } = useParams() as OrganizationRoute
  const { data: coBrandsResponse } = useGetCoBrandsQuery({})
  const { data: features } = useGetFeaturesQuery({})

  const [addPlan, { isLoading }] = useAddOrganizationPlanMutation()

  const navigateToOrganizationProfile = useCallback(() => {
    navigate(organizationProfile.get(organizationId))
  }, [navigate, organizationId])

  const onSubmit = useCallback(
    (params: PlanFormSchema) => {
      const addPlanBody: PostPlanBody = {
        type: 'plan',
        attributes: {
          nameEn: params.name,
          nameFr: params.name,
          descriptionEn: params.name,
          descriptionFr: params.name,
          label: params.label,
          chargePrice: params.cost,
          chargeStrategy: params.model,
          isDefault: params.default,
          features: params.services,
        },
      }

      if (params.cobrand) {
        addPlanBody.relationships = {
          cobrand: { data: { id: params.cobrand, type: 'cobrand' } },
        }
      }

      addPlan({ organizationId, body: { data: addPlanBody } })
        .unwrap()
        .then(navigateToOrganizationProfile)
    },
    [organizationId, addPlan, navigateToOrganizationProfile],
  )

  return (
    <Page isLoading={isLoading}>
      <PlanForm
        onCancel={navigateToOrganizationProfile}
        onSubmit={onSubmit}
        availableFeatures={features || []}
        coBrandResponse={coBrandsResponse}
      />
    </Page>
  )
}
