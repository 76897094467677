import { useMediaQuery, useTheme } from '@mui/material'

export const useBreakpoint = () => {
  const theme = useTheme()
  const {
    breakpoints: { only },
  } = theme
  const mqXs = useMediaQuery(only('xs'))
  const mqSm = useMediaQuery(only('sm'))
  const mqMd = useMediaQuery(only('md'))
  const mqLg = useMediaQuery(only('lg'))
  const mqXl = useMediaQuery(only('xl'))
  const mqXxl = useMediaQuery(only('xxl'))
  return (
    (mqXs && 'xs') ||
    (mqSm && 'sm') ||
    (mqMd && 'md') ||
    (mqLg && 'lg') ||
    (mqXl && 'xl') ||
    (mqXxl && 'xxl') ||
    'xs'
  )
}
