import React, { PropsWithChildren } from 'react'

import { Box, Stack, Typography } from '@mui/material'
// import { Trans, useTranslation } from 'react-i18next'

import { colors } from 'app/theme'

const style = {
  backgroundColor: colors.dialogueLinen,
  borderRadius: 4,
  marginBottom: '2.5rem',
  boxShadow: '0px 2px 4px 0px #F1F1F1',
}

export const Banner = ({ children }: PropsWithChildren) => (
  <Box style={style} padding={2}>
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Typography variant="subtitle2" align="center">
        {children}
      </Typography>
    </Stack>
  </Box>
)

// Left so that we don't lose what pages this banner is usually rendered on
// But empty because we don't want to show any content.
export const HealthSessionsBanner: React.FC = () => {
  return <></>
}

// EXAMPLE OF HOW TO USE THE BANNER.
// 1. Copy the below component, and replace the name/variables
// 2. Update localization files (en/fr) - "banner" object needs the following:
//       SPECIFICBANNERNAME: { copy: "", btnLink: "" }
// 3. Search app for last used Banner (<HealthSessionsBanner />)
// 4. Replace all instances from above with your new banner (if you want)

// export const SpecificBannerNameHere: React.FC = () => {
//   const { t } = useTranslation()
//
//   return (
//     <Banner>
//       <Trans
//         t={t}
//         i18nKey="banner.SPECIFICBANNERNAME.copy"
//         components={[
//           <Link
//             key={0}
//             href={t('banner.SPECIFICBANNERNAME.btnLink')}
//             target="_blank"
//             // @ts-ignore The variant exists stop
//             variant="subtitleLink"
//           >
//             btn
//           </Link>,
//         ]}
//       />
//     </Banner>
//   )
// }
