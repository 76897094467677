import { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CollapsibleResults } from 'app/lib/components/CollapsibleResults'
import { ProcessingResults } from 'app/lib/utils/process-directives'

import { MemberActivationDate } from './MemberActivationDate'

interface Props {
  activationDate: Date | null
  onActivationDateChange: (date: Date | null) => void
  sendEmailEnabled: boolean
  processingResults: ProcessingResults
  activationMinDate: Date
  onNext: () => void
  onClose: () => void
  onBack: () => void
}

export const Preview: React.FC<Props> = ({
  activationDate,
  onActivationDateChange,
  sendEmailEnabled,
  processingResults,
  activationMinDate,
  onNext,
  onBack,
  onClose,
}) => {
  const [isValid, setIsValid] = useState(true)

  const { t } = useTranslation()

  return (
    <>
      <DialogTitle>
        {t('csvImport.preview.title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CollapsibleResults processingResults={processingResults} />
        <Stack mt={2}>
          <Typography variant="caption1" color="dimgrey">
            {t('csvImport.preview.activationDate.label')}
          </Typography>
          <Typography variant="caption2" color="dimgrey">
            {t('csvImport.preview.activationDate.description')}
          </Typography>
          <MemberActivationDate
            activationDate={activationDate}
            onActivationDateChange={onActivationDateChange}
            activationMinDate={activationMinDate}
            onValidate={setIsValid}
          />
          <Typography variant="caption2" color="dimgrey">
            {sendEmailEnabled
              ? t('csvImport.preview.sendNewMembersAnInviteAllowed')
              : t('csvImport.preview.sendNewMembersAnInviteNotAllowed')}
          </Typography>
        </Stack>
        <DialogActions>
          <Button onClick={onBack}>{t('global.dialog.back')}</Button>
          <Button
            onClick={onNext}
            variant="contained"
            data-testid="continue-button"
            disabled={!isValid}
          >
            {t('global.dialog.continue')}
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  )
}
