import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props extends DialogProps {
  title: string
  confirmText: string
  loading?: boolean
  onClose: () => void
  onConfirm: () => void
}

const BasicDialog = ({
  title,
  confirmText,
  onClose,
  onConfirm,
  loading,
  children,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  return (
    <Dialog fullWidth maxWidth="md" {...rest}>
      <DialogContent sx={{ p: 3 }}>
        <Typography variant="h3" mb={3} display="flex" justifyContent="space-between">
          {title}
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Typography>
        {children}
      </DialogContent>
      <DialogActions sx={{ px: 4, pb: 4, pt: 0 }}>
        <Button variant="outlined" onClick={onClose}>
          {t('global.dialog.cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={onConfirm}
          key="preventDoubleSubmit"
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default BasicDialog
