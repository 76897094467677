import {
  TableBody,
  TableCell,
  Table as MaterialTable,
  TableFooter,
  TablePagination,
  TableRow,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ClickableRow } from 'app/lib/components/ClickableRow'
import { StatusBox } from 'app/lib/components/StatusBox'
import { formatDate } from 'app/lib/utils/date'
import { EligibilityRecord } from 'app/models/scribe.models'

import { DEFAULT_LIMIT } from './index'

interface TableDisplayProps {
  records: ReadonlyArray<EligibilityRecord>
  page: number
  onPageChange: (page: number) => void
  count: number
}

export type HeaderValues = {
  uniqueIdentifier: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  province?: string
  activationDate: string
  deactivationDate: string
  status: string
}

interface HeaderType {
  name: keyof HeaderValues
  width?: number
}

const HEADER_NAMES = [
  { name: 'uniqueIdentifier', width: 275 },
  { name: 'firstName' },
  { name: 'lastName' },
  { name: 'organizations' },
  { name: 'dateOfBirth' },
  { name: 'activationDate' },
  { name: 'deactivationDate' },
  { name: 'status', width: 100 },
] as HeaderType[]

export const Table = ({ records, page, onPageChange, count }: TableDisplayProps) => {
  const { t: tHeader } = useTranslation(undefined, {
    keyPrefix: 'eligibilityRecordListPage.eligibilityRecordsTable.headers',
  })
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()

  const cells = HEADER_NAMES.map(({ name, width }) => (
    <TableCell key={name} sx={{ width }} align="left">
      {tHeader(name)}
    </TableCell>
  ))

  const rows = records.map(
    ({ id, organizationId, attributes, eligibleIntervals, status, organization }) => (
      <ClickableRow key={id} to={`/organizations/${organizationId}/members/${id}`}>
        <TableCell
          title={attributes.uniqueIdentifier}
          sx={{ maxWidth: 275, overflowWrap: 'break-word' }}
        >
          {attributes.uniqueIdentifier}
        </TableCell>
        <TableCell sx={{ maxWidth: 100, overflowWrap: 'break-word' }}>
          <Typography variant="subtitle2">{attributes.firstName}</Typography>
        </TableCell>
        <TableCell sx={{ maxWidth: 100, overflowWrap: 'break-word' }}>
          <Typography variant="subtitle2">{attributes.lastName}</Typography>
        </TableCell>
        <TableCell sx={{ maxWidth: 100, overflowWrap: 'break-word' }}>
          <Typography variant="subtitle2">{organization?.name}</Typography>
        </TableCell>
        <TableCell>
          {attributes.dateOfBirth && formatDate(attributes.dateOfBirth, resolvedLanguage)}
        </TableCell>
        <TableCell>
          {formatDate(eligibleIntervals[eligibleIntervals.length - 1].startDate, resolvedLanguage)}
        </TableCell>
        <TableCell>
          {formatDate(eligibleIntervals[eligibleIntervals.length - 1].endDate, resolvedLanguage)}
        </TableCell>
        <TableCell>
          <StatusBox status={status} />
        </TableCell>
      </ClickableRow>
    ),
  )

  return (
    <TableContainer>
      <MaterialTable>
        <TableHead>
          <TableRow>{cells}</TableRow>
        </TableHead>
        <TableBody data-testid="eligibility-record-table">{rows}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={DEFAULT_LIMIT}
              rowsPerPageOptions={[]}
              count={count}
              showFirstButton
              showLastButton
              onPageChange={(_event, pageValue) => onPageChange(pageValue)}
              getItemAriaLabel={(type) => t(`global.pagination.${type}`)}
              labelDisplayedRows={({ from, to, count: itemsCount }) =>
                itemsCount > 0
                  ? t('global.pagination.of', { from, to, count: itemsCount })
                  : t('global.pagination.of_more_than', { from, to })
              }
            />
          </TableRow>
        </TableFooter>
      </MaterialTable>
    </TableContainer>
  )
}
