import { useState } from 'react'

import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  services: string[]
}

export const PlanServices = ({ services }: Props) => {
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(true)

  const handleToggleCollapse = () => setCollapsed((state) => !state)

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2">
        {t('planCard.servicesAmount', { count: services.length })}
      </Typography>
      {!collapsed && (
        <List
          sx={{
            listStyleType: 'disc',
            pl: 3,
            pt: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          {services.map((s: string) => (
            <ListItem key={s} sx={{ pl: 0, py: 0 }}>
              <Typography variant="body2">{s}</Typography>
            </ListItem>
          ))}
        </List>
      )}
      {!!services.length && (
        <Button variant="link" onClick={handleToggleCollapse} sx={{ alignSelf: 'end' }}>
          {t(collapsed ? 'planCard.viewMoreServices' : 'planCard.viewLess')}
        </Button>
      )}
    </Box>
  )
}
