import React, { ReactElement } from 'react'

import { Box, Grid, Typography } from '@mui/material'

interface FormRowProps {
  label: string
  field: ReactElement
  fullWidthField?: boolean
  align?: string
}

export const FormRow: React.FC<FormRowProps> = ({
  label,
  field,
  fullWidthField = false,
  align = 'center',
}) => (
  <Grid container spacing={2} pb={2.5}>
    <Grid item xs={4} md={3} xl={2}>
      <Box display="flex" alignItems={align} height="100%">
        <Typography variant="subtitle2">{label}</Typography>
      </Box>
    </Grid>
    <Grid item xs={8} md={fullWidthField ? 8 : 6} xl={fullWidthField ? 8 : 5}>
      {React.cloneElement(field, { label, hideLabel: true })}
    </Grid>
  </Grid>
)
