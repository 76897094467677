import { useState } from 'react'

import HelpIcon from '@mui/icons-material/Help'
import LockIcon from '@mui/icons-material/Lock'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { LoadingBackdrop } from 'app/lib/components/LoadingBackdrop'

interface Props {
  open: boolean
  onSubmit: (tokenId: string) => Promise<void>
  onClose: () => void
}

// doc: https://stripe.com/docs/stripe-js/react
export const CreditCardForm: React.FC<Props> = ({ open, onSubmit, onClose }) => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const stripe = useStripe()
  const elements = useElements()
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = () => {
    setError('')
    const element = elements?.getElement(CardNumberElement)
    if (element && name) {
      setLoading(true)
      stripe
        ?.createToken(element, { name })
        .then((response) => {
          if (response.error) {
            setError(response.error.message ? response.error.message : response.error.type)
            return
          } else {
            return onSubmit(response.token.id)
              .then(() => {
                enqueueSnackbar(
                  t('organizationProfilePayment.snackbarMessage.cardSuccessfullyAdded'),
                  {
                    variant: 'success',
                  },
                )
                onClose()
              })
              .catch(() => {
                enqueueSnackbar(t('organizationProfilePayment.snackbarMessage.cardDeclined'), {
                  variant: 'error',
                })
              })
          }
        })
        .finally(() => setLoading(false))
    } else {
      setError('Missing element')
    }
  }

  return (
    <Dialog open={open} maxWidth="lg" onClose={onClose}>
      <LoadingBackdrop loading={loading} />
      <DialogTitle>{t('organizationProfilePayment.creditCardFormDialog.title')}</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <Box sx={{ pb: 2 }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" className="StripeInputBox-underline" sx={{ mr: 2 }}>
              <Box sx={{ width: 248, mr: 2 }}>
                <CardNumberElement />
              </Box>
              <LockIcon fontSize="small" color="disabled" />
            </Box>
            <Box className="StripeInputBox-underline" sx={{ width: 64, mr: 2 }}>
              <CardExpiryElement />
            </Box>
            <Box display="flex" className="StripeInputBox-underline">
              <Box sx={{ width: 64 }}>
                <CardCvcElement />
              </Box>
              <Tooltip
                title={t('organizationProfilePayment.creditCardFormDialog.securityCodeBackCard')}
              >
                <HelpIcon fontSize="small" color="disabled" />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <TextField
          label={t('organizationProfilePayment.creditCardFormDialog.nameOnCard')}
          required
          value={name}
          onChange={(e) => setName(e.target.value.trimStart())}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('global.dialog.cancel')}</Button>
        <Button onClick={handleSubmit} disabled={!stripe || !name} sx={{ color: 'secondary.main' }}>
          {t('organizationProfilePayment.creditCardFormDialog.registerCard')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
