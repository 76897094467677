import React from 'react'

import { TableCell, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Pagination, UserEpisodesData } from 'app/models/scribe.models'

import { UserInfoTable } from './UserInfoTable'

const TABLE_HEADERS = ['userProfile.episodes.date', 'userProfile.episodes.status'] as const

type TableProps = Readonly<{
  episodesData: UserEpisodesData[]
  pagination: Pagination
  setPagination: (params: Pagination) => void
}>

const InactiveState = 'Inactive'

export const Episodes: React.FC<TableProps> = ({ episodesData, pagination, setPagination }) => {
  const { t } = useTranslation()

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.toISOString().split('T')[0]
  }

  const cells = TABLE_HEADERS.map((i18nKey) => (
    <TableCell key={i18nKey} align="left" padding="normal">
      {t(i18nKey)}
    </TableCell>
  ))

  const rows = episodesData.map((episode: UserEpisodesData) => (
    <TableRow key={episode.id}>
      <TableCell>
        <Typography variant="subtitle2">{formatDate(episode.updatedAt)}</Typography>
      </TableCell>
      <TableCell>{episode.state || InactiveState}</TableCell>
    </TableRow>
  ))

  return (
    <UserInfoTable
      cells={cells}
      rows={rows}
      emptyStateMessage={t('userProfile.episodes.emptyStateMessage')}
      pagination={pagination}
      setPagination={setPagination}
      hasEntries={episodesData.length > 0}
      tableTitle={t('userProfile.episodes.episodesHeader')}
    />
  )
}
