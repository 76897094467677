import { ReactNode, isValidElement, useCallback } from 'react'

import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export type Attribute = {
  name: string
  value: string | string[] | boolean | number | ReactNode | null | undefined
  highlighted?: boolean
  key: string
}

type AttributeTableProps = Readonly<{
  attributes: Array<Attribute>
  width?: string
}>

export const AttributeTable: React.FC<AttributeTableProps> = ({ attributes, width = '80%' }) => {
  const { t } = useTranslation()

  const formatAttributeValue = useCallback(
    (value: Attribute['value']) => {
      switch (typeof value) {
        case 'boolean':
          return value ? t('global.yes') : t('global.no')
        case 'number':
          return value.toString()
        case 'string':
          return value
        default:
          return ''
      }
    },
    [t],
  )

  const rows = attributes.map((attribute: Attribute) => {
    const { key, name, value, highlighted } = attribute

    return (
      <TableRow className={highlighted ? 'highlighted' : ''} key={key}>
        <TableCell
          className="InlineTableCell"
          sx={{ borderBottom: 'none', width: '40%', verticalAlign: 'top' }}
        >
          <Typography variant="subtitle2">{name}</Typography>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          {isValidElement(value) ? (
            value
          ) : (
            <Typography variant="body2">{formatAttributeValue(value)}</Typography>
          )}
        </TableCell>
      </TableRow>
    )
  })

  return (
    <TableContainer>
      <Table sx={{ width }}>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  )
}
