import { ReactNode, useCallback, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { BlueBadge } from 'app/lib/components/BlueBadge'
import { ClickableRow } from 'app/lib/components/ClickableRow'
import { LoadingBackdrop } from 'app/lib/components/LoadingBackdrop'
import { userAddMember, userProfile } from 'app/lib/routes'
import { FamilyMember } from 'app/models/FamilyMembers'
import { EligibilityRecordStatus, FamilyMemberType } from 'app/models/scribe.models'
import {
  useLazyListEligibilityRecordsQuery,
  useRemoveFamilyMemberMutation,
} from 'app/redux/scribeApi'

import { ConfirmationDialog } from '../../lib/components/ConfirmationDialog'

import { EmptyStateContainer } from './UserInfoTable'

const NAME_KEY = 'userProfile.info.name'

const ADULT_TABLE_HEADERS = [
  NAME_KEY,
  'userProfile.info.userId',
  'userProfile.info.dateOfBirth',
  'userProfile.info.invitationEmail',
  'userProfile.info.guardian',
  '',
]

const CHILD_TABLE_HEADERS = [
  NAME_KEY,
  'userProfile.info.userId',
  'userProfile.info.dateOfBirth',
  '',
  '',
  '',
]

const largeColumnStyle = { width: 350 }
const columnStyle = { width: 180 }

interface MembersTableProps {
  tableHeaders: any[]
  children: ReactNode
  isLoading?: boolean
  testId: string
}

interface FamilyMembersProps {
  data: FamilyMember[]
  userId?: string
  isFamilyGuardian: boolean
}

const MembersTable = ({ tableHeaders, children, isLoading, testId }: MembersTableProps) => {
  const { t } = useTranslation()
  return (
    <Paper elevation={1} sx={{ position: 'relative' }} data-testid={testId}>
      {isLoading && <LoadingBackdrop loading={isLoading} />}
      <TableContainer>
        <Table>
          <colgroup>
            {tableHeaders.map((columnKey, index) => (
              <col style={columnKey === NAME_KEY ? largeColumnStyle : columnStyle} key={index} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              {tableHeaders.map((i18nKey, index) => (
                <TableCell key={index} align="left">
                  {t(i18nKey)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export const FamilyMembers = ({ data, userId = '', isFamilyGuardian }: FamilyMembersProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [removeAdult, { isLoading: isRemovingAdult }] = useRemoveFamilyMemberMutation({
    fixedCacheKey: FamilyMemberType.ADULT,
  })
  const [removeChild, { isLoading: isRemovingChild }] = useRemoveFamilyMemberMutation({
    fixedCacheKey: FamilyMemberType.CHILD,
  })
  const [fetchEligibilityRecords, { data: eligibilityRecords }] =
    useLazyListEligibilityRecordsQuery()

  const [primaryAcctHolders, setPrimaryAcctHolders] = useState<string[]>([])

  const membersWithoutSelf = data.filter((el) => el.id !== userId)
  const childMembers = membersWithoutSelf.filter((el) => el.type === FamilyMemberType.CHILD)
  const adultMembers = membersWithoutSelf.filter((el) => el.type !== FamilyMemberType.CHILD)

  const fetchRecords = useCallback(
    (participantId: number[]) => {
      const params = {
        participantId,
        status: EligibilityRecordStatus.ACTIVE,
      }

      fetchEligibilityRecords({ params })
    },
    [fetchEligibilityRecords],
  )

  // 1. We require eligibility records to determine which (if any) family members
  // are the primary account holder
  useEffect(() => {
    const participantIds = data.map((familyMember) => parseInt(familyMember.id, 10))

    fetchRecords(participantIds)
  }, [data, fetchRecords])

  // 2. We then loop through all the returned eligibility records and
  // capture them using the related user id as primary account holders
  useEffect(() => {
    if (eligibilityRecords) {
      const userIds = eligibilityRecords.data
        .filter((record) => Number.isInteger(record.participant?.id))
        .map((record) => record.participant!!.id.toString())

      setPrimaryAcctHolders(userIds)
    }
  }, [eligibilityRecords])

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={10}>
        <Typography variant="h2">
          {t('userProfile.family.adultFamilyHeader', { count: adultMembers.length })}
        </Typography>
        {isFamilyGuardian && (
          <Button
            variant="contained"
            sx={{ alignSelf: 'center' }}
            onClick={() => navigate(userAddMember.get(userId, FamilyMemberType.ADULT))}
          >
            {t('userProfile.family.add_adult')}
          </Button>
        )}
      </Box>
      {adultMembers.length > 0 ? (
        <MembersTable
          tableHeaders={ADULT_TABLE_HEADERS}
          isLoading={isRemovingAdult}
          testId="adult-table"
        >
          {adultMembers.map(({ attributes, id, type }) => (
            <ClickableRow key={id} to={userProfile.get(id)}>
              <TableCell>
                {attributes.firstName} {attributes.lastName}
                {primaryAcctHolders.includes(id) && (
                  <BlueBadge sx={{ ml: 1 }} data-testid="primaryAcctHolder">
                    {t('userProfile.primaryAcctHolder')}
                  </BlueBadge>
                )}
              </TableCell>
              <TableCell>{id}</TableCell>
              <TableCell>{attributes.birthdate}</TableCell>
              <TableCell>{attributes.email}</TableCell>
              <TableCell>
                {type === FamilyMemberType.GUARDIAN ? t('global.yes') : t('global.no')}
              </TableCell>
              <TableCell sx={{ textAlign: 'end' }}>
                <ConfirmationDialog
                  title={t('removeMember.dialog.title', {
                    completeName: `${attributes.firstName} ${attributes.lastName}`,
                  })}
                  message={t('removeMember.dialog.areYouSure')}
                  submitAction={() => removeAdult({ userId, targetUserId: id }).unwrap()}
                />
              </TableCell>
            </ClickableRow>
          ))}
        </MembersTable>
      ) : (
        <EmptyStateContainer>
          <Typography variant="body1">{t('userProfile.family.adultEmptyStateMessage')}</Typography>
        </EmptyStateContainer>
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={10}>
        <Typography variant="h2">
          {t('userProfile.family.childFamilyHeader', { count: childMembers.length })}
        </Typography>
        {isFamilyGuardian && (
          <Button
            variant="contained"
            sx={{ alignSelf: 'center' }}
            onClick={() => navigate(userAddMember.get(userId, FamilyMemberType.CHILD))}
          >
            {t('userProfile.family.add_child')}
          </Button>
        )}
      </Box>
      {childMembers.length > 0 ? (
        <MembersTable
          tableHeaders={CHILD_TABLE_HEADERS}
          isLoading={isRemovingChild}
          testId="child-table"
        >
          {childMembers.map(({ attributes, id }) => (
            <ClickableRow key={id} to={userProfile.get(id)}>
              <TableCell>
                {attributes.firstName} {attributes.lastName}
              </TableCell>
              <TableCell>{id}</TableCell>
              <TableCell>{attributes.birthdate}</TableCell>
              <TableCell />
              <TableCell />
              <TableCell sx={{ textAlign: 'end' }}>
                <ConfirmationDialog
                  title={t('removeMember.dialog.title', {
                    completeName: `${attributes.firstName} ${attributes.lastName}`,
                  })}
                  message={t('removeMember.dialog.areYouSure')}
                  submitAction={() => removeChild({ userId, targetUserId: id }).unwrap()}
                />
              </TableCell>
            </ClickableRow>
          ))}
        </MembersTable>
      ) : (
        <EmptyStateContainer>
          <Typography variant="body1">{t('userProfile.family.childEmptyStateMessage')}</Typography>
        </EmptyStateContainer>
      )}
    </Box>
  )
}
