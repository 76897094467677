import { useCallback, useState } from 'react'

import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  title: string
  message: string
  submitAction: () => Promise<null>
}>

export const ConfirmationDialog: React.FC<Props> = ({ title, message, submitAction }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleSubmit = useCallback(() => {
    submitAction().then(() => {
      handleClose()
    })
  }, [handleClose, submitAction])

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Button onClick={handleOpen} variant="text">
        {t('actions.remove')}
      </Button>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t('global.dialog.cancel')}
          </Button>
          <Button variant="contained" autoFocus type="submit" onClick={handleSubmit}>
            {t('global.dialog.remove')}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  )
}
