import { useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AttributeTable } from 'app/lib/components/AttributeTable'
import { CreditCard } from 'app/models/scribe.models'

import { CreditCardForm } from './CreditCardForm'

interface Props {
  creditCard?: CreditCard
  onCreditCardSubmit: (tokenId: string) => Promise<void>
}

export const OrganizationPayment: React.FC<Props> = ({ creditCard, onCreditCardSubmit }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Box display="flex" justifyContent="space-between" sx={{ p: 2 }}>
        <Typography variant="h2" sx={{ marginBottom: 3 }}>
          {t('organizationProfilePayment.title')}
        </Typography>
        <Button variant="contained" onClick={() => setOpen(true)}>
          {t('organizationProfilePayment.updatePaymentDetails')}
        </Button>
      </Box>
      {open && (
        <CreditCardForm open={open} onClose={() => setOpen(false)} onSubmit={onCreditCardSubmit} />
      )}
      {creditCard && <CreditCardDetails creditCard={creditCard} />}
    </>
  )
}

const CreditCardDetails = ({ creditCard }: { creditCard: CreditCard }) => {
  const { t } = useTranslation()
  const cardNumber =
    creditCard.brand[0].toUpperCase() +
      creditCard.brand.substring(1) +
      ' •••• ' +
      creditCard.last4Digits ?? ''
  const cardExpiry = `${creditCard.expirationMonth}/${creditCard.expirationYear % 1000}`

  const paymentAttributes = [
    {
      key: 'paymentType',
      name: t('organizationProfilePayment.type'),
      value: t('global.billingMethodStatus.credit_card'),
    },
    {
      key: 'cardholderName',
      name: t('organizationProfilePayment.cardholderName'),
      value: creditCard.cardholderName,
    },
    {
      key: 'cardNumber',
      name: t('organizationProfilePayment.cardNumber'),
      value: cardNumber,
    },
    {
      key: 'cardExpiry',
      name: t('organizationProfilePayment.cardExpiry'),
      value: cardExpiry,
    },
  ]

  return <AttributeTable attributes={paymentAttributes} />
}
