import FileUploadIcon from '@mui/icons-material/FileUpload'
import { LinearProgress, Paper, Typography, Grid, DialogTitle, DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  percentage?: number
  bodyText: string
}

export const Progress: React.FC<Props> = ({ percentage, bodyText }) => {
  const { t } = useTranslation()
  return (
    <>
      <DialogTitle>{t('csvImport.progress.title')}</DialogTitle>
      <DialogContent>
        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container>
                <FileUploadIcon color="primary" />
                <Typography variant="subtitle1">{bodyText}</Typography>
              </Grid>
            </Grid>
            {percentage && (
              <Grid item>
                <Typography variant="subtitle1">{percentage + '%'}</Typography>
              </Grid>
            )}
          </Grid>
          <LinearProgress
            color="primary"
            value={percentage}
            variant={percentage !== undefined ? 'determinate' : 'indeterminate'}
          />
        </Paper>
      </DialogContent>
    </>
  )
}
