import { ReactNode } from 'react'

import Box from '@mui/material/Box'

import { LoadingBackdrop } from 'app/lib/components/LoadingBackdrop'
import { STRETCH_FULL_HEIGHT } from 'app/lib/constants'

interface PageProps {
  isLoading?: boolean
  error?: false | ReactNode
  children?: React.ReactNode
}

const Page: React.FC<PageProps> = ({ isLoading, error, children }) => {
  return (
    <Box sx={STRETCH_FULL_HEIGHT}>
      {isLoading && <LoadingBackdrop loading={isLoading} />}
      {error || children}
    </Box>
  )
}

export default Page
