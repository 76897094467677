import { PropsWithChildren } from 'react'

import { AppState, Auth0Provider, AuthorizationParams } from '@auth0/auth0-react'
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker'

import { getSnowplowSchema } from 'app/lib/utils/helpers'
import { getApplicationConfig } from 'config'

import { TokenProvider } from './tokenProvider'

const config = getApplicationConfig()

const onRedirectCallback = (appState?: AppState): void => {
  // copy-pasted from from '@auth0/auth0-react/src/auth0-provider'
  window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname)
  // let's wait before the tracker context initializes and send the event
  // FIXME remove this workaround with setting a timeout
  setTimeout(() => {
    trackSelfDescribingEvent({
      event: {
        schema: getSnowplowSchema('presto_admin_sign_in', '1-0-0'),
        data: {},
      },
    })
  }, 10000)
}

export const buildAuthorizationParams = (params: AuthorizationParams = {}) => {
  const origin = window.location.origin
  const returnTo = origin + window.location.pathname
  const auth0RedirectUri = `${origin}/#/auth-check?returnTo=${returnTo}`

  return {
    audience: config.auth0.audience,
    redirect_uri: auth0RedirectUri,
    scope: 'openid email user_metadata profile scribe:superadmin scribe:manage:organizations',
    ui_locales: window.navigator.language,
    ...params,
  }
}

export function AuthenticationProvider(props: PropsWithChildren<{}>) {
  const { children } = props

  return (
    <Auth0Provider
      clientId={config.auth0.clientId}
      domain={config.auth0.domain}
      authorizationParams={buildAuthorizationParams()}
      cacheLocation="localstorage"
      useRefreshTokens={!window.Cypress}
      useRefreshTokensFallback={true}
      onRedirectCallback={onRedirectCallback}
    >
      <TokenProvider>{children}</TokenProvider>
    </Auth0Provider>
  )
}
