import { useCallback, useEffect, useReducer } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Paper, Typography } from '@mui/material'
import { trackPageView } from '@snowplow/browser-tracker'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { createSearchParams, useSearchParams } from 'react-router-dom'

import Page from 'app/layout/Page'
import { HealthSessionsBanner } from 'app/lib/components/Banner'
import { NoResults } from 'app/lib/components/NoResults'
import { listOrganizationInvoices } from 'app/lib/legacy-api/scribe.api'
import { OrganizationRoute } from 'app/models/scribe.models'
import { useLegacyGetOrganizationQuery } from 'app/redux/scribeApi'
import { Invoices } from 'assets/images'

import { OrganizationInvoicesTable } from './InvoicesTable'
import {
  DEFAULT_LIMIT,
  initialOrganizationInvoiceListState,
  reduceOrganizationInvoiceListState,
} from './organization-invoice-list.reducer'

export const OrganizationBillingPage: React.FC = () => {
  const { t } = useTranslation()
  const { getAccessTokenSilently } = useAuth0()
  const [pageState, dispatch] = useReducer(
    reduceOrganizationInvoiceListState,
    initialOrganizationInvoiceListState,
  )

  const { organizationId } = useParams() as OrganizationRoute

  const { data: organization } = useLegacyGetOrganizationQuery(organizationId)

  const [searchParams, setSearchParams] = useSearchParams()

  type PaginationParams = {
    offset: string | null
    limit: string | null
  }

  const getPaginationParams = useCallback((): PaginationParams => {
    return {
      offset: searchParams.get('offset'),
      limit: searchParams.get('limit'),
    }
  }, [searchParams])

  const updatePaginationParams = useCallback(
    (offset: number, limit: number) => {
      const params = createSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
      })
      setSearchParams(params)
    },
    [setSearchParams],
  )

  const fetchOrganizationInvoices = useCallback(
    (params: { organizationId: string; offset: number; limit: number }) => {
      dispatch({ type: 'ORGANIZATION_INVOICE_LIST_FETCH_START' })

      return getAccessTokenSilently()
        .then((token) =>
          listOrganizationInvoices(token, params.organizationId, params.offset, params.limit),
        )
        .then(({ organizationInvoices, pagination }) => {
          const paginationParams = getPaginationParams()
          if (paginationParams.offset && paginationParams.limit) {
            updatePaginationParams(pagination.offset, pagination.limit)
          }

          return dispatch({
            type: 'ORGANIZATION_INVOICE_LIST_FETCH_SUCCESS',
            organizationInvoices,
            pagination,
          })
        })
        .catch(() => dispatch({ type: 'ORGANIZATION_INVOICE_LIST_FETCH_ERROR' }))
    },
    [getAccessTokenSilently, getPaginationParams, updatePaginationParams, dispatch],
  )

  useEffect(() => {
    trackPageView({ title: 'organization-billing' })
  }, [])

  useEffect(() => {
    const { offset, limit } = getPaginationParams()
    fetchOrganizationInvoices({
      organizationId: organizationId,
      offset: offset ? parseInt(offset) : 0,
      limit: limit ? parseInt(limit) : DEFAULT_LIMIT,
    })
  }, [organizationId, getPaginationParams, fetchOrganizationInvoices])

  return (
    <Page isLoading={pageState.loading}>
      <HealthSessionsBanner />
      {!pageState.loading && pageState.organizationInvoices.length === 0 ? (
        <Paper sx={{ mb: 3, mt: 3 }} elevation={1}>
          <NoResults
            title={t('organizationInvoiceListPage.thereAreNoInvoicesYet')}
            subtitle={t('organizationInvoiceListPage.onceBillingBeginsYourMonthlyInvoices')}
            img={
              <Box margin={-3}>
                <Invoices title="invoice" width="100%" />
              </Box>
            }
          />
        </Paper>
      ) : (
        <>
          <Typography variant="h1" sx={{ marginBottom: 3 }} data-testid="page-title">
            {t('organizationInvoiceListPage.pageTitle', { organizationName: organization?.name })}
          </Typography>
          <Paper sx={{ mb: 3, mt: 3 }} elevation={1}>
            {organization && (
              <OrganizationInvoicesTable
                organizationId={organization.id}
                organizationInvoices={pageState.organizationInvoices}
                pagination={pageState.pagination}
                onPageChange={(page) => {
                  const { limit } = pageState.pagination
                  const offset = limit * page
                  updatePaginationParams(offset, limit)
                }}
              />
            )}
          </Paper>
        </>
      )}
    </Page>
  )
}
