import { Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { isFuture } from 'date-fns'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import DatePickerFormField, {
  DatePickerFormFieldProps,
} from 'app/lib/components/form/DatePickerFormField'
import InputFormField from 'app/lib/components/form/InputFormField'
import SelectFormField, { SelectFormFieldProps } from 'app/lib/components/form/SelectFormField'
import SwitchFormField from 'app/lib/components/form/SwitchFormField'
import withInputFieldViewMode from 'app/lib/hoc/withInputFieldViewMode'
import { formatDate } from 'app/lib/utils/date'
import {
  AccountingMethod,
  BillingMethod,
  BillingMethodDeprecated,
  CreditCardEnum,
} from 'app/models/scribe.models'

import { FormRow } from './FormRow'

const DatePickerFormFieldWithViewMode =
  withInputFieldViewMode<DatePickerFormFieldProps>(DatePickerFormField)
const SelectFormFieldWithViewMode = withInputFieldViewMode<SelectFormFieldProps>(SelectFormField)

export const Billing: React.FC<{ isEdit: boolean; nonEditableFields: string[] }> = ({
  isEdit,
  nonEditableFields,
}) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const {
    watch,
    formState: { defaultValues },
  } = useFormContext()

  const [billingStartDate, billingMethod]: [Date, BillingMethodDeprecated] = watch([
    'billingStartDate',
    'billingMethod',
  ])

  const initialBillingStartDate = defaultValues?.billingStartDate
  const isFutureBilling = initialBillingStartDate && isFuture(initialBillingStartDate)

  const isBillingStartDateDisabled =
    nonEditableFields.includes('billingStartDate') || (initialBillingStartDate && !isFutureBilling)

  const billingDisplayStartDate = formatDate(billingStartDate, resolvedLanguage, 'long')

  const billingOptions = Object.values(
    defaultValues?.billingMethod === CreditCardEnum.CREDIT_CARD
      ? { ...BillingMethod, ...CreditCardEnum }
      : BillingMethod,
  )

  return (
    <Card>
      <CardContent sx={{ p: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2">{t('organizationPage.form.billing.title')}</Typography>
          </Grid>
          {!isEdit && (
            <Grid item xs={12}>
              <SwitchFormField
                name="accountingMethod"
                label={t('organizationPage.form.billing.accountingMethod')}
                options={[AccountingMethod.Manual, AccountingMethod.Automatic]}
                color="warning"
              />
              <Divider sx={{ my: 2 }} />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <FormRow
              label={t('organizationPage.form.billing.billingEmail')}
              field={
                <InputFormField
                  name="billingEmail"
                  label={t('organizationPage.form.billing.billingEmail')}
                  variant="outlined"
                  hideLabel
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormRow
              label={t('organizationPage.form.billing.billingMethod')}
              field={
                <SelectFormFieldWithViewMode
                  name="billingMethod"
                  label={t('organizationPage.form.billing.billingMethod')}
                  isViewMode={nonEditableFields.includes('billingMethod')}
                  displayName={t(`global.billingMethodStatus.${billingMethod}`)}
                  variant="outlined"
                  hideLabel
                  options={billingOptions.map((value) => ({
                    label: t(`global.billingMethodStatus.${value}`),
                    value,
                  }))}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormRow
              label={t('organizationPage.form.billing.billingStartDate')}
              field={
                <DatePickerFormFieldWithViewMode
                  name="billingStartDate"
                  isViewMode={isBillingStartDateDisabled}
                  displayName={billingDisplayStartDate}
                  label={t('organizationPage.form.billing.billingStartDate')}
                  variant="outlined"
                  openTo="day"
                  disablePast
                  hideLabel
                />
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
