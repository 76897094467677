import { REGIONS } from 'app/lib/constants'
import { colors } from 'app/theme'

import type { Config } from '.'

const hostOverrides: Record<string, Partial<Config>> = {
  'admin-sunlifeus.dev.dialogue.co': {
    auth0: {
      audience: 'https://api.dev.dialoguecorp.com',
      clientId: '4I1kfTnwzPD4IEE4EZ7uUYAykjlzyC5T',
      domain: 'auth.dev.dialogue.co',
      connections: {
        cognito: 'cognito-admin-dev',
        okta: 'okta-sandbox-admin-dev',
        adp: 'adp-admin-dev',
      },
    },
  },
  'admin-sunlifeus-mirror.dev.dialogue.co': {
    auth0: {
      audience: 'https://api.dev.dialoguecorp.com',
      clientId: 'EDMJFIsv6EPSLs7cRSRtUl6LBjxuBFfi',
      domain: 'auth.dev.dialogue.co',
      connections: {
        cognito: 'cognito-admin-dev',
        okta: 'okta-sandbox-admin-dev',
        adp: 'adp-admin-dev',
      },
    },
  },
}

export const developmentConfig: Config = {
  auth0: {
    audience: 'https://api.dev.dialoguecorp.com',
    clientId: 'WqzfcaZ5qWLS3YzQqlyA7CopArryMOTn',
    domain: 'auth.dev.dialogue.co',
    connections: {
      cognito: 'cognito-admin-dev',
      okta: 'okta-sandbox-admin-dev',
      adp: 'adp-admin-dev',
    },
  },
  launchDarkly: {
    clientId: '6295189ae11a2e14fd1b74d1',
  },
  intercom: {
    appId: 'argp6vm6',
  },
  scribe: {
    baseUrl: 'https://scribe.dev.dialoguecorp.com',
  },
  multipass: {
    baseUrl: 'https://multipass.dev.dialoguecorp.com',
  },
  coredata: {
    baseUrl: 'https://coredata.dev.dialoguecorp.com',
  },
  activeMinutes: {
    baseUrl: 'https://active-minutes.dev.dialoguecorp.com',
  },
  stripePublicKey: 'pk_test_NvdT0NqP86UFpZsxWjLt3wjF',
  theme: {
    applicationBar: {
      backgroundColor: colors.navy,
    },
  },
  assets_url: 'https://assets.dialoguecorp.com/maestro/dev',
  snowplow: {
    appId: 'presto',
    namespace: 'dev-ca2',
    endpoint: 'https://lawnmower.dev.dialoguecorp.com',
  },
  region: REGIONS.CANADA,
  ...hostOverrides[window.location.hostname],
}
