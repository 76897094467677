import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ClickableRow } from 'app/lib/components/ClickableRow'
import { ScribePermission } from 'app/lib/constants'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { formatDate } from 'app/lib/utils/date'
import { Organization, Pagination } from 'app/models/scribe.models'

const TABLE_HEADERS = ['name', 'payingMembers', 'billingStartDate', 'id'] as const

type TableProps = Readonly<{
  organizations: ReadonlyArray<Organization>
  pagination: Pagination
  onPageChange: (page: number) => void
}>

export const OrganizationsTable: React.FC<TableProps> = ({
  organizations,
  pagination,
  onPageChange,
}) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()

  const permissions = useUserPermissions()
  const canReadBilling = permissions?.includes(ScribePermission.READ_ORGANIZATION_BILLING)

  const headers = canReadBilling
    ? TABLE_HEADERS
    : TABLE_HEADERS.filter((header) => header !== 'billingStartDate')

  const cells = headers.map((i18nKey) => (
    <TableCell key={i18nKey} align="left">
      {t(`organizationListPage.organizationTable.headers.${i18nKey}`)}
    </TableCell>
  ))

  const rows = organizations.map((organization) => (
    <ClickableRow key={organization.id} to={`/organizations/${organization.id}/members`}>
      <TableCell
        data-testid="organisation-name"
        sx={{ maxWidth: 275, overflowWrap: 'break-word', whiteSpace: 'normal' }}
      >
        <Typography variant="subtitle2">{organization.name} </Typography>
      </TableCell>
      <TableCell data-testid="active-members">{organization.members.active}</TableCell>
      {canReadBilling && (
        <TableCell data-testid="biling-start-date">
          {organization.billingStartDate !== null
            ? formatDate(organization.billingStartDate, resolvedLanguage)
            : ''}
        </TableCell>
      )}
      <TableCell data-testid="organisation-id">{organization.id}</TableCell>
    </ClickableRow>
  ))

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{cells}</TableRow>
        </TableHead>
        <TableBody data-testid="organisation-records-table">{rows}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              data-testid="pagination-footer"
              page={Math.floor(pagination.offset / pagination.limit)}
              rowsPerPage={pagination.limit}
              rowsPerPageOptions={[]}
              count={pagination.total}
              showFirstButton
              showLastButton
              onPageChange={(_event, page) => onPageChange(page)}
              getItemAriaLabel={(type) => t(`global.pagination.${type}`)}
              labelDisplayedRows={({ from, to, count: itemsCount }) =>
                itemsCount > 0
                  ? t('global.pagination.of', { from, to, count: itemsCount })
                  : t('global.pagination.of_more_than', { from, to })
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
