import { REGIONS } from 'app/lib/constants'
import { colors } from 'app/theme'

import type { Config } from '.'

export const productionEuConfig = Object.freeze<Config>({
  auth0: {
    audience: 'https://api.dialoguecorp.com',
    clientId: 'ClI5qyNwtBWQZGXBpFd2g1clQhJGThcO',
    domain: 'auth.dialoguecorp.eu',
    connections: {},
  },
  launchDarkly: {
    clientId: '65fc56dcaace4c1061c9ac29',
  },
  intercom: {
    appId: 'argp6vm6',
  },
  scribe: {
    baseUrl: 'https://scribe.dialoguecorp.eu',
  },
  multipass: {
    baseUrl: 'https://multipass.dialoguecorp.eu',
  },
  coredata: {
    baseUrl: 'https://coredata.dialoguecorp.eu',
  },
  activeMinutes: {
    baseUrl: 'https://active-minutes.dialoguecorp.eu',
  },
  stripePublicKey: 'pk_live_mMeepfha0s3atBB59RWG9mIj',
  theme: {
    applicationBar: {
      backgroundColor: colors.dialogueCharcoal,
    },
  },
  assets_url: 'https://assets.dialoguecorp.eu/maestro/master',
  snowplow: {
    appId: 'presto',
    namespace: 'prod-eu',
    endpoint: 'https://lawnmower.dialoguecorp.eu',
  },
  region: REGIONS.UK,
})
