import { useTranslation } from 'react-i18next'

import { Language } from 'app/models/scribe.models'

export const useLanguage = () => {
  const language = useTranslation().i18n.resolvedLanguage as Language
  if (!Object.values(Language).includes(language)) {
    throw Error(`i18n.resolvedLanguage '${language}' is not a known language`)
  }
  return language
}
