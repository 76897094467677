import { Checkbox, FormControlLabel, FormGroup, Grid, GridProps } from '@mui/material'
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel'
import { Controller, useFormContext } from 'react-hook-form'

type Props = Omit<FormControlLabelProps, 'control' | 'label'> & {
  name: string
  options: {
    label: string
    value: string
  }[]
  wrapperGridProps?: GridProps
  optionGridProps?: GridProps
}

const CheckboxGroupFormField = ({
  name,
  options,
  wrapperGridProps,
  optionGridProps,
  ...props
}: Props) => {
  const { control } = useFormContext()
  return (
    <FormGroup>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Grid container spacing={2} {...wrapperGridProps}>
            {options.map(({ label, value }) => (
              <Grid item key={label} display="flex" alignItems="center" xs={6} {...optionGridProps}>
                <FormControlLabel
                  {...field}
                  {...props}
                  label={label}
                  control={
                    <Checkbox
                      size="small"
                      onChange={() => {
                        if (!field.value.includes(value)) {
                          field.onChange([...field.value, value])
                          return
                        }
                        const newValues = field.value.filter((val: any) => val !== value)
                        field.onChange(newValues)
                      }}
                      checked={field.value.includes(value)}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
      />
    </FormGroup>
  )
}

export default CheckboxGroupFormField
