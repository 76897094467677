import React, { useState } from 'react'

import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  styled,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Pagination } from 'app/models/scribe.models'
import { colors } from 'app/theme/colors'

import { EnrolUser } from './EnrolUser'

type EpisodesChargesTableProps = {
  cells: JSX.Element[]
  rows: JSX.Element[]
  pagination: Pagination
  setPagination: (params: { offset: number; limit: number; total: number }) => void
  tableTitle?: string
  isEligibilityRecord?: boolean
  hasEntries: boolean
  pageTitle?: string
  emptyStateMessage?: string
}

export const EmptyStateContainer = styled('div')({
  display: 'flex',
  padding: '20px 32px',
  alignItems: 'center',
  borderRadius: '4px',
  backgroundColor: colors.white,
  height: '56px',
  boxShadow: '0px 2px 4px 0px rgba(188, 188, 188, 0.50)',
})

export const UserInfoTable: React.FC<EpisodesChargesTableProps> = ({
  cells,
  rows,
  pagination,
  setPagination,
  tableTitle,
  isEligibilityRecord = false,
  hasEntries,
  pageTitle,
  emptyStateMessage,
}) => {
  const { t } = useTranslation()

  const [openEnrolModal, setOpenEnrolModal] = useState<boolean>(false)

  const handlePageChange = (_event: any, newPage: number) => {
    const newOffset = newPage * pagination.limit
    setPagination({
      ...pagination,
      offset: newOffset,
    })
  }

  const handleEnrolUserClick = () => {
    setOpenEnrolModal(true)
  }

  return (
    <>
      {tableTitle && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={10}>
          <Typography variant="h2">{tableTitle}</Typography>
          {isEligibilityRecord && (
            <Button variant="contained" sx={{ alignSelf: 'center' }} onClick={handleEnrolUserClick}>
              {t('userProfile.eligibilityRecord.enrolUser')}
            </Button>
          )}
        </Box>
      )}
      {hasEntries ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>{cells}</TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: colors.white }} data-testid="common-table">
              {rows}
            </TableBody>
            <TableFooter sx={{ backgroundColor: colors.white }}>
              <TableRow>
                <TablePagination
                  data-testid="pagination-footer"
                  page={Math.floor(pagination.offset / pagination.limit)}
                  rowsPerPage={pagination.limit}
                  rowsPerPageOptions={[]}
                  count={pagination.total}
                  onPageChange={handlePageChange}
                  labelDisplayedRows={({ from, to, count: itemsCount }) =>
                    itemsCount > 0
                      ? t('global.pagination.of', { from, to, count: itemsCount })
                      : t('global.pagination.of_more_than', { from, to })
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <EmptyStateContainer>
          <Typography variant="body1">{emptyStateMessage}</Typography>
        </EmptyStateContainer>
      )}
      {openEnrolModal && (
        <EnrolUser setOpenEnrolModal={setOpenEnrolModal} pageTitle={pageTitle || ''} />
      )}
    </>
  )
}
