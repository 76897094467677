import { createApi } from '@reduxjs/toolkit/query/react'

import customBaseQuery from 'app/lib/utils/customBaseQuery'
import { UserEpisodes } from 'app/models/scribe.models'
import { UserEpisodesArg } from 'app/models/User'
import { getApplicationConfig } from 'config'

const {
  coredata: { baseUrl: coredataBaseUrl },
} = getApplicationConfig()

export const erApi = createApi({
  reducerPath: 'erApi',
  tagTypes: ['UserEpisodes'],
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getUserEpisodes: builder.query<UserEpisodes, UserEpisodesArg>({
      query: ({ params, patientId }) => ({
        url: `${coredataBaseUrl}/v1/patients/${patientId}/limited_episodes`,
        params: {
          limit: params.limit,
          offset: params.offset,
        },
      }),
      providesTags: (_result, _error, { patientId }) => [{ type: 'UserEpisodes', id: patientId }],
    }),
  }),
})

export const { useGetUserEpisodesQuery } = erApi
