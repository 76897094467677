import { ReactNode } from 'react'

import { SxProps, Typography } from '@mui/material'

export const BlueBadge = ({ children, sx, ...props }: { children: ReactNode; sx?: SxProps }) => (
  <Typography
    variant="caption1"
    sx={{
      backgroundColor: '#C4E6F1',
      borderRadius: 0.5,
      width: 'fit-content',
      py: 0.5,
      px: 0.75,
      ...sx,
    }}
    {...props}
  >
    {children}
  </Typography>
)
