import { TextFieldProps } from '@mui/material'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type DatePickerFormFieldProps = Omit<
  DatePickerProps<any>,
  'onChange' | 'value' | 'renderInput'
> &
  Pick<TextFieldProps, 'helperText'> & {
    name: string
    required?: boolean
    variant?: 'filled' | 'outlined' | 'standard'
    hideLabel?: boolean
    size?: 'small' | 'medium'
  }

const DatePickerFormField = ({
  name,
  required,
  helperText,
  ...props
}: DatePickerFormFieldProps) => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  const { variant, size, openTo, ...datePickerProps } = props

  if (props.hideLabel) {
    datePickerProps.label = null
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          format="yyyy-MM-dd"
          openTo={openTo}
          views={['year', 'month', 'day']}
          slotProps={{
            textField: {
              variant: variant || 'standard',
              fullWidth: true,
              size,
              required,
              name,
              error: !!error?.message,
              helperText: error?.message
                ? t(error.message, '', { label: props.label })
                : helperText,
              inputProps: {
                'data-testid': `datepicker-${name}`,
                sx: variant === 'outlined' && {
                  padding: '8.5px 14px',
                },
                placeholder: t('form.placeholders.yyyy-MM-dd'),
              },
            },
          }}
          {...field}
          {...datePickerProps}
        />
      )}
    />
  )
}

export default DatePickerFormField
