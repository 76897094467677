import { useAuth0 } from '@auth0/auth0-react'
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material'
import MuiAvatar from '@mui/material/Avatar'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { logoSrc } from 'assets/images'

import { LanguageSelector } from './language-selector/LanguageSelector'
import { ProfileMenu } from './profile-menu/ProfileMenu'

export type ApplicationBarProps = Readonly<{
  backgroundColor: string
}>

const Avatar = () => {
  const { user } = useAuth0()
  const familyName = user?.family_name ?? ''
  const picture = user?.picture ?? ''
  const size = 32

  return <MuiAvatar alt={familyName} src={picture} sx={{ width: size, height: size }} />
}

const Logo = () => {
  const { t } = useTranslation()
  return (
    <Stack
      direction="row"
      alignItems="center"
      component={Link}
      spacing={2}
      to="/"
      sx={{ textDecoration: 'none' }}
    >
      <img src={logoSrc} alt="Home" height={52} />
      <Typography variant="button" color="secondary.main">
        {t('applicationBar.menu.admin')}
      </Typography>
    </Stack>
  )
}

export const ApplicationTopBar: React.FC<ApplicationBarProps> = ({ backgroundColor }) => (
  <AppBar
    position="fixed"
    sx={{
      backgroundColor,
      zIndex: (theme) => theme.zIndex.drawer + 1,
      boxShadow: 3,
    }}
    data-testid="application-bar"
  >
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'unset',
        justifyContent: 'space-between',
        overflow: 'overlay',
      }}
    >
      <Logo />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0 10px' }}>
        <LanguageSelector />
        <Avatar />
        <ProfileMenu />
      </Box>
    </Toolbar>
  </AppBar>
)
