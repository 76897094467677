import React, { useState } from 'react'

import { Button, TableCell, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ClickableRow } from 'app/lib/components/ClickableRow'
import { Label, LabelType } from 'app/lib/components/Label'
import { SortOrder } from 'app/lib/legacy-api/scribe.api'
import { EligibilityRecord, EligibilityRecordStatus } from 'app/models/scribe.models'
import { useLazyListEligibilityRecordsQuery } from 'app/redux/scribeApi'

import { DEFAULT_LIMIT, SearchMember } from '../search-member/SearchMember'

import { ConfirmEnrolmentChange } from './ConfirmEnrolmentChange'

const TABLE_HEADERS = [
  'userProfile.eligibilityRecord.firstName',
  'userProfile.eligibilityRecord.lastName',
  'userProfile.eligibilityRecord.eligibilityRecordId',
  'userProfile.eligibilityRecord.uniqueIdentifier',
  'userProfile.eligibilityRecord.status',
  'userProfile.eligibilityRecord.organization',
] as const

type EnrolUserProps = {
  setOpenEnrolModal: React.Dispatch<React.SetStateAction<boolean>>
  pageTitle: string
}

export const EnrolUser: React.FC<EnrolUserProps> = ({ setOpenEnrolModal, pageTitle }) => {
  const { t } = useTranslation()
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(true)
  const [openConfirmEnrolChange, setOpenConfirmEnrolChange] = useState<boolean>(false)
  const [onCancel, setOnCancel] = useState<boolean>(false)
  const [eligibilityId, setEligibilityId] = useState<number>(0)
  const [fetchEligibilityRecords, { data: records, isFetching, isError }] =
    useLazyListEligibilityRecordsQuery()

  const cells = [
    ...TABLE_HEADERS.map((i18nKey) => (
      <TableCell key={i18nKey} align="left" padding="normal">
        {t(i18nKey)}
      </TableCell>
    )),
    <TableCell key="additional-column" align="left" padding="normal" />,
  ]

  const fetchRecords = (page: number, search?: string | null) => {
    const params = {
      order: SortOrder.DESCENDING,
      offset: DEFAULT_LIMIT * page,
      limit: DEFAULT_LIMIT,
      search: search || undefined,
    }

    fetchEligibilityRecords({ params })
  }

  const enrolUser = (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
    event.stopPropagation()
    setEligibilityId(id)
    setOpenConfirmEnrolChange(true)
    setOpenSearchModal(false)
    setOnCancel(false)
  }

  const updateRecordStatusFormat = (status: string) => {
    return status.charAt(0).toUpperCase() + status.slice(1)
  }

  const rows = records?.data?.map((record: EligibilityRecord) => (
    <ClickableRow
      key={record.id}
      to={`/organizations/${record?.organization?.id}/members/${record.id}`}
      newTabOnClick={true}
    >
      <TableCell>
        <Typography variant="subtitle2">{record.attributes.firstName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">{record.attributes.lastName}</Typography>
      </TableCell>
      <TableCell>{record.id}</TableCell>
      <TableCell>{record.attributes.uniqueIdentifier}</TableCell>
      <TableCell>
        <Typography variant="body2">
          <Label
            type={
              record.status === EligibilityRecordStatus.ACTIVE
                ? LabelType.DEFAULT
                : LabelType.ARCHIVED
            }
            text={updateRecordStatusFormat(record.status)}
            textProps={{ px: 2, py: 0.5, borderRadius: 10 }}
            boxProps={{ mt: 0 }}
          />
        </Typography>
      </TableCell>
      <TableCell>{record?.organization?.name}</TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <Button variant="contained" onClick={(event) => enrolUser(event, record.id)}>
          {t('userProfile.eligibilityRecord.enrol')}
        </Button>
      </TableCell>
    </ClickableRow>
  ))

  const tableData = {
    rows: rows || [],
    cells: cells,
    totalItems: records?.meta.totalItems,
  }

  const onClose = () => {
    setOpenSearchModal(false)
    setOpenEnrolModal(false)
  }

  return (
    <>
      <SearchMember
        open={openSearchModal}
        onCancel={onCancel}
        title={`${t('searchMember.modal.titleEnrol')} ${pageTitle}`}
        onClose={onClose}
        handleFetch={fetchRecords}
        isFetching={isFetching}
        isError={isError}
        tableData={tableData}
        enableLargeModal
      />
      {openConfirmEnrolChange && (
        <ConfirmEnrolmentChange
          openModal={openConfirmEnrolChange}
          onOpenConfirmation={setOpenConfirmEnrolChange}
          onCancel={setOnCancel}
          onOpen={setOpenEnrolModal}
          onOpenSearch={setOpenSearchModal}
          eligibilityId={eligibilityId}
          modalTitle={`${t('searchMember.modal.titleEnrol')} ${pageTitle}`}
        />
      )}
    </>
  )
}
