import { PropsWithChildren, useEffect, useMemo, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import { getApplicationConfig } from 'config'

enum RoleType {
  ORGANIZATION_MANAGER = 'organizationManager',
  DEFAULT = 'default',
}

export function LaunchDarklyProvider(props: PropsWithChildren<{}>) {
  const { children } = props

  const { launchDarkly } = getApplicationConfig()
  const { getAccessTokenSilently } = useAuth0()
  const [userRole, setUserRole] = useState<RoleType>()

  useEffect(() => {
    getAccessTokenSilently({ detailedResponse: true })
      .then((token) => {
        const scopes = token.scope?.split(' ')
        const isOrganizationManager = scopes && scopes.includes('scribe:manage:organizations')
        setUserRole(!!isOrganizationManager ? RoleType.ORGANIZATION_MANAGER : RoleType.DEFAULT)
      })
      .catch(() => {
        setUserRole(RoleType.DEFAULT)
      })
  }, [getAccessTokenSilently])

  const Provider = useMemo(() => {
    const Child = () => <>{children}</>

    if (!userRole) {
      return Child
    }

    return withLDProvider({
      clientSideID: launchDarkly.clientId,
      deferInitialization: true,
      context: {
        key: userRole,
      },
    })(Child)
  }, [userRole, launchDarkly.clientId, children])

  return <Provider />
}
