import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Invite, Manage, Download } from 'assets/images'

type Props = Readonly<{
  open: boolean
  onClose: () => void
}>

export const GetStartedDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'getStartedDialog' })

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h3" textAlign="center">
          {t('youreAllSet')}
        </Typography>
        <Typography variant="h5" textAlign="center">
          {t('startGivingYourMembersAccess')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item sm={4} container direction="column" alignItems="center" padding={2}>
            <Invite title="invite your team" />
            <Typography fontWeight={'bold'}>{t('inviteYourTeam')}</Typography>
            <Typography textAlign="center">{t('addMembersAndSendInvites')}</Typography>
          </Grid>
          <Grid item sm={4} container direction="column" alignItems="center" padding={2}>
            <Download title="download Dialogue" />
            <Typography fontWeight={'bold'}>{t('theyGetTheDialogueApp')}</Typography>
            <Typography textAlign="center">{t('theyWillUseTheEmail')}</Typography>
          </Grid>
          <Grid item sm={4} container direction="column" alignItems="center" padding={2}>
            <Manage title="manage your members" />
            <Typography fontWeight={'bold'}>{t('manageYourMembers')}</Typography>
            <Typography textAlign="center">{t('addAndRemoveMembers')}</Typography>
          </Grid>
          <Grid item xs={12} textAlign="center" margin={3}>
            <Button variant="contained" onClick={onClose}>
              {t('soundsGood')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
