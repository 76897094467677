import { isAfter, isBefore, isSameDay, startOfDay } from 'date-fns'

import { i18n } from 'app/i18n'
import {
  formatDate,
  fromISOdate,
  isSameDayOrLater,
  today,
  toISOdate,
  yesterday,
} from 'app/lib/utils/date'
import { Item } from 'app/models/api'
import {
  EligibilityDirective as Directive,
  SubmitEligibilityDirectiveMutation,
  SubmitEligibilityDirectiveResponse,
} from 'app/models/EligibilityRecord'
import {
  EligibilityDirectiveError,
  EligibilityDirectiveOperation,
  EligibilityRecord,
  EligibilityRecordAttributes,
  EligibilityRecordStatus,
} from 'app/models/scribe.models'
import { ImportTypes } from 'app/pages/organization-eligibility-record-list/csv-upload/Import'

import { asyncPoolAll, fetchAllEligibilityRecords, HttpError } from '../legacy-api/helpers'

const { resolvedLanguage } = i18n

type DirectiveWithIndex = {
  directive: Directive
  index: number
}

interface ResponseErrorDetail {
  loc: string[]
  msg: string
  type: string
}

export enum ResultType {
  ACTIVATED = 'activated',
  FUTURE_ACTIVATED = 'future_activated',
  DEACTIVATED = 'deactivated',
  UNCHANGED = 'unchanged',
  ERROR = 'invalid',
}

export interface ProcessingResults {
  activatedRecords: EligibilityRecordAttributes[]
  unchangedRecords: EligibilityRecordAttributes[]
  deactivatedRecords: EligibilityRecordAttributes[]
  futureActivatedRecords: EligibilityRecordAttributes[]
  errors: EligibilityDirectiveError[]
}

export const processingResultsInitial: ProcessingResults = {
  activatedRecords: [],
  unchangedRecords: [],
  deactivatedRecords: [],
  futureActivatedRecords: [],
  errors: [],
}

type ResultTypeSuccess =
  | ResultType.ACTIVATED
  | ResultType.FUTURE_ACTIVATED
  | ResultType.UNCHANGED
  | ResultType.DEACTIVATED

interface ResultItemSuccess {
  index: number
  type: ResultTypeSuccess
  result: EligibilityRecordAttributes
}
interface ResultItemError {
  index: number
  type: ResultType.ERROR
  result: EligibilityDirectiveError
}

type ResultItem = ResultItemSuccess | ResultItemError

type PlansIdToLabel = {
  [key: string]: string
}

const CONCURRENCY_LIMIT = 5

const getAllDirectives = async (
  token: string,
  organizationId: string,
  fileDirectives: Directive[],
  importType: ImportTypes,
): Promise<DirectiveWithIndex[]> => {
  let directives = fileDirectives

  const getDeactivationDirectives = (records: ReadonlyArray<EligibilityRecord>) => {
    let deactivationDirectives: Directive[] = []
    const directiveIdentifiers = directives.map((directive) => directive.uniqueIdentifier)
    records.forEach((record) => {
      const { attributes } = record
      if (!directiveIdentifiers.includes(attributes.uniqueIdentifier)) {
        deactivationDirectives.push({
          uniqueIdentifier: attributes.uniqueIdentifier,
          deactivationDate: toISOdate(yesterday()),
        })
      }
    })
    return deactivationDirectives
  }

  if (importType === ImportTypes.UPDATE) {
    const activeAndFutureRecords = await fetchAllEligibilityRecords(
      token,
      organizationId,
      toISOdate(today()),
    )
    const deactivationDirectives = getDeactivationDirectives(activeAndFutureRecords)
    directives = directives.concat(deactivationDirectives)
  }
  return directives.map((dir, i) => ({ directive: dir, index: i }))
}

const getResultType = (response: SubmitEligibilityDirectiveResponse): ResultTypeSuccess => {
  const statusChange = response.meta.logs?.find((log) => log.type === 'status_change')
  if (!statusChange) {
    const operation = response.meta.operation
    if (operation === EligibilityDirectiveOperation.CREATED) {
      return ResultType.FUTURE_ACTIVATED
    }
    return ResultType.UNCHANGED
  }

  if (
    statusChange.before === EligibilityRecordStatus.INACTIVE &&
    statusChange.after === EligibilityRecordStatus.ACTIVE
  ) {
    return ResultType.ACTIVATED
  }
  if (
    statusChange.before === EligibilityRecordStatus.ACTIVE &&
    statusChange.after === EligibilityRecordStatus.INACTIVE
  ) {
    return ResultType.DEACTIVATED
  }
  return ResultType.UNCHANGED
}

const submitDirective = (
  args: {
    submitEligibilityDirectiveMutation: SubmitEligibilityDirectiveMutation
    organizationId: string
    directive: Directive
    isPreview: boolean
    plansIdToLabel: PlansIdToLabel
  },
  i: number,
): Promise<ResultItem> => {
  const {
    submitEligibilityDirectiveMutation,
    organizationId,
    directive,
    isPreview,
    plansIdToLabel,
  } = args
  const params = { dryRun: isPreview }
  return submitEligibilityDirectiveMutation({ organizationId, body: directive, params })
    .then((response) => {
      if (!response.data) {
        return {
          index: i,
          type: getResultType(response),
          result: {
            uniqueIdentifier: directive.uniqueIdentifier,
          },
        }
      }
      const eligibilityRecord = response.data
      const {
        uniqueIdentifier,
        firstName,
        lastName,
        dateOfBirth,
        province,
        communicationEmail,
        ...restOfTheAttributes
      } = eligibilityRecord?.attributes
      const eligibleIntervals =
        eligibilityRecord?.eligibleIntervals[eligibilityRecord?.eligibleIntervals.length - 1]

      const activationDate = formatDate(eligibleIntervals?.startDate, resolvedLanguage || 'en')
      const deactivationDate = formatDate(eligibleIntervals?.endDate, resolvedLanguage || 'en')

      return {
        index: i,
        type: getResultType(response),
        result: {
          // Sort columns
          uniqueIdentifier,
          firstName,
          lastName,
          dateOfBirth,
          province,
          communicationEmail,
          activationDate,
          deactivationDate,
          ...restOfTheAttributes,
          plan: eligibleIntervals?.planId ? plansIdToLabel[eligibleIntervals?.planId] : '',
        },
      }
    })
    .catch((error: Item<HttpError>) => {
      let errorDetail: ResponseErrorDetail = Array.isArray(error.data?.detail)
        ? error.data.detail[0]
        : null
      const directiveError: EligibilityDirectiveError = {
        lineNumber: i + 2,
        uniqueIdentifier: directive.uniqueIdentifier,
        message: errorDetail?.msg ?? 'unknownError',
        field: errorDetail?.loc?.length > 1 ? errorDetail.loc[1] : '',
      }
      return {
        index: i,
        type: ResultType.ERROR,
        result: directiveError,
      }
    })
}

const categorizeResults = (resultItems: ResultItem[]): ProcessingResults => {
  const sortedResultItems = resultItems.sort((a, b) => a.index - b.index)
  return {
    activatedRecords: sortedResultItems
      .filter((item): item is ResultItemSuccess => item.type === ResultType.ACTIVATED)
      .map((result) => result.result),
    futureActivatedRecords: sortedResultItems
      .filter((item): item is ResultItemSuccess => item.type === ResultType.FUTURE_ACTIVATED)
      .map((result) => result.result),
    unchangedRecords: sortedResultItems
      .filter((item): item is ResultItemSuccess => item.type === ResultType.UNCHANGED)
      .map((result) => result.result),
    deactivatedRecords: sortedResultItems
      .filter((item): item is ResultItemSuccess => item.type === ResultType.DEACTIVATED)
      .map((result) => result.result),
    errors: sortedResultItems
      .filter((item): item is ResultItemError => item.type === ResultType.ERROR)
      .map((result) => result.result),
  }
}

type processDirectivesParams = {
  getAccessTokenSilently: () => Promise<string>
  organizationId: string
  directives: Directive[]
  importType: ImportTypes
  isPreview: boolean
  setPercentageOfProcessedDirectives: (_: number) => void
  plansIdToLabel: PlansIdToLabel
  submitEligibilityDirectiveMutation: SubmitEligibilityDirectiveMutation
}
export const processDirectives = async ({
  getAccessTokenSilently,
  organizationId,
  directives,
  importType,
  isPreview,
  setPercentageOfProcessedDirectives,
  plansIdToLabel,
  submitEligibilityDirectiveMutation,
}: processDirectivesParams): Promise<ProcessingResults> => {
  return await getAccessTokenSilently()
    .then((token) => getAllDirectives(token, organizationId, directives, importType))
    .then((allDirectives) => {
      let numberOfProcessedDirectives = 0
      const numberOfTotalDirectives = allDirectives.length

      const processSingleDirective = ({ directive, index }: DirectiveWithIndex) => {
        return submitDirective(
          {
            submitEligibilityDirectiveMutation,
            organizationId,
            directive,
            isPreview,
            plansIdToLabel,
          },
          index,
        ).finally(() => {
          numberOfProcessedDirectives++
          const percentage = Math.floor(
            (numberOfProcessedDirectives * 100) / numberOfTotalDirectives,
          )
          setPercentageOfProcessedDirectives(percentage)
        })
      }
      return asyncPoolAll(CONCURRENCY_LIMIT, allDirectives, processSingleDirective).then(
        (items) => {
          setPercentageOfProcessedDirectives(0)
          return categorizeResults(items)
        },
      )
    })
}

export const calculateActivationDate = (
  directiveActivationDate: string | undefined,
  directiveDeactivationDate: string | undefined,
  defaultActivationDate: string,
  datePickerSelected: boolean = false,
): string | null => {
  if (datePickerSelected) {
    return defaultActivationDate
  }

  const activationDateIsEmpty = !directiveActivationDate || directiveActivationDate.trim() === ''

  const presentDate = startOfDay(today())
  const defaultDate = startOfDay(fromISOdate(defaultActivationDate))
  const parsedDirectiveDeactivationDate = directiveDeactivationDate
    ? startOfDay(fromISOdate(directiveDeactivationDate))
    : null

  if (
    activationDateIsEmpty &&
    parsedDirectiveDeactivationDate &&
    isBefore(parsedDirectiveDeactivationDate, presentDate)
  ) {
    // If the deactivation date is specified and it's earlier
    // than today, activation date should not be set here.
    return null
  }

  if (activationDateIsEmpty) {
    // If the directive activation date is not provided or is an empty string,
    // return the default activation date as the fallback option.
    return defaultActivationDate
  }

  const parsedDirectiveActivationDate = startOfDay(fromISOdate(directiveActivationDate))

  if (
    parsedDirectiveDeactivationDate &&
    isBefore(parsedDirectiveActivationDate, defaultDate) &&
    isAfter(parsedDirectiveDeactivationDate, parsedDirectiveActivationDate)
  ) {
    // If the directive activation date is specified, it's earlier than the default date,
    // AND the deactivation date is after, the activation date should not be set here.
    return null
  }

  if (
    isSameDayOrLater(parsedDirectiveActivationDate, presentDate) &&
    isSameDayOrLater(parsedDirectiveActivationDate, defaultDate)
  ) {
    // If the directive activation date is on or after both the default
    // activation date and the present date, use the directive activation date.
    return directiveActivationDate
  }

  if (
    isAfter(parsedDirectiveActivationDate, defaultDate) &&
    isSameDay(parsedDirectiveActivationDate, presentDate)
  ) {
    // If the directive activation date is after the default activation date but
    // before the present date, set the calculated activation date to the present date.
    return toISOdate(presentDate)
  }

  if (isAfter(defaultDate, parsedDirectiveActivationDate)) {
    // If the directive activation date is before the default activation date,
    // use the default activation date instead.
    return defaultActivationDate
  }

  // In case all other checks fail, safely assume that the default activation
  // date should be used.
  return defaultActivationDate
}

export const prepareDirectives = (
  fileDirectives: Directive[],
  defaultActivationDate: string,
  datePickerSelected?: boolean,
) => {
  return fileDirectives.map((directive) => {
    const calculatedActivationDate = calculateActivationDate(
      directive.activationDate,
      directive.deactivationDate,
      defaultActivationDate,
      datePickerSelected,
    )

    return {
      ...directive,
      // Normalize unique_identifier so it can be compared with the unique_identifier from the API's response
      uniqueIdentifier: directive.uniqueIdentifier.trim().toLowerCase(),
      activationDate: calculatedActivationDate || directive.activationDate,
    }
  })
}
