import { memo, useCallback, useEffect, useMemo } from 'react'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { trackPageView } from '@snowplow/browser-tracker'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Page from 'app/layout/Page'
import { NoAccess } from 'app/lib/components/NoAccess'
import { NoResults } from 'app/lib/components/NoResults'
import { SearchBar } from 'app/lib/components/SearchBar'
import { SortOrder } from 'app/lib/legacy-api/scribe.api'
import { useLazyListEligibilityRecordsQuery, useListOrganizationsQuery } from 'app/redux/scribeApi'
import { Manage, NoSearchResults } from 'assets/images'

import { Table } from './Table'

export const DEFAULT_LIMIT = 50

const NoMember = ({ isSearch }: { isSearch: boolean }) => {
  const { t } = useTranslation()
  if (isSearch) {
    return (
      <NoResults
        title={t('eligibilityRecordListPage.eligibilityRecordsTable.noMemberFound')}
        subtitle={t('eligibilityRecordListPage.eligibilityRecordsTable.searchNoMatch')}
        img={<NoSearchResults title="no_member_found" />}
      />
    )
  }
  return (
    <NoResults
      title={t('eligibilityRecordListPage.eligibilityRecordsTable.searchMember')}
      subtitle={t('eligibilityRecordListPage.eligibilityRecordsTable.typeInTheSearch')}
      img={<Manage title="no_member_found" />}
    />
  )
}

export const EligibilityRecordSearchPage = memo(() => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [fetchEligibilityRecords, { data: records, isFetching }] =
    useLazyListEligibilityRecordsQuery()
  const { data: organizationsResp, isFetching: isFetchingOrgs } = useListOrganizationsQuery({})
  const { data: organizations = [] } = organizationsResp || {}
  const hasNoOrganization = !isFetchingOrgs && organizations?.length === 0

  const querySearchValue = useMemo(() => searchParams.get('search'), [searchParams])
  const queryPageValue = useMemo(() => Number(searchParams.get('page')), [searchParams])

  const fetchRecords = useCallback(
    (page: number, search?: string | null) => {
      const params = {
        order: SortOrder.DESCENDING,
        offset: DEFAULT_LIMIT * page,
        limit: DEFAULT_LIMIT,
        search: search || undefined,
      }

      fetchEligibilityRecords({ params })
    },
    [fetchEligibilityRecords],
  )

  const handleMemberSearch = (search: string) => {
    const params = Object.fromEntries(searchParams.entries())
    if (search) setSearchParams({ ...params, search })
    else setSearchParams({})
  }

  const onPageChange = useCallback(
    (pageNumber: number) => {
      if (querySearchValue)
        setSearchParams({ page: pageNumber.toString(), search: querySearchValue })
      else setSearchParams({ page: pageNumber.toString() })
    },
    [setSearchParams, querySearchValue],
  )

  useEffect(() => {
    trackPageView({ title: 'eligibility-record-search' })
  }, [])

  useEffect(() => {
    if (querySearchValue) fetchRecords(queryPageValue, querySearchValue)
  }, [querySearchValue, queryPageValue, fetchRecords])

  return (
    <Page isLoading={isFetching || isFetchingOrgs}>
      {hasNoOrganization ? (
        <NoAccess />
      ) : (
        <>
          <Stack direction="row" alignItems="center" flexWrap="wrap" gap={4}>
            <Typography variant="h1" display="flex" alignItems="top">
              {t('eligibilityRecordListPage.pageTitleAll')}
              <Tooltip
                title={t('eligibilityRecordListPage.titleAllTooltip')}
                componentsProps={{ tooltip: { sx: { maxWidth: 'none' } } }}
                arrow
              >
                <HelpOutlineIcon sx={{ fontSize: 14, ml: 0.5, mt: 1, cursor: 'pointer' }} />
              </Tooltip>
            </Typography>
            <Box sx={{ minWidth: '500px' }}>
              <SearchBar
                inputLabel={t('eligibilityRecordListPage.searchForAMember')}
                onSearch={handleMemberSearch}
                search={querySearchValue || ''}
                minSearchTermLength={3}
                data-testid={'search-member-input'}
              />
            </Box>
          </Stack>
          <Box
            sx={{
              mb: 3,
              mt: 4,
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: querySearchValue && records?.data.length ? 'start' : 'center',
            }}
          >
            {querySearchValue && records?.data.length ? (
              <Table
                records={records.data}
                page={queryPageValue}
                onPageChange={onPageChange}
                count={records.meta.totalItems}
              />
            ) : (
              !isFetching && <NoMember isSearch={!!querySearchValue} />
            )}
          </Box>
        </>
      )}
    </Page>
  )
})
