import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Typography } from '@mui/material'
import { trackPageView } from '@snowplow/browser-tracker'
import { differenceInDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Page from 'app/layout/Page'
import { HealthSessionsBanner } from 'app/lib/components/Banner'
import {
  EngagementReports,
  MINIMUM_ACTIVE_MEMBERS,
  MINIMUM_DAYS_SINCE_LAUNCH_DATE,
  ReportAccessProps,
  ReportAccessType,
} from 'app/lib/components/EngagementReports'
import { STRETCH_FULL_HEIGHT } from 'app/lib/constants'
import { fetchOrganizationReports } from 'app/lib/legacy-api/scribe.api'
import { fromISOdate, today } from 'app/lib/utils/date'
import { LocalizedUrlsByProgram, Organization, OrganizationRoute } from 'app/models/scribe.models'
import { useLegacyGetOrganizationQuery } from 'app/redux/scribeApi'

export const determineReportAccessProps = (organization: Organization): ReportAccessProps => {
  const activeMembers = organization.members.active
  if (activeMembers < MINIMUM_ACTIVE_MEMBERS) {
    return {
      access: ReportAccessType.INSUFFICIENT_MEMBERS,
      numberOfMissingMembers: MINIMUM_ACTIVE_MEMBERS - activeMembers,
    }
  }

  const launchDate = fromISOdate(organization.billingStartDate || organization.created)
  const fullDaysSinceLaunch = differenceInDays(today(), launchDate)
  if (fullDaysSinceLaunch < MINIMUM_DAYS_SINCE_LAUNCH_DATE) {
    return {
      access: ReportAccessType.NOT_YET_AVAILABLE,
      // if there's even 1 second left - we still ask to wait "one more day"
      remainingDays: MINIMUM_DAYS_SINCE_LAUNCH_DATE - fullDaysSinceLaunch,
    }
  }

  return {
    access: ReportAccessType.ENABLED,
  }
}

export const OrganizationReportsPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { t } = useTranslation()

  const { organizationId } = useParams() as OrganizationRoute

  const {
    data: organization,
    isLoading: orgLoading,
    isError: orgError,
  } = useLegacyGetOrganizationQuery(organizationId)

  const [ihpReportsUrlsLoading, setIhpReportsUrlsLoading] = useState(true)
  const [ihpReportsUrlsError, setIhpReportsUrlsError] = useState(false)
  const [localizedUrlsByProgram, setLocalizedUrlsByProgram] =
    useState<LocalizedUrlsByProgram | null>(null)
  const [reportAccessProps, setReportAccessProps] = useState<ReportAccessProps | null>(null)

  useEffect(() => {
    trackPageView({ title: 'organization-reports' })
  }, [])

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => fetchOrganizationReports(token, organizationId))
      .then((reports) => {
        setLocalizedUrlsByProgram(reports.ihpReportsUrls)
        setIhpReportsUrlsError(false)
      })
      .catch(() => {
        setLocalizedUrlsByProgram(null)
        setIhpReportsUrlsError(true)
      })
      .finally(() => {
        setIhpReportsUrlsLoading(false)
      })
  }, [getAccessTokenSilently, organizationId, setLocalizedUrlsByProgram])

  useEffect(() => {
    if (orgError || ihpReportsUrlsError) {
      setReportAccessProps({ access: ReportAccessType.UNKNOWN_ERROR })
    } else if (organization) {
      setReportAccessProps(determineReportAccessProps(organization))
    }
  }, [organization, orgError, ihpReportsUrlsError])

  return (
    <Page>
      <HealthSessionsBanner />
      <Typography variant="h1" sx={{ mb: 3 }} data-testid="page-title">
        {t('organizationReportsPage.pageTitle', { organizationName: organization?.name })}
      </Typography>
      <Box sx={{ mt: 3, ...STRETCH_FULL_HEIGHT }}>
        {reportAccessProps && !ihpReportsUrlsLoading && !orgLoading && (
          <EngagementReports urlsPerProgram={localizedUrlsByProgram} {...reportAccessProps} />
        )}
      </Box>
    </Page>
  )
}
