import { useMemo, useState } from 'react'

import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { AttributeRow, AttributeRowProps } from 'app/lib/components/AttributeRow'
import { DEFAULT_INVITATION_TIME, REGIONS, ScribePermission, TIME_FORMATS } from 'app/lib/constants'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { organizationEdit, organizationReportingColumns } from 'app/lib/routes'
import { formatDate, todayWithTime } from 'app/lib/utils/date'
import { isRegion } from 'app/lib/utils/regions'
import { Organization } from 'app/models/scribe.models'

import { ResendEmailDialog } from './ResendEmailDialog'

interface Props {
  organization: Organization
}

const isUSRegion = isRegion(REGIONS.US)

export const OrganizationInfo: React.FC<Props> = ({ organization }) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const navigate = useNavigate()
  const { id } = organization
  const orgAddress = organization.addresses[0]
  const userPermissions = useUserPermissions()

  const [openResendEmailDialog, setOpenResendEmailDialog] = useState(false)

  const canEditWelcomeEmailAndReportingColumns = userPermissions?.includes(
    ScribePermission.MANAGE_ORGANIZATIONS,
  )

  const canReadNotesAndBrandInfo = userPermissions?.includes(ScribePermission.CREATE_ORGANIZATION)
  const canReadRestrictedFields = userPermissions?.includes(ScribePermission.UPDATE_ORGANIZATION)
  const canReadBilling = userPermissions?.includes(ScribePermission.READ_ORGANIZATION_BILLING)

  const enrolmentCode = organization.meta?.organizationEnrolmentCode
  const automaticBilling = organization.chargebeeId ? t('global.yes') : t('global.no')

  const invitationEmailTime = organization.invitationEmailTime || DEFAULT_INVITATION_TIME

  const communicationsAllowed = organization.communicationsAllowed
    ? `${t('global.yes')}, ${format(todayWithTime(invitationEmailTime), TIME_FORMATS.INVITATION_TIME)} UTC`
    : t('global.no')

  const address = useMemo(() => {
    if (!orgAddress) return ''
    const { streetAddress, suite, city, adminAreaIsoCode, postalCode } = orgAddress

    const location = [streetAddress, suite].filter(Boolean).join(' ')
    const area = [adminAreaIsoCode?.slice(3), postalCode].filter(Boolean).join(' ')

    return [location, city, area].filter(Boolean).join(', ')
  }, [orgAddress])

  const navigateToEditOrganization = () => navigate(organizationEdit.get(id))

  const navigateToUpdatingReportingColumns = () => navigate(organizationReportingColumns.get(id))

  const createdDate =
    organization.created !== null ? formatDate(organization.created, resolvedLanguage, 'long') : ''
  const billingStartDate =
    organization.billingStartDate && canReadBilling
      ? formatDate(organization.billingStartDate, resolvedLanguage, 'long')
      : null

  const emailPreference: string = t(`global.languagePreference.${organization.emailPreference}`)
  const billingMethod: string = canReadBilling
    ? t(`global.billingMethodStatus.${organization.billingMethod}`)
    : ''

  const profileDetailsAttributes: AttributeRowProps[] = [
    {
      label: t(
        `organizationPage.form.profile.${isUSRegion ? 'displayName' : 'displayNameEnglish'}`,
      ),
      value: organization.displayName.en,
    },
    {
      label: t('organizationPage.form.profile.memberIdType'),
      value: t(`global.memberIdTypeStatus.${organization.memberIdType}`),
    },
    ...(!isUSRegion
      ? [
          {
            label: t('organizationPage.form.profile.displayNameFrench'),
            value: organization.displayName.fr,
          },
        ]
      : []),
    { label: t('organizationProfileInfo.activeMembers'), value: organization.members.active },
    {
      label: t('organizationPage.form.profile.streetAddress'),
      value: address,
    },
    { label: t('organizationProfileInfo.futureMembers'), value: organization.members.future },
    {
      label: t('organizationProfileInfo.created'),
      value: createdDate,
    },
    {
      label: t('organizationProfileInfo.deactivatedMembers'),
      value: organization.members.inactive,
    },
    ...(!isUSRegion
      ? [
          {
            label: t('organizationPage.form.profile.emailPreference'),
            value: emailPreference,
          },
        ]
      : []),
    {
      label: t('organizationPage.form.attributes.communicationsAllowed'),
      value: communicationsAllowed,
    },
    ...(canReadNotesAndBrandInfo
      ? [
          {
            label: t('organizationPage.form.profile.brandId'),
            value: organization.brand.data.id,
          },
        ]
      : []),
    ...(canReadRestrictedFields && enrolmentCode
      ? [
          {
            label: t('organizationProfileInfo.enrolmentCode'),
            value: enrolmentCode,
          },
        ]
      : []),
  ]

  const notesAttributes: AttributeRowProps[] = [
    { label: t('organizationPage.form.notes.clientNotes'), value: organization.clientNotes },
    { label: t('organizationPage.form.notes.careTeamNotes'), value: organization.careTeamNotes },
  ]

  const billingAttributes: AttributeRowProps[] = canReadBilling
    ? [
        {
          label: t('organizationPage.form.billing.billingEmail'),
          value: organization.billingEmail,
        },
        {
          label: t('organizationPage.form.billing.billingStartDate'),
          value: billingStartDate,
        },
        {
          label: t('organizationPage.form.billing.billingMethod'),
          value: billingMethod,
        },
        {
          label: t('organizationPage.form.billing.accountingMethod'),
          value: automaticBilling,
        },
      ]
    : []

  const attributeMapper = (attr: AttributeRowProps) => (
    <Grid item xs={12} md={6} key={attr.label?.toString()} height="100%">
      <AttributeRow {...attr} />
    </Grid>
  )

  const showResendEmailButton =
    organization.communicationsAllowed && organization.members.active > 0

  const onOpenResendEmailDialog = () => setOpenResendEmailDialog(true)
  const onCloseResendEmailDialog = () => setOpenResendEmailDialog(false)

  return (
    <>
      <Typography variant="h1">{organization.name}</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('organizationProfileInfo.title')}</Typography>
        <Stack direction="row" spacing={2}>
          {canEditWelcomeEmailAndReportingColumns && (
            <>
              {!!showResendEmailButton && (
                <>
                  <Button variant="outlined" onClick={onOpenResendEmailDialog}>
                    {t('resendWelcomeEmail.actionLabel')}
                  </Button>
                  <ResendEmailDialog
                    organization={organization}
                    open={openResendEmailDialog}
                    onClose={onCloseResendEmailDialog}
                  />
                </>
              )}
              <Button variant="outlined" onClick={navigateToUpdatingReportingColumns}>
                {t('organizationProfileInfo.updateReportingColumns')}
              </Button>
            </>
          )}
          <Button sx={{ ml: 2 }} variant="contained" onClick={navigateToEditOrganization}>
            {t('organizationProfileInfo.edit')}
          </Button>
        </Stack>
      </Box>
      <Card>
        <CardContent sx={{ p: 3 }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h3" fontSize={24} mb={2}>
                {t('organizationProfileInfo.profileDetails')}
              </Typography>
            </Grid>
            {profileDetailsAttributes.map(attributeMapper)}
          </Grid>
          {canReadNotesAndBrandInfo && (
            <>
              <Divider sx={{ borderColor: 'accent.main', mt: 2, mb: 4 }} />
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h3" fontSize={24} mb={2}>
                    {t('organizationPage.form.notes.title')}
                  </Typography>
                </Grid>
                {notesAttributes.map(attributeMapper)}
              </Grid>
            </>
          )}
          {canReadBilling && (
            <>
              <Divider sx={{ borderColor: 'accent.main', mt: 2, mb: 4 }} />
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h3" fontSize={24} mb={2}>
                    {t('organizationPage.form.billing.title')}
                  </Typography>
                </Grid>
                {billingAttributes.map(attributeMapper)}
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </>
  )
}
