import { useTranslation } from 'react-i18next'

import { CONNECTION_TYPES } from '../connections'

import BaseConnection from './BaseConnection'

const Okta = () => {
  const { t } = useTranslation()
  return (
    <BaseConnection
      title={t('loginConnectionPage.okta.title')}
      buttonText={t('loginConnectionPage.okta.loginButton')}
      connection={CONNECTION_TYPES.OKTA}
    />
  )
}

export default Okta
