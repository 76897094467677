import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { jwtDecode } from 'jwt-decode'

const TENANT_CLAIMS = 'https://dialogue/claims/tenant_id'

export const getTenantId = (token: Record<string, string>) => token?.[TENANT_CLAIMS] || undefined

export const useTokenData = () => {
  const [tokenData, setTokenData] = useState<Record<string, any>>({})
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const decoded: Record<string, any> = jwtDecode(token)
      setTokenData(decoded || {})
    })
  }, [getAccessTokenSilently])

  return tokenData
}
