import { FormHelperText, MenuItem, Select, SelectProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type SelectFormFieldProps = SelectProps & {
  name: string
  options: {
    label: string
    value: any
  }[]
  hideLabel?: boolean
}

const SelectFormField = ({ name, options, hideLabel = false, ...props }: SelectFormFieldProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <>
          <Select
            fullWidth
            size="small"
            {...field}
            {...props}
            label={hideLabel ? '' : props.label}
            error={!!error?.message}
          >
            {options.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText sx={{ mx: 1.75 }} error>
              {/* @ts-ignore */}
              {t(error?.message, { label: props.label })}
            </FormHelperText>
          )}
        </>
      )}
    />
  )
}

export default SelectFormField
