import { Box, BoxProps, Typography, TypographyProps } from '@mui/material'

import { colors } from 'app/theme'

export enum LabelType {
  DEFAULT = 'default',
  ARCHIVED = 'archived',
}

const LabelStyle = {
  [LabelType.DEFAULT]: {
    backgroundColor: colors.dialoguePeach,
  },
  [LabelType.ARCHIVED]: {
    backgroundColor: colors.dialogueCharcoal,
    color: colors.dialogueLinen,
  },
}

type Props = {
  text: String
  type: LabelType
  boxProps?: BoxProps['sx']
  textProps?: TypographyProps['sx']
}

export const Label: React.FC<Props> = ({ text, type, boxProps, textProps, ...rest }) => (
  <Box mt={1.25} pr={1} sx={{ flex: 1, ...boxProps }} {...rest}>
    <Typography
      variant="caption1"
      p={0.5}
      sx={{ ...(LabelStyle[type] || {}), borderRadius: 0.5, ...textProps }}
    >
      {text}
    </Typography>
  </Box>
)
