import { Theme, Backdrop, CircularProgress } from '@mui/material'

type LoadingBackdropProps = Readonly<{
  loading: boolean
}>

export const LoadingBackdrop: React.FC<LoadingBackdropProps> = ({ loading }) => {
  const incrementModalZIndex = (theme: Theme) => theme.zIndex.modal + 1

  return (
    <Backdrop sx={{ zIndex: incrementModalZIndex }} open={loading} data-testid="loading-backdrop">
      <CircularProgress size={80} />
    </Backdrop>
  )
}
