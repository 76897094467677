import { LoadingButton } from '@mui/lab'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

type FooterProps = {
  isEdit: boolean
  isDirty: boolean
  isLoading?: boolean
  onCancel: () => void
}

export const Footer: React.FC<FooterProps> = ({ isEdit, isDirty, isLoading = false, onCancel }) => {
  const { t } = useTranslation()
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      <Button variant="outlined" onClick={onCancel}>
        {t('global.dialog.cancel')}
      </Button>
      <LoadingButton
        variant="contained"
        color="primary"
        type="submit"
        disabled={!isDirty}
        loading={isLoading}
      >
        {t(isEdit ? 'actions.save' : 'global.dialog.next')}
      </LoadingButton>
    </Stack>
  )
}
