import { SyntheticEvent, useState } from 'react'

import { TabContext, TabList } from '@mui/lab'
import { Tab, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import Page from 'app/layout/Page'
import { NoResults } from 'app/lib/components/NoResults'
import { FamilyMemberType } from 'app/models/scribe.models'
import { useGetUserQuery } from 'app/redux/scribeApi'
import { NoSearchResults } from 'assets/images'

import { ExistingUserTab } from './ExistingUserTab'
import { NewAdultUserTab } from './NewAdultUserTab'
import { NewChildUserTab } from './NewChildUserTab'

enum TabType {
  EXISTING_USER = 'existingUser',
  NEW_USER = 'newUser',
}

export const AddAdultFamilyMemberPage = () => {
  const { t } = useTranslation()

  const { userId = '' } = useParams()
  const { isLoading, isError } = useGetUserQuery(userId)
  const [searchParams] = useSearchParams()
  const isAdultType = searchParams.get('type') === FamilyMemberType.ADULT
  const [tabValue, setTabValue] = useState(TabType.EXISTING_USER)

  const handleTabChange = (_event: SyntheticEvent, value: TabType) => {
    setTabValue(value)
  }

  return (
    <Page
      isLoading={isLoading}
      error={
        isError && (
          <NoResults
            title={t('userProfile.notFound')}
            img={<NoSearchResults title="no_results" />}
          />
        )
      }
    >
      <Typography variant="h1" mb={6} data-testid="page-title">
        {t(isAdultType ? 'addMember.pageTitleAdult' : 'addMember.pageTitleChild')}
      </Typography>
      <TabContext value={tabValue}>
        <TabList onChange={handleTabChange}>
          <Tab label={t('addMember.existingUser')} value={TabType.EXISTING_USER} />
          <Tab label={t('addMember.newUser')} value={TabType.NEW_USER} />
        </TabList>
        {tabValue === TabType.EXISTING_USER && <ExistingUserTab />}
        {tabValue === TabType.NEW_USER && (isAdultType ? <NewAdultUserTab /> : <NewChildUserTab />)}
      </TabContext>
    </Page>
  )
}
