import { useCallback, useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SearchBar } from 'app/lib/components/SearchBar'
import { Manage, NoSearchResults, Refresh } from 'assets/images'

export const DEFAULT_LIMIT = 5

// Style
const style = {
  info: { maxWidth: '50%', margin: '0 auto 2em', textAlign: 'center' },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(55, 55, 54, 0.6)',
  },
  memberBox: { margin: 4 },
  memberList: { boxShadow: '0px 2px 4px rgba(188, 188, 188, 0.5)' },
}

type TableData = {
  rows: JSX.Element[]
  cells?: JSX.Element[]
  totalItems: number | undefined
}

type SearchMemberProps = Readonly<{
  open: boolean
  onSelectModalClose?: boolean
  title: string
  handleFetch: (page: number, search?: string | null) => void
  isFetching: boolean
  isError: boolean
  tableData: TableData
  onClose: () => void
  enableLargeModal?: boolean
  onCancel?: boolean
}>

export const SearchMember: React.FC<SearchMemberProps> = ({
  onClose,
  enableLargeModal = false,
  onSelectModalClose,
  open,
  tableData,
  isFetching,
  isError,
  handleFetch,
  title,
  onCancel,
}) => {
  const { t } = useTranslation()

  const [activeSearch, setActiveSearch] = useState<string>('')
  const [activePage, setActivePage] = useState<number>(0)
  const [showInitialState, setShowInitialState] = useState(true)
  const showMembers = !showInitialState && tableData.rows.length > 0
  const showEmptyState = !showInitialState && tableData.totalItems === 0
  const showErrorState = !showInitialState && isError

  useEffect(() => {
    // Clear initial state out once API returns a response
    if ((!isFetching && (tableData.rows.length || tableData.totalItems === 0)) || isError) {
      setShowInitialState(false)
    }
  }, [isFetching, isError, tableData.rows.length, tableData.totalItems])

  const fetchRecords = useCallback(
    (page: number, search?: string | null) => {
      handleFetch(page, search)
    },
    [handleFetch],
  )

  const resetValues = useCallback(() => {
    setShowInitialState(true)
    setActiveSearch('')
    setActivePage(0)
  }, [])

  const handleMemberSearch = useCallback(
    (searchValue: string) => {
      if (!searchValue) {
        resetValues()
        return
      }

      if (activePage > 0) setActivePage(0)
      setActiveSearch(searchValue)
      fetchRecords(0, searchValue)
    },
    [activePage, fetchRecords, resetValues],
  )

  const handleModalClose = useCallback(() => {
    onClose()
    setTimeout(() => {
      resetValues()
    }, 250)
  }, [onClose, resetValues])

  useEffect(() => {
    if (onSelectModalClose || onCancel) resetValues()
  }, [onSelectModalClose, onCancel, resetValues])

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      setActivePage(pageNumber)
      fetchRecords(pageNumber, activeSearch)
    },
    [activeSearch, fetchRecords],
  )

  const checkModalWidth = showMembers && enableLargeModal

  return (
    <Dialog
      maxWidth={checkModalWidth ? 'lg' : 'md'}
      fullWidth
      open={open}
      onClose={handleModalClose}
      data-testid="member-modal"
    >
      <Box sx={{ padding: 2.5 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <DialogTitle>{title}</DialogTitle>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={handleModalClose}>
              <CloseIcon fontSize="small" sx={{ color: 'primary.main' }} />
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ maxWidth: '512px', marginLeft: 3 }}>
          <SearchBar
            inputLabel={t('searchMember.modal.search')}
            onSearch={handleMemberSearch}
            autofocus
          />
        </Box>
        {showInitialState && (
          <Grid container direction="column" alignItems="center" sx={style.info}>
            <Manage />
            <Box sx={{ marginTop: -1.5, marginBottom: 0.5 }}>
              <Typography variant="h3">{t('searchMember.modal.initial.subtitle')}</Typography>
            </Box>
            <Typography variant="body1">{t('searchMember.modal.initial.copy')}</Typography>
          </Grid>
        )}
        {showEmptyState && (
          <Grid
            container
            direction="column"
            alignItems="center"
            sx={style.info}
            data-testid="no-results"
          >
            <NoSearchResults />
            <Box sx={{ marginTop: -1.5, marginBottom: 0.5 }}>
              <Typography variant="h3">{t('searchMember.modal.empty.subtitle')}</Typography>
            </Box>
            <Typography variant="body1">{t('searchMember.modal.empty.copy')}</Typography>
          </Grid>
        )}
        {showMembers && (
          <Box sx={style.memberBox}>
            <TableContainer sx={{ overflow: 'visible' }}>
              <Table data-testid="members-table">
                <TableHead>
                  <TableRow>{tableData.cells}</TableRow>
                </TableHead>
                <TableBody data-testid="eligibility-record-table" sx={style.memberList}>
                  {tableData.rows}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      data-testid="pagination-footer"
                      page={activePage}
                      rowsPerPage={DEFAULT_LIMIT}
                      rowsPerPageOptions={[]}
                      count={tableData.totalItems || 0}
                      showFirstButton
                      showLastButton
                      onPageChange={(_event, pageValue) => handlePageChange(pageValue)}
                      getItemAriaLabel={(type) => t(`global.pagination.${type}`)}
                      labelDisplayedRows={({ from, to, count: itemsCount }) =>
                        itemsCount > 0
                          ? t('global.pagination.of', { from, to, count: itemsCount })
                          : t('global.pagination.of_more_than', { from, to })
                      }
                      sx={{ borderBottom: 'none' }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        )}
        {showErrorState && (
          <Grid
            container
            direction="column"
            alignItems="center"
            sx={style.info}
            data-testid="error-state"
          >
            <Refresh />
            <Box sx={{ marginTop: -1.5, marginBottom: 0.5 }}>
              <Typography variant="h3">{t('searchMember.modal.error.subtitle')}</Typography>
            </Box>
            <Typography variant="body1">{t('searchMember.modal.error.copy')}</Typography>
          </Grid>
        )}
      </Box>
      {isFetching && (
        <Grid container sx={style.loading} alignItems="center" justifyContent="space-around">
          <CircularProgress size={150} />
        </Grid>
      )}
    </Dialog>
  )
}
