import { useEffect } from 'react'

import Box from '@mui/material/Box'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Outlet } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { STRETCH_FULL_HEIGHT } from 'app/lib/constants'
import { OptionalOrganizationRoute } from 'app/models/scribe.models'
import { useLegacyGetOrganizationQuery, useListOrganizationsQuery } from 'app/redux/scribeApi'
import { getApplicationConfig } from 'config'

import { ScribePermission } from '../lib/constants'
import { useUserPermissions } from '../lib/hoc/withProtectedComponent'
import { organizationMemberList } from '../lib/routes'

import { ApplicationSideBar } from './application-bar/ApplicationSideBar'
import { ApplicationTopBar } from './application-bar/ApplicationTopBar'

export const Layout: React.FC = () => {
  const config = getApplicationConfig()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data: organizationsResp, isUninitialized } = useListOrganizationsQuery({})
  const hasOnlyOneOrganization = organizationsResp?.data?.length === 1
  const userPermissions = useUserPermissions()
  const intercom = useIntercom()
  const { supportChatbot: supportChatbotFlag } = useFlags()
  const { i18n } = useTranslation()

  // This exectutes only on the initial render. If the user has
  // access to only one organization, redirect to that organization.
  useEffect(() => {
    if (hasOnlyOneOrganization) {
      navigate(organizationMemberList.get(organizationsResp.data[0].id), { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOnlyOneOrganization])

  // Scroll to top each time the url path changes. (By default the browser never
  // resets the scrolling position even when the path changes)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    // Boot Intercom only if the user has an admin permission
    // (we're using list:eligibility-records here but could be any permission)
    // This safeguards against random users sending messages through Intercom
    if (
      !window.Cypress &&
      supportChatbotFlag &&
      userPermissions?.includes(ScribePermission.LIST_ELIGIBILITY_RECORDS)
    ) {
      intercom.boot()
    }
  }, [userPermissions, supportChatbotFlag, intercom])

  useEffect(() => {
    intercom.update({ languageOverride: i18n.resolvedLanguage })
  }, [i18n.resolvedLanguage, intercom])

  if (isUninitialized) {
    return null // wait to have organizations, to prevent flickering
  }
  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <ApplicationTopBar backgroundColor={config.theme.applicationBar.backgroundColor} />
      <SideBar
        backgroundColor={config.theme.applicationBar.backgroundColor}
        hideBackButton={hasOnlyOneOrganization}
      />
      <Box component="main" sx={{ flexGrow: 1, py: 4, px: 4, mt: 8, ...STRETCH_FULL_HEIGHT }}>
        <Outlet />
      </Box>
    </Box>
  )
}

interface SideBarProps {
  backgroundColor: string
  hideBackButton: boolean
}

export const SideBar = ({ backgroundColor, hideBackButton }: SideBarProps) => {
  const { organizationId } = useParams() as OptionalOrganizationRoute
  const { data: organization } = useLegacyGetOrganizationQuery(organizationId ?? skipToken)
  const { ihpReports: ihpReportsFlag } = useFlags()

  const displayReportsTab = ihpReportsFlag && organization?.reportsEnabled

  return (
    <ApplicationSideBar
      backgroundColor={backgroundColor}
      hideBackButton={hideBackButton}
      displayReportsTab={displayReportsTab}
      organizationId={organizationId}
    />
  )
}
