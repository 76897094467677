import { ReactElement, ReactNode, useState } from 'react'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { buildHeader } from 'app/lib/utils/export-records-to-csv'
import {
  EligibilityDirectiveError,
  EligibilityRecordAttributes,
  Language,
} from 'app/models/scribe.models'

import { EN_TO_FR_COLUMNS, translate } from '../utils/helpers'
import { ProcessingResults, ResultType } from '../utils/process-directives'

interface ResultTableProps {
  error: boolean
  results: EligibilityRecordAttributes[] | EligibilityDirectiveError[]
}
const ResultTable: React.FC<ResultTableProps> = ({ results, error }) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  // FIXME: remove ternaries for error
  const headers = error
    ? ['lineNumber', 'uniqueIdentifier', 'errorMessage', 'field']
    : buildHeader(results)
  const headerRow = headers.map((field) => (
    <TableCell key={field} variant="head">
      {/* @ts-ignore: FIXME: typing */}
      {error ? t(`csvImport.directiveErrorHeader.${field}`) : t(`csvImport.csvHeaders.${field}`)}
    </TableCell>
  ))

  const bodyRows = results.map((result, i) => {
    let values
    if (error) {
      result = result as EligibilityDirectiveError
      // @ts-ignore: FIXME: typing based on message
      const message = t(`csvImport.directiveError.${result.message}`, result.message)
      const field =
        resolvedLanguage === Language.FR ? translate(result.field, EN_TO_FR_COLUMNS) : result.field
      values = [result.lineNumber, result.uniqueIdentifier, message, field]
    } else {
      values = headers.map((headerKey) => result[headerKey as keyof typeof result])
    }
    const cells = values.map((vfield, j) => <TableCell key={j}>{vfield as ReactNode}</TableCell>)
    return <TableRow key={i}>{cells}</TableRow>
  })

  return (
    <TableContainer sx={{ maxHeight: 220 }}>
      <Table className="borderless">
        <TableHead>
          <TableRow>{headerRow}</TableRow>
        </TableHead>
        <TableBody>{bodyRows}</TableBody>
      </Table>
    </TableContainer>
  )
}

interface CollapsibleListItemProps {
  label: string
  item: ReactElement
  open: boolean
  onClick: () => void
}
const CollapsibleListItem: React.FC<CollapsibleListItemProps> = ({
  label,
  item,
  open,
  onClick,
}) => {
  return (
    <>
      <ListItemButton onClick={onClick}>
        <ListItemText primary={label} data-testid="result-sublist-label" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        {item}
      </Collapse>
      <Divider />
    </>
  )
}

interface ResultSubList {
  type: ResultType
  results: EligibilityRecordAttributes[] | EligibilityDirectiveError[]
}
interface CollapsibleResultsProps {
  processingResults: ProcessingResults
}

export const CollapsibleResults: React.FC<CollapsibleResultsProps> = ({
  processingResults: {
    activatedRecords,
    unchangedRecords,
    deactivatedRecords,
    futureActivatedRecords,
    errors,
  },
}) => {
  const { t } = useTranslation()
  const [expandedItem, setExpandedItem] = useState<number | null>(null)

  const resultSublists: ResultSubList[] = [
    { type: ResultType.ACTIVATED, results: activatedRecords },
    { type: ResultType.FUTURE_ACTIVATED, results: futureActivatedRecords },
    { type: ResultType.UNCHANGED, results: unchangedRecords },
    { type: ResultType.DEACTIVATED, results: deactivatedRecords },
    { type: ResultType.ERROR, results: errors },
  ]

  return (
    <List data-testid="result-list">
      {resultSublists.map(
        ({ type, results }, ix) =>
          !!results.length && (
            <CollapsibleListItem
              label={t(`csvImport.summary.results.${type}`, { count: results.length })}
              item={<ResultTable results={results} error={type === ResultType.ERROR} />}
              open={expandedItem === ix}
              key={ix}
              onClick={() => {
                expandedItem === ix ? setExpandedItem(null) : setExpandedItem(ix)
              }}
            />
          ),
      )}
    </List>
  )
}
