import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import enTranslations from './locales/en.json'
import frTranslations from './locales/fr.json'

export const defaultNS = 'translation'
export const resources = {
  en: {
    translation: enTranslations,
  },
  fr: {
    translation: frTranslations,
  },
} as const
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    defaultNS,
    resources,
    fallbackLng: {
      fr: ['fr'],
      default: ['en'],
    },
    interpolation: { escapeValue: false },
  })

export { i18n }
