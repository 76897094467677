import { EligibilityRecord, EngagementReportUrls, PaginationV2 } from 'app/models/scribe.models'

import { V2EligibilityRecord, V2IhpReportsUrls, V2ListQueryMeta } from './v2.models'

const convertEligibilityRecordAttributes = (
  attributes: V2EligibilityRecord['attributes'],
): EligibilityRecord['attributes'] => {
  const {
    unique_identifier,
    first_name,
    last_name,
    date_of_birth,
    province,
    communication_email,
    label,
    ...extra_attributes
  } = attributes
  return {
    uniqueIdentifier: unique_identifier,
    firstName: first_name,
    lastName: last_name,
    dateOfBirth: date_of_birth,
    province: province,
    communicationEmail: communication_email,
    label,
    ...extra_attributes,
  }
}

export function fromV2EligibilityRecord(record: V2EligibilityRecord): EligibilityRecord {
  return {
    id: record.id,
    attributes: convertEligibilityRecordAttributes(record.attributes),
    status: record.status,
    organizationId: record.organization_id,
    organization: record.organization && {
      id: record.organization.id,
      name: record.organization.name,
    },
    eligibleIntervals: record.eligible_intervals.map((interval) => {
      return {
        planId: interval.plan_id,
        startDate: interval.start_date,
        endDate: interval.end_date,
        isActive: interval.is_active,
      }
    }),
  }
}

export function fromV2ListQueryMeta(meta: V2ListQueryMeta): PaginationV2 {
  return {
    offset: meta.offset,
    total: meta.total_items,
  }
}

export function fromV2FetchOrganizationReports(data: V2IhpReportsUrls): EngagementReportUrls {
  return {
    ihpReportsUrls: !data.ihp_reports_urls
      ? null
      : {
          execSummary: data.ihp_reports_urls.exec_summary,
          primaryCare: data.ihp_reports_urls.primary_care,
          mentalHealth: data.ihp_reports_urls.mental_health,
          employeeAssistanceProgram: data.ihp_reports_urls.employee_assistance_program,
          wellness: data.ihp_reports_urls.wellness,
        },
  }
}
