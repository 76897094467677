import { cannotHappen } from 'app/lib/utils/cannot-happen'
import { Pagination, OrganizationInvoice } from 'app/models/scribe.models'

export const DEFAULT_LIMIT = 10

export type OrganizationInvoiceListState = {
  loading: boolean
  organizationInvoices: Array<OrganizationInvoice>
  pagination: Pagination
}

export type OrganizationInvoiceListAction =
  | { type: 'ORGANIZATION_INVOICE_LIST_FETCH_START' }
  | {
      type: 'ORGANIZATION_INVOICE_LIST_FETCH_SUCCESS'
      organizationInvoices: Array<OrganizationInvoice>
      pagination: Pagination
    }
  | { type: 'ORGANIZATION_INVOICE_LIST_FETCH_ERROR' }

export const initialOrganizationInvoiceListState = Object.freeze<OrganizationInvoiceListState>({
  loading: true,
  organizationInvoices: [],
  pagination: { offset: 0, limit: DEFAULT_LIMIT, total: 0 },
})

export function reduceOrganizationInvoiceListState(
  state: OrganizationInvoiceListState,
  action: OrganizationInvoiceListAction,
): OrganizationInvoiceListState {
  if (action.type === 'ORGANIZATION_INVOICE_LIST_FETCH_START') {
    return { ...state, loading: true }
  } else if (action.type === 'ORGANIZATION_INVOICE_LIST_FETCH_SUCCESS') {
    const { organizationInvoices, pagination } = action
    return { ...state, loading: false, organizationInvoices, pagination }
  } else if (action.type === 'ORGANIZATION_INVOICE_LIST_FETCH_ERROR') {
    return { ...state, loading: false }
  } else {
    return cannotHappen(action)
  }
}
