import { useCallback } from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'

import { AdminAreaTrKeys } from 'app/lib/constants'
import { MemberIdType, Organization } from 'app/models/scribe.models'
import theme from 'app/theme'
import { getApplicationConfig } from 'config'

interface Props {
  onNext: () => void
  onClose: () => void
  onFileDrop: (file: File) => void
  file: File | null
  onReplaceFile: () => void
  importType: ImportTypes
  setImportType: (type: ImportTypes) => void
  isFileValidated: boolean
  error: string | React.ReactNode
  organizationType: Organization['memberIdType']
  assetsURL: string
}

export enum ImportTypes {
  ADD = 'add',
  UPDATE = 'update',
}

export const Import: React.FC<Props> = ({
  onClose,
  onNext,
  importType,
  setImportType,
  onFileDrop,
  file,
  onReplaceFile,
  isFileValidated,
  error,
  organizationType,
  assetsURL,
}) => {
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<any>) => {
    setImportType(event.target.value)
  }

  return (
    <>
      <DialogTitle>
        {t('csvImport.fileImport.title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <RadioGroup
          name="upload-type-group"
          defaultValue={ImportTypes.ADD}
          value={importType}
          onChange={handleChange}
          sx={{ mb: 3 }}
        >
          <FormControlLabel
            value={ImportTypes.ADD}
            label={
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h3">{t('csvImport.fileImport.add.label')}</Typography>
                  }
                  secondary={
                    <Typography variant="body2">
                      {t('csvImport.fileImport.add.description')}
                    </Typography>
                  }
                  sx={{ my: theme.typography.pxToRem(3) }}
                />
              </ListItem>
            }
            control={<Radio />}
          />
          <FormControlLabel
            value={ImportTypes.UPDATE}
            data-testid="update-members-button"
            label={
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h3">{t('csvImport.fileImport.update.label')}</Typography>
                  }
                  secondary={
                    <Typography variant="body2">
                      {t('csvImport.fileImport.update.description')}
                    </Typography>
                  }
                  sx={{ my: theme.typography.pxToRem(3) }}
                />
              </ListItem>
            }
            control={<Radio />}
          />
        </RadioGroup>
        {error && <Alert severity="error">{error}</Alert>}
        {!file && (
          <>
            <CSVDropzone
              onDrop={onFileDrop}
              organizationType={organizationType}
              assetsURL={assetsURL}
            />
            <Box textAlign="center" sx={{ p: 1 }}>
              <Typography variant="body2">{t('csvImport.explainerDateOfBirth')}</Typography>
              <Typography variant="body2">{t('csvImport.explainerMemberLocation')}</Typography>
            </Box>
          </>
        )}
        {file && <FileDisplay file={file} onReplaceFile={onReplaceFile} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('global.dialog.cancel')}</Button>
        <Button
          variant="contained"
          autoFocus
          data-testid="preview-button"
          onClick={onNext}
          disabled={!isFileValidated}
        >
          {t('global.dialog.preview')}
        </Button>
      </DialogActions>
    </>
  )
}

interface DropzoneProps {
  onDrop: (file: File) => void
  organizationType: Organization['memberIdType']
  assetsURL: string
}

const acceptedFiles = {
  'text/*': ['.csv'],
  'application/*': ['.csv'],
}

const CSVDropzone: React.FC<DropzoneProps> = ({ onDrop, organizationType, assetsURL }) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const handleDrop = useCallback(
    (files: File[]) => {
      onDrop(files[0])
    },
    [onDrop],
  )

  const { getRootProps, getInputProps, open, isDragAccept, isFileDialogActive } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    accept: acceptedFiles,
    multiple: false,
  })

  const { region } = getApplicationConfig()
  const adminAreaKey = AdminAreaTrKeys[region]

  return (
    <div
      {...getRootProps({
        style: { ...(isDragAccept ? { opacity: 0.7 } : {}) },
      })}
    >
      <input {...getInputProps()} />
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ px: 4, py: 3, border: `2px dashed ${theme.palette.accent.main}` }}
      >
        <Trans i18nKey="csvImport.dragAndDropCSV">
          <Typography variant="h3" sx={{ mb: 0.5 }}>
            Drag and drop
          </Typography>
          <Typography sx={{ m: 0 }}>or</Typography>
          <Button
            variant="outlined"
            onClick={isFileDialogActive ? undefined : open}
            sx={{ m: 1.5, alignSelf: 'center' }}
          >
            choose file
          </Button>
        </Trans>
        <Typography>{t('csvImport.fileMustContainEmailLocation')}</Typography>
        <Box textAlign="left">
          <Typography variant="subtitle2" pt={1}>
            {t('global.required')}:
          </Typography>
          <List
            sx={{
              listStyleType: 'disc',
              pl: 2,
              pt: 0,
              '& .MuiListItem-root': {
                display: 'list-item',
                padding: 0,
              },
            }}
          >
            <ListItem>
              <Typography variant="body2">
                {t('csvImport.csvHeaders.uniqueIdentifier')}{' '}
                {organizationType === MemberIdType.EMAIL &&
                  t('csvImport.csvHeaders.uniqueIdentifierHelp')}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">{t('csvImport.csvHeaders.firstName')}</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">{t('csvImport.csvHeaders.lastName')}</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                {t('csvImport.csvHeaders.dateOfBirth')}* {t('csvImport.csvHeaders.dateOfBirthHelp')}
              </Typography>
            </ListItem>
          </List>
          <Typography variant="subtitle2" pt={1}>
            {t('global.optional')}:
          </Typography>
          <List
            sx={{
              listStyleType: 'disc',
              pl: 2,
              pb: 1,
              pt: 0,
              '& .MuiListItem-root': {
                display: 'list-item',
                padding: 0,
              },
            }}
          >
            <ListItem>
              <Typography variant="body2">{t(`csvImport.csvHeaders.${adminAreaKey}`)}**</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">{t('csvImport.csvHeaders.plan')}</Typography>
            </ListItem>
          </List>
        </Box>
        <Typography>
          {t('csvImport.downloadTemplate')}
          <Link
            href={`${assetsURL}/csv-import/${organizationType}/${resolvedLanguage}/${t(
              'csvImport.templateName',
            )}`}
          >
            {t('csvImport.templateName')}
          </Link>
        </Typography>
      </Box>
    </div>
  )
}

interface FileDisplayProps {
  file: File
  onReplaceFile: () => void
}
const FileDisplay: React.FC<FileDisplayProps> = ({ file, onReplaceFile }) => {
  const { t } = useTranslation()

  return (
    <Paper elevation={0} variant="outlined">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container>
            <CheckCircleIcon color="success" sx={{ pl: 1, pr: 1 }} />
            <Typography>{file.name}</Typography>
          </Grid>
        </Grid>
        <Button onClick={onReplaceFile} variant="text" color="primary">
          {t('csvImport.fileImport.replace')}
        </Button>
      </Grid>
    </Paper>
  )
}
