import React, { useCallback, useEffect, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { FamilyMemberType, FamilyMembersData, Pagination } from 'app/models/scribe.models'
import {
  useGetEligibilityRecordQuery,
  useGetUserFamilyQuery,
  useEnrolUserToEligibilityRecordMutation,
  useUnEnrolUserToEligibilityRecordMutation,
} from 'app/redux/scribeApi'
import { colors } from 'app/theme'

import { UserInfoTable } from './UserInfoTable'

type Props = {
  openModal: boolean
  eligibilityId: number
  modalTitle: string
  onOpenConfirmation: (val: boolean) => void
  onOpen?: (val: boolean) => void
  onOpenSearch?: (val: boolean) => void
  onCancel?: (val: boolean) => void
  unEnrol?: boolean
}

const TABLE_HEADERS = [
  'userProfile.eligibilityRecord.firstName',
  'userProfile.eligibilityRecord.lastName',
  'userProfile.eligibilityRecord.familyDetails.userId',
  'userProfile.eligibilityRecord.familyDetails.email',
  'userProfile.eligibilityRecord.familyDetails.userType',
] as const

const userTypeTranslations = {
  guardian: 'userProfile.eligibilityRecord.familyMemberType.guardian',
  adult: 'userProfile.eligibilityRecord.familyMemberType.adult',
  dependent: 'userProfile.eligibilityRecord.familyMemberType.dependent',
  child: 'userProfile.eligibilityRecord.familyMemberType.child',
} as const

type UserType = keyof typeof userTypeTranslations

const DEFAULT_LIMIT = 5

const EmailContainer = styled('div')({
  display: 'flex',
  padding: '20px 32px',
  alignItems: 'center',
  borderRadius: '4px',
  backgroundColor: colors.white,
  height: '56px',
  boxShadow: '0px 2px 4px 0px rgba(188, 188, 188, 0.50)',
})

const initializePagination = (limit = DEFAULT_LIMIT) => ({
  offset: 0,
  limit,
  total: 0,
})

const style = {
  info: { maxWidth: '50%', margin: '0 auto 2em', textAlign: 'center' },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(55, 55, 54, 0.6)',
  },
  memberBox: { margin: 4 },
  memberList: { boxShadow: '0px 2px 4px rgba(188, 188, 188, 0.5)' },
}

const usePagination = (
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>,
  totalItems: number,
  pagination: Pagination,
) => {
  const handlePagination = useCallback(
    (params: Partial<Pagination>) => {
      setPagination((prev) => ({ ...prev, ...params }))
    },
    [setPagination],
  )

  useEffect(() => {
    if (totalItems !== pagination.total) {
      handlePagination({ total: totalItems })
    }
  }, [totalItems, pagination.total, handlePagination])

  return handlePagination
}

export const ConfirmEnrolmentChange: React.FC<Props> = ({
  openModal,
  eligibilityId,
  modalTitle,
  onOpenConfirmation,
  onOpen,
  onOpenSearch,
  onCancel,
  unEnrol = false,
}) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initializePagination())
  const { enqueueSnackbar } = useSnackbar()
  const { data: eligibilityRecord } = useGetEligibilityRecordQuery(eligibilityId.toString())
  const participantId = eligibilityRecord?.participant?.id
  const { data: familyData, isFetching } = useGetUserFamilyQuery(participantId, {
    skip: !participantId,
  })
  const [enrolUser, { isLoading: isEnrolLoading }] = useEnrolUserToEligibilityRecordMutation()
  const [unEnrolUser, { isLoading: isUnEnrolLoading }] = useUnEnrolUserToEligibilityRecordMutation()
  const { userId = '' } = useParams()

  const handleFamilyPagination = usePagination(
    setPagination,
    familyData?.data?.length || 1,
    pagination,
  )
  useEffect(() => {
    if (participantId) {
      const total = familyData?.data?.length || 0
      handleFamilyPagination({ total: total || 1 })
    }
  }, [participantId, familyData?.data?.length, handleFamilyPagination])

  const handleClose = () => {
    onOpenConfirmation(false)
    if (onOpen) onOpen(false)
  }

  const handleCancel = () => {
    onOpenConfirmation(false)
    if (onCancel) onCancel(true)
    if (onOpenSearch) onOpenSearch(true)
  }

  const handleEnrolment = async () => {
    try {
      await enrolUser({
        userId,
        body: { eligibility_record_id: eligibilityId.toString() },
      }).unwrap()
      enqueueSnackbar(t('userProfile.eligibilityRecord.actions.successfulEnrolment'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('userProfile.eligibilityRecord.actions.unsuccessfulEnrolment'), {
        variant: 'error',
      })
    }
    handleClose()
  }

  const handleUnEnrolment = async () => {
    try {
      await unEnrolUser({
        userId,
        body: { eligibility_record_id: eligibilityId.toString() },
      }).unwrap()
      enqueueSnackbar(t('userProfile.eligibilityRecord.actions.successfulUnEnrolment'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('userProfile.eligibilityRecord.actions.unsuccessfulUnEnrolment'), {
        variant: 'error',
      })
    }
    handleClose()
  }

  const cells = TABLE_HEADERS.map((i18nKey) => (
    <TableCell key={i18nKey} align="left" padding="normal">
      {t(i18nKey)}
    </TableCell>
  ))

  const rows = useMemo(() => {
    if (familyData?.data && familyData.data.length > 0) {
      return familyData.data
        .slice(pagination.offset, pagination.offset + pagination.limit)
        .map((user: FamilyMembersData) => (
          <TableRow key={user.id}>
            <TableCell>{user.attributes.firstName}</TableCell>
            <TableCell>{user.attributes.lastName}</TableCell>
            <TableCell>{user.id}</TableCell>
            <TableCell>{user.attributes.email}</TableCell>
            <TableCell>{t(userTypeTranslations[user.type as UserType])}</TableCell>
          </TableRow>
        ))
    } else if (participantId) {
      return [
        <TableRow key="participantDetails">
          <TableCell>{eligibilityRecord.participant.firstName}</TableCell>
          <TableCell>{eligibilityRecord.participant.lastName}</TableCell>
          <TableCell>{eligibilityRecord.participant.id}</TableCell>
          <TableCell>{eligibilityRecord.participant.email}</TableCell>
          <TableCell>{t(userTypeTranslations[FamilyMemberType.GUARDIAN as UserType])}</TableCell>
        </TableRow>,
      ]
    } else {
      return []
    }
  }, [familyData, participantId, pagination.offset, pagination.limit, eligibilityRecord, t])

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      PaperProps={{
        sx: { width: rows.length ? 889 : 760, maxWidth: 'none', backgroundColor: colors.snow },
      }}
    >
      <DialogTitle sx={{ padding: '32px', fontSize: '22px', lineHeight: '29px' }}>
        {modalTitle}
        <IconButton
          aria-label="close"
          color="primary"
          data-testid="close-button"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 32,
            p: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '32px' }}>
        {unEnrol ? (
          <Typography variant="subtitle2" sx={{ mb: 1.5, fontWeight: 400 }}>
            {t('userProfile.eligibilityRecord.confirmUnEnrolMessage')}
          </Typography>
        ) : (
          <Typography variant="subtitle2" sx={{ mb: 1.5, fontWeight: 400 }}>
            {t('userProfile.eligibilityRecord.confirmEnrolMessage')}
          </Typography>
        )}
        <EmailContainer>
          <Typography variant="subtitle2">{eligibilityId}</Typography>
        </EmailContainer>
        {rows.length > 0 && (
          <>
            <Typography sx={{ mt: 4 }} variant="body2" color="error">
              {t('userProfile.eligibilityRecord.removeUsersWarningMessage')}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <UserInfoTable
                cells={cells}
                rows={rows}
                hasEntries={rows.length > 0}
                pagination={pagination}
                setPagination={setPagination}
              />
            </Box>
          </>
        )}
        {isFetching && (
          <Grid container sx={style.loading} alignItems="center" justifyContent="space-around">
            <CircularProgress size={150} />
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ p: '0px 32px 32px' }}>
        <Button variant="outlined" data-testid="cancel-button" onClick={handleCancel}>
          {t('global.dialog.cancel')}
        </Button>
        {unEnrol ? (
          <LoadingButton
            color="error"
            variant="contained"
            type="submit"
            sx={{ ml: 2 }}
            onClick={handleUnEnrolment}
            data-testid="confirm-button"
            loading={isUnEnrolLoading}
          >
            {t('userProfile.eligibilityRecord.confirmUnEnrol')}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{ ml: 2 }}
            onClick={handleEnrolment}
            data-testid="confirm-button"
            loading={isEnrolLoading}
          >
            {t('userProfile.eligibilityRecord.confirmEnrol')}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
