import { memo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'

import { REGIONS, ScribePermission } from 'app/lib/constants'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { isRegion } from 'app/lib/utils/regions'
import { AccountingMethod, BrandIDEnum, LanguagePreference } from 'app/models/scribe.models'

import { Attributes } from './Attributes'
import { Billing } from './Billing'
import { Footer } from './Footer'
import { Heading } from './Heading'
import { Profile } from './Profile'
import {
  createOrganizationSchema,
  editFullOrganizationSchema,
  editRestrictedOrganizationSchema,
  OrganizationFormData,
} from './schemas'

const isUSRegion = isRegion(REGIONS.US)

// The default billing start date is the first of the month, 3 months from today
const defaultBillingStartDate = () => {
  const date = new Date() // get today
  date.setMonth(date.getMonth() + 3) // get three months from today

  return new Date(date.getFullYear(), date.getMonth(), 1)
}

const baseDefaultValues = {
  name: '',
  displayNameEnglish: '',
  displayNameFrench: '',
  memberIdType: '',
  streetAddress: '',
  suite: '',
  city: '',
  province: '',
  brandId: BrandIDEnum.Dia,
  postalCode: '',
  emailPreference: isUSRegion ? LanguagePreference.ENGLISH : '',
  clientNotes: '',
  careTeamNotes: '',
  forcedMfa: true,
  reportsEnabled: false,
  enrolmentCode: '',
  communicationsAllowed: false,
  accountingMethod: AccountingMethod.Automatic,
  billingMethod: '',
  billingStartDate: defaultBillingStartDate(),
  billingEmail: '',
}

interface OrganizationFormProps {
  onCancel: () => void
  onSubmit: (formData: any) => void
  validationSchema:
    | typeof createOrganizationSchema
    | typeof editFullOrganizationSchema
    | typeof editRestrictedOrganizationSchema
  initialValues?: Partial<OrganizationFormData> | null
  isLoading?: boolean
  isEdit?: boolean
  isReportingColumns?: boolean
  nonEditableFields?: string[]
}

export const OrganizationForm: React.FC<OrganizationFormProps> = memo(
  ({
    onCancel,
    onSubmit,
    validationSchema,
    initialValues = {},
    isEdit = false,
    isReportingColumns = false,
    isLoading = false,
    nonEditableFields = [],
  }) => {
    const userPermissions = useUserPermissions()
    const canUpdateBilling =
      !isEdit || userPermissions?.includes(ScribePermission.UPDATE_ORGANIZATION_BILLING)
    const defaultValues = validationSchema.cast({
      ...validationSchema.cast(baseDefaultValues),
      ...initialValues,
    }) as OrganizationFormData

    const formMethods = useForm<OrganizationFormData>({
      defaultValues,
      resolver: yupResolver(validationSchema),
    })

    const {
      handleSubmit,
      formState: { isDirty },
    } = formMethods

    return (
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={4}>
            <Heading
              isReportingColumns={isReportingColumns}
              isEdit={isEdit}
              isDirty={isDirty}
              onCancel={onCancel}
              isLoading={isLoading}
            />
            <Profile isEdit={isEdit} nonEditableFields={nonEditableFields} />
            <Attributes nonEditableFields={nonEditableFields} />
            {canUpdateBilling && <Billing isEdit={isEdit} nonEditableFields={nonEditableFields} />}
            <Footer isEdit={isEdit} isDirty={isDirty} onCancel={onCancel} isLoading={isLoading} />
          </Stack>
        </form>
      </FormProvider>
    )
  },
)
