import { ReactNode } from 'react'

import { Box, Grid, Typography } from '@mui/material'

interface FormRowProps {
  label: string
  sublabel?: string
  field: ReactNode
  justify?: string
  maxWidth?: boolean
}

export const FormRow: React.FC<FormRowProps> = ({
  label,
  sublabel,
  field,
  justify = 'center',
  maxWidth,
}) => (
  <Grid container spacing={2} pb={2.5}>
    <Grid item xs={12} md={maxWidth ? 0.75 : 4}>
      <Box display="flex" justifyContent={justify} flexDirection="column" height="100%">
        <Typography variant="subtitle2">{label}</Typography>
        {sublabel && <Typography variant="body3">{sublabel}</Typography>}
      </Box>
    </Grid>
    <Grid item xs={12} md={6}>
      {field}
    </Grid>
  </Grid>
)
