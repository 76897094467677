import { useCallback } from 'react'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import { useTranslation } from 'react-i18next'

import { Organization } from 'app/models/scribe.models'

export enum TabType {
  ACTIVE = 'active',
  FUTURE = 'future',
  DEACTIVATED = 'deactivated',
}

interface StatusTabsProps {
  tabValue: TabType
  onTabChange: (value: TabType) => void
  recordsCount: Organization['members']
  children?: React.ReactNode
}

export const StatusTabs: React.FC<StatusTabsProps> = ({
  children,
  onTabChange,
  recordsCount,
  tabValue,
}) => {
  const { t } = useTranslation()

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: TabType) => {
      onTabChange(newValue)
    },
    [onTabChange],
  )

  return (
    <TabContext value={tabValue}>
      <Box>
        <TabList data-testid="members-status-tabs" onChange={handleChange} aria-label="status-tabs">
          <Tab
            label={t(`eligibilityRecordListPage.eligibilityRecordsTable.statusTabs.active`, {
              count: recordsCount.active,
            })}
            value={TabType.ACTIVE}
            aria-label={TabType.ACTIVE}
          />
          {recordsCount.future !== 0 && (
            <Tab
              label={t(`eligibilityRecordListPage.eligibilityRecordsTable.statusTabs.future`, {
                count: recordsCount.future,
              })}
              value={TabType.FUTURE}
              aria-label={TabType.FUTURE}
            />
          )}
          <Tab
            label={t(`eligibilityRecordListPage.eligibilityRecordsTable.statusTabs.deactivated`, {
              count: recordsCount.inactive,
            })}
            value={TabType.DEACTIVATED}
            aria-label={TabType.DEACTIVATED}
          />
        </TabList>
      </Box>
      <TabPanel value={tabValue}>{children}</TabPanel>
    </TabContext>
  )
}
