import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Bear404 } from 'assets/images'

export function PageNotFound() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container sx={{ maxWidth: 'md', padding: 3 }}>
      <Stack spacing={3}>
        <Box display="flex" justifyContent="center">
          <Bear404 width="30%" title="Page Not Found" />
        </Box>
        <Box display="flex" justifyContent="center">
          <Stack spacing={3} display="flex" justifyContent="center">
            <Typography variant="h5" textAlign="center">
              {t('pageNotFound.sorryWeCouldntFindThatPage')}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t('pageNotFound.thePageYoureLookingfor')}
            </Typography>
            <Button variant="dark" sx={{ alignSelf: 'center' }} onClick={() => navigate('/')}>
              {t('pageNotFound.tryTheHomePage')}
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}
