import React, { useEffect, useCallback } from 'react'

import { TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getCurrencySymbol } from 'app/lib/utils/regions'
import { Pagination, UserChargesData } from 'app/models/scribe.models'

import { UserInfoTable } from './UserInfoTable'

export const DEFAULT_LIMIT = 5

const TABLE_HEADERS = [
  'userProfile.charges.amount',
  'userProfile.charges.description',
  'userProfile.charges.status',
] as const

type TableProps = Readonly<{
  chargesData: UserChargesData[]
  pagination: Pagination
  setPagination: (params: Pagination) => void
}>

export const Charges: React.FC<TableProps> = ({ chargesData, pagination, setPagination }) => {
  const { t } = useTranslation()
  const currencySymbol = getCurrencySymbol()

  const cells = TABLE_HEADERS.map((i18nKey) => (
    <TableCell key={i18nKey} align="left" padding="normal">
      {t(i18nKey)}
    </TableCell>
  ))

  const handleChargesPagination = useCallback(
    (params: Partial<Pagination>) => {
      setPagination({
        ...pagination,
        ...params,
      })
    },
    [pagination, setPagination],
  )

  useEffect(() => {
    if (chargesData.length !== pagination.total) {
      handleChargesPagination({ total: chargesData.length })
    }
  }, [chargesData.length, pagination.total, handleChargesPagination])

  const updateChargeStatusFormat = (status: string) => {
    return status.charAt(0).toUpperCase() + status.slice(1)
  }

  const paginatedData = chargesData.slice(pagination.offset, pagination.offset + pagination.limit)

  const rows = paginatedData.map((charges: any) => (
    <TableRow key={charges.id}>
      <TableCell>{currencySymbol + charges.attributes.amount}</TableCell>
      <TableCell>{charges.attributes.description}</TableCell>
      <TableCell>{updateChargeStatusFormat(charges.attributes.status)}</TableCell>
    </TableRow>
  ))

  return (
    <UserInfoTable
      cells={cells}
      rows={rows}
      pagination={pagination}
      setPagination={setPagination}
      hasEntries={chargesData.length > 0}
      tableTitle={t('userProfile.charges.chargesHeader')}
      emptyStateMessage={t('userProfile.charges.emptyStateMessage')}
    />
  )
}
