import DomainDisabledIcon from '@mui/icons-material/DomainDisabled'
import { Box, Button, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { STRETCH_FULL_HEIGHT } from 'app/lib/constants'

export const NoAccess = () => {
  const { t } = useTranslation()

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 4, px: 4, mt: 8, ...STRETCH_FULL_HEIGHT }}>
      <Paper
        sx={{
          mb: 3,
          mt: 3,
          ml: 'auto',
          mr: 'auto',
          maxWidth: '560px',
          p: 4,
          textAlign: 'center',
        }}
        elevation={1}
      >
        <DomainDisabledIcon fontSize="large" />
        <Typography variant="h1" sx={{ mb: 1.5 }}>
          {t('noAccess.title')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {t('noAccess.description')}
        </Typography>
        <Button variant="contained" href="mailto:clients@dialogue.co">
          {t('noAccess.button')}
        </Button>
      </Paper>
    </Box>
  )
}
