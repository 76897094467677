import { yupResolver } from '@hookform/resolvers/yup'
import { TabPanel } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { object } from 'yup'

import CheckboxFormField from 'app/lib/components/form/CheckboxFormField'
import InputFormField from 'app/lib/components/form/InputFormField'
import { userProfile } from 'app/lib/routes'
import { requiredEmailSchema } from 'app/lib/utils/yupSchemas'
import { FamilyMemberType } from 'app/models/scribe.models'
import { useAddAdultFamilyMemberMutation } from 'app/redux/scribeApi'

type Values = {
  email: string
  type?: boolean
}

const defaultValues: Values = {
  email: '',
  type: true,
}

const schema = object({
  email: requiredEmailSchema,
}).required()

export const NewAdultUserTab = () => {
  const { t } = useTranslation()
  const { userId } = useParams() as { userId: string }
  const navigate = useNavigate()
  const [updateAdultFamilyMember] = useAddAdultFamilyMemberMutation()

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const navigateToUserProfile = () => {
    navigate(userProfile.get(userId))
  }
  const onSubmit = (data: Values) => {
    const { email, type: checked } = data
    const type = checked ? FamilyMemberType.GUARDIAN : FamilyMemberType.DEPENDENT
    const body = {
      data: {
        type,
        attributes: {
          email,
          type,
        },
      },
    }
    if (email && userId) {
      updateAdultFamilyMember({ userId, body })
        .unwrap()
        .then(() => {
          navigateToUserProfile()
        })
    }
  }

  return (
    <TabPanel value="newUser" sx={{ p: 4, maxWidth: 700 }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <InputFormField
            label={t('form.labels.emailAddress')}
            name="email"
            type="email"
            sx={{ my: 4 }}
          />
          <CheckboxFormField name="type" label={t('form.labels.guardianMember')} />
          <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
            <Button variant="outlined" onClick={navigateToUserProfile}>
              {t('global.dialog.cancel')}
            </Button>
            <Button variant="contained" type="submit">
              {t('addMember.actionInviteToFamily')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </TabPanel>
  )
}
