import { useCallback } from 'react'

import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ChallengeLeaderboard } from 'app/models/ActiveMinutes'

import { exportToCsv } from './utils'

type ExportButtonProps = Readonly<{
  leaderboard: ChallengeLeaderboard['participants']
  challengeId: string
}>

export const ExportButton = ({ leaderboard, challengeId }: ExportButtonProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'eligibilityRecordListPage.exportButton' })

  const onSubmit = useCallback(
    () => exportToCsv(leaderboard, challengeId),
    [leaderboard, challengeId],
  )

  return (
    <Stack direction="row" alignItems="center" spacing={1} data-testid="export-csv-button">
      <Button variant="outlined" onClick={onSubmit}>
        {t('export')}
      </Button>
    </Stack>
  )
}
