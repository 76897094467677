import React, { ReactNode } from 'react'

import { Box, BoxProps, Grid, Typography } from '@mui/material'

export interface AttributeRowProps {
  label: ReactNode
  value: ReactNode
  labelProps?: BoxProps
  valueProps?: BoxProps
}

export const AttributeRow: React.FC<AttributeRowProps> = ({
  label,
  value,
  labelProps,
  valueProps,
}) => (
  <Grid container spacing={2} pb={2.5}>
    <Grid item xs={6} md={4}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
        {...labelProps}
      >
        <Typography variant="subtitle2">{label}</Typography>
      </Box>
    </Grid>
    <Grid item xs={6} md={8}>
      <Box display="flex" alignItems="center" height="100%" {...valueProps}>
        {typeof value === 'string' ? (
          <Typography variant="body2">{value ?? '-'}</Typography>
        ) : (
          value
        )}
      </Box>
    </Grid>
  </Grid>
)
