import { Administrator, CreditCard } from 'app/models/scribe.models'

import { V1Administrator, V1CreditCard } from '.'

export function fromV1Administrator(v1Admin: V1Administrator): Administrator {
  const attributes = v1Admin.attributes
  return {
    id: v1Admin.id,
    email: attributes.email,
    firstName: attributes.first_name,
    lastName: attributes.last_name,
    phoneNumber: attributes.phone_number,
  }
}

export function fromV1StripeRegistration(v1CreditCard: V1CreditCard): CreditCard {
  const attributes = v1CreditCard.attributes
  return {
    id: v1CreditCard.id,
    brand: attributes.brand,
    cardholderName: attributes.cardholder_name,
    expirationMonth: attributes.expiration_month,
    expirationYear: attributes.expiration_year,
    funding: attributes.funding,
    last4Digits: attributes.last_4_digits,
  }
}
