import { Box, Stack, Table, TableBody, TableCell, TableHead, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Page from 'app/layout/Page'
import { ClickableRow } from 'app/lib/components/ClickableRow'
import { Label, LabelType } from 'app/lib/components/Label'
import TableContent from 'app/lib/components/TableContent'
import { challengeLeaderboard } from 'app/lib/routes'
import { OrganizationRoute } from 'app/models/scribe.models'
import { useListChallengesQuery } from 'app/redux/activeMinutesApi'

export const OrganizationChallenges: React.FC = () => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation(undefined, { keyPrefix: 'organizationChallengesPage' })

  const language = resolvedLanguage === 'fr' ? 'fr' : 'en'

  const { organizationId } = useParams() as OrganizationRoute

  const { data: challenges = [], isFetching } = useListChallengesQuery({ organizationId })

  const rows = challenges.map(({ id, image, title, activity, startsAt, endsAt, status }) => {
    const startDate = format(new Date(startsAt), 'MMMM d')
    const endDate = format(new Date(endsAt), 'MMMM d, yyyy')
    return (
      <ClickableRow key={id} to={challengeLeaderboard.get(organizationId, id)}>
        <TableCell style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
          <img
            src={image[language]}
            alt={title[language]}
            style={{ width: '50px', height: '50px', marginRight: '10px' }}
          />
          {title.en}
        </TableCell>
        <TableCell>{t(`table.activity.${activity}`, { defaultValue: activity })}</TableCell>
        <TableCell>{t('table.type.individual')}</TableCell>
        <TableCell>{t('table.duration', { startDate, endDate })}</TableCell>
        <TableCell>
          <Label
            type={LabelType.DEFAULT}
            text={t(`table.status.${status}`, { defaultValue: status })}
            textProps={{ px: 2, py: 1, borderRadius: 10 }}
            boxProps={{ mt: 0 }}
          />
        </TableCell>
      </ClickableRow>
    )
  })

  return (
    <Page>
      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={4}>
        <Typography variant="h1" data-testid="page-title" display="flex" alignItems="top">
          {t('title')}
        </Typography>
      </Stack>
      <Box
        sx={{
          mb: 3,
          mt: 4,
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TableContent
          isLoading={isFetching}
          count={challenges.length}
          notFound={t('notFound')}
          searchNoMatch={t('notFound')}
          iconName="no_member_found"
        >
          <Table>
            <TableHead>
              <TableCell>{t('table.headers.title')}</TableCell>
              <TableCell>{t('table.headers.activity')}</TableCell>
              <TableCell>{t('table.headers.type')}</TableCell>
              <TableCell>{t('table.headers.duration')}</TableCell>
              <TableCell>{t('table.headers.status')}</TableCell>
            </TableHead>
            <TableBody data-testid="data-table">{rows}</TableBody>
          </Table>
        </TableContent>
      </Box>
    </Page>
  )
}
