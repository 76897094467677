import { useCallback } from 'react'

import { useNavigate, useParams } from 'react-router'

import Page from 'app/layout/Page'
import { organizationProfile } from 'app/lib/routes'
import { toISOdate, yesterday } from 'app/lib/utils/date'
import { PatchPlanBody } from 'app/models/Organization'
import { ChargeStrategy, Plan, PlanRoute } from 'app/models/scribe.models'
import {
  useGetCoBrandsQuery,
  useGetFeaturesQuery,
  useGetOrganizationPlanQuery,
  useRemoveOrganizationPlanMutation,
  useUpdateOrganizationPlanMutation,
  useUpdatePlanMutation,
} from 'app/redux/scribeApi'

import { PlanForm, PlanFormSchema } from './PlanForm'

export const EditPlanPage: React.FC = () => {
  const { organizationId, planId } = useParams() as PlanRoute
  const navigate = useNavigate()
  const { data: coBrandsResponse } = useGetCoBrandsQuery({})
  const { data: plan } = useGetOrganizationPlanQuery({ organizationId, planId })
  const [updateOrganisationPlan, { isLoading: isUpdatingOrganisationPlan }] =
    useUpdateOrganizationPlanMutation()
  const [removeOrganizationPlan, { isLoading: isDeleting }] = useRemoveOrganizationPlanMutation()
  const [updatePlan, { isLoading: isUpdatingPlan }] = useUpdatePlanMutation()
  const { data: features } = useGetFeaturesQuery({})

  const navigateToOrganizationProfile = useCallback(() => {
    navigate(organizationProfile.get(organizationId))
  }, [navigate, organizationId])

  const onUpdate = useCallback(
    (params: PlanFormSchema) => {
      const editedPlan: PatchPlanBody = {
        type: 'plan',
        attributes: {
          nameEn: params.name,
          nameFr: params.name,
          label: params.label,
          chargePrice: params.cost,
          chargeStrategy: params.model as ChargeStrategy,
          isDefault: params.default,
          features: params.services,
        },
      }

      if (params.cobrand) {
        editedPlan.relationships = {
          cobrand: { data: { id: params.cobrand, type: 'cobrand' } },
        }
      }

      return updateOrganisationPlan({ organizationId, planId, body: { data: editedPlan } })
        .unwrap()
        .then(navigateToOrganizationProfile)
    },
    [organizationId, planId, updateOrganisationPlan, navigateToOrganizationProfile],
  )

  const onRemove = useCallback(() => {
    return removeOrganizationPlan(planId).unwrap().then(navigateToOrganizationProfile)
  }, [planId, removeOrganizationPlan, navigateToOrganizationProfile])

  const onArchive = useCallback(() => {
    // Update plan to not accept any eligible interval that is active today or later
    const body = { startDate: '-inf', endDate: toISOdate(yesterday()) }
    return updatePlan({ planId, body }).unwrap().then(navigateToOrganizationProfile)
  }, [planId, updatePlan, navigateToOrganizationProfile])

  const getPlanInitialValues = useCallback((plan_: Plan): PlanFormSchema => {
    const attributes = plan_.attributes
    const relationships = plan_.relationships
    return {
      name: attributes.name,
      label: attributes.label,
      cost: attributes.chargePrice,
      model: attributes.chargeStrategy,
      default: attributes.isDefault,
      services: attributes.features.map((x) => x.label),
      cobrand: relationships?.cobrand?.data?.id,
    }
  }, [])

  return (
    <Page isLoading={isUpdatingOrganisationPlan || isDeleting || isUpdatingPlan}>
      {plan && features && (
        <PlanForm
          onCancel={() => navigate(organizationProfile.get(organizationId))}
          onSubmit={(params) => onUpdate(params)}
          onRemove={onRemove}
          onArchive={onArchive}
          availableFeatures={features}
          initialValues={getPlanInitialValues(plan)}
          coBrandResponse={coBrandsResponse}
        />
      )}
    </Page>
  )
}
