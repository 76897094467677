import { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Button, ButtonProps, Paper, Stack, Typography } from '@mui/material'
import { trackPageView } from '@snowplow/browser-tracker'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Page from 'app/layout/Page'
import { NoAccess } from 'app/lib/components/NoAccess'
import { NoResults } from 'app/lib/components/NoResults'
import { ScribePermission } from 'app/lib/constants'
import withProtectedComponent from 'app/lib/hoc/withProtectedComponent'
import { organizationCreate } from 'app/lib/routes'
import { ListOrganizationsParams } from 'app/models/Organization'
import { Pagination } from 'app/models/scribe.models'
import { DEFAULT_LIMIT, useListOrganizationsQuery } from 'app/redux/scribeApi'
import { NoSearchResults } from 'assets/images'

import { OrganizationsTable } from './Table'
import { OrganizationsToolbar } from './Toolbar'

const ProtectedButton = withProtectedComponent<ButtonProps>(Button)

export const OrganizationListPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [listParams, setListParams] = useState<ListOrganizationsParams>({})

  const { data: organizationsResp, isFetching } = useListOrganizationsQuery(listParams)
  const { data: organizations = [], meta = { offset: 0, totalItems: 0 } } = organizationsResp || {}
  const pagination: Pagination = {
    offset: meta.offset,
    limit: DEFAULT_LIMIT,
    total: meta.totalItems,
  }

  const orgLengthIsZero = useMemo(() => organizations?.length === 0, [organizations])
  const searchParamsExist = useMemo(() => Object.keys(listParams).length > 0, [listParams])
  const hasNoOrganization = useMemo(
    () => !isFetching && orgLengthIsZero && !searchParamsExist,
    [isFetching, orgLengthIsZero, searchParamsExist],
  )
  const searchReturnedNoOrgs = orgLengthIsZero && searchParamsExist
  const hasOrganizations = !orgLengthIsZero

  useEffect(() => {
    trackPageView({ title: 'organization-list' })
  }, [])

  const handleSearch = useCallback((search: string) => {
    setListParams({ search })
  }, [])

  return (
    <Page isLoading={isFetching}>
      {hasNoOrganization ? (
        <NoAccess />
      ) : (
        <>
          <Stack direction="row" alignItems="center" flexWrap="wrap" gap={4}>
            <Typography variant="h1" data-testid="organization-list-title">
              {t('organizationListPage.pageTitle')}
            </Typography>
            <Box sx={{ minWidth: '500px' }}>
              <OrganizationsToolbar onSearch={handleSearch} />
            </Box>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
              <ProtectedButton
                permission={ScribePermission.CREATE_ORGANIZATION}
                variant="contained"
                onClick={() => navigate(organizationCreate.get())}
              >
                {t('organizationListPage.createOrganization')}
              </ProtectedButton>
            </Box>
          </Stack>

          {searchReturnedNoOrgs && (
            <Paper data-testid="no-results" sx={{ mb: 3, mt: 3 }} elevation={1}>
              <NoResults
                title={t('organizationListPage.noSearchResults')}
                img={<NoSearchResults title="no_results" />}
              />
            </Paper>
          )}

          {hasOrganizations && (
            <Paper sx={{ mb: 3, mt: 4 }} elevation={1}>
              <OrganizationsTable
                organizations={organizations}
                pagination={pagination}
                onPageChange={(page) =>
                  setListParams({ ...listParams, offset: DEFAULT_LIMIT * page })
                }
              />
            </Paper>
          )}
        </>
      )}
    </Page>
  )
}
