import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

type StatusBoxProps = Readonly<{
  status: 'active' | 'inactive'
}>

export function StatusBox(props: StatusBoxProps) {
  const { status } = props
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        borderRadius: 10,
        textAlign: 'center',
        bgcolor: status === 'active' ? 'accent.main' : 'info.main',
        padding: '8px',
      }}
    >
      {t(`global.eligibilityRecordStatus.${status}`)}
    </Box>
  )
}
