import CloseIcon from '@mui/icons-material/Close'
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CollapsibleResults } from 'app/lib/components/CollapsibleResults'
import { ProcessingResults } from 'app/lib/utils/process-directives'

interface SummaryProps {
  processingResults: ProcessingResults
  onClose: () => void
}

export const Summary: React.FC<SummaryProps> = ({ processingResults, onClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <DialogTitle>
        {t('csvImport.summary.title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CollapsibleResults processingResults={processingResults} />
        <DialogActions>
          <Button
            variant="contained"
            data-testid="upload-eligibility-record-done"
            onClick={onClose}
          >
            {t('global.dialog.done')}
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  )
}
