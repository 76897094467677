import { toISOdatetime, today } from 'app/lib/utils/date'
import { saveCsv } from 'app/lib/utils/export-records-to-csv'
import { ChallengeLeaderboard, LeaderboardParticipants } from 'app/models/ActiveMinutes'

export function buildHeader<T extends object>(records: T[]): (keyof T)[] {
  const attributeNames = new Set<keyof T>()
  for (const item of records) {
    for (const attribute of Object.keys(item)) {
      if (attribute === 'userId') continue // exclude because user_id is PII
      attributeNames.add(attribute as keyof T)
    }
  }
  return Array.from(attributeNames)
}

export function convertObjectToCsvContent(objects: LeaderboardParticipants[]) {
  const sanitizeValue = (value: string | number = ''): string | number =>
    typeof value === 'string' && value.includes(',') ? `"${value}"` : value

  const header = buildHeader<LeaderboardParticipants>(objects)
  const csv = [
    header.join(','), // header row first
    ...objects.map((object) =>
      header.map((columnName) => sanitizeValue(object[columnName])).join(','),
    ),
  ].join('\r\n')
  return csv
}

export function exportToCsv(
  leaderboard: ChallengeLeaderboard['participants'],
  challengeId: string,
) {
  const datetime = toISOdatetime(today())
  const filename = `export-${challengeId}-${datetime}.csv`
  const content = convertObjectToCsvContent(leaderboard)
  saveCsv(content, filename)
}
