import { ReactNode } from 'react'

import { TableContainer } from '@mui/material'

import { NoSearchResults } from 'assets/images'

import { LoadingBackdrop } from './LoadingBackdrop'
import { NoResults } from './NoResults'

interface TableContentProps {
  children: ReactNode
  isLoading: boolean
  count: number
  notFound: string
  searchNoMatch: string
  iconName: string
}

const TableContent = ({
  children,
  isLoading,
  count,
  notFound,
  searchNoMatch,
  iconName,
}: TableContentProps) => {
  const showTable = count || isLoading
  return (
    <TableContainer sx={{ alignSelf: showTable ? 'baseline' : 'initial' }}>
      {isLoading && <LoadingBackdrop loading={isLoading} />}
      {showTable ? (
        children
      ) : (
        <NoResults
          title={notFound}
          subtitle={searchNoMatch}
          img={<NoSearchResults title={iconName} />}
        />
      )}
    </TableContainer>
  )
}

export default TableContent
