import { Button, CardContent, Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import enTranslations from 'app/i18n/locales/en.json'
import DatePickerFormField from 'app/lib/components/form/DatePickerFormField'
import { FormRow } from 'app/lib/components/form/FormRow'
import InputFormField from 'app/lib/components/form/InputFormField'
import SelectFormField from 'app/lib/components/form/SelectFormField'
import { PlanCard } from 'app/lib/components/plans/PlanCard'
import { AdminAreaTrKeys } from 'app/lib/constants'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { getEarliestActivationDate, today } from 'app/lib/utils/date'
import { getProvinceName } from 'app/lib/utils/helpers'
import { MemberIdType, Organization, Plan } from 'app/models/scribe.models'
import { FormViewTypes } from 'app/pages/organization-add-member-page/index'
import { useGetAdminAreasQuery } from 'app/redux/scribeApi'
import { getApplicationConfig } from 'config'

interface MemberInfoProps {
  organization?: Organization
  plans: Plan[]
  onChangeView: (view: string) => void
}

type MemberFieldType = keyof typeof enTranslations.addEditMemberDialog.fields

const { region } = getApplicationConfig()
const adminAreaKey = AdminAreaTrKeys[region]

export const MemberInfo: React.FC<MemberInfoProps> = ({ organization, plans, onChangeView }) => {
  const { watch } = useFormContext()
  const { t } = useTranslation()
  const tf = (field: MemberFieldType): string => t(`addEditMemberDialog.fields.${field}.label`)
  const userPermissions = useUserPermissions()

  const { data: availableAreas = [] } = useGetAdminAreasQuery(region)

  const selectedPlanLabel = watch('plan')
  const selectedPlan = plans.find((plan) => plan.attributes.label === selectedPlanLabel)

  const organizationType = organization?.memberIdType
  const communicationsAllowed = organization?.communicationsAllowed
  const organizationBillingStartDate = organization?.billingStartDate || null

  const hasMultiplePlans = plans.length > 1

  const isEmployeeIdOrg = organizationType === MemberIdType.EMPLOYEE_ID
  const activationMinDate = getEarliestActivationDate(organizationBillingStartDate, userPermissions)

  return (
    <>
      <CardContent>
        <FormRow
          label={tf('uniqueIdentifier')}
          field={
            <InputFormField
              variant="outlined"
              name="uniqueIdentifier"
              helperText={t('addEditMemberDialog.fields.uniqueIdentifier.helperText')}
            />
          }
        />
        <FormRow
          label={tf('firstName')}
          field={<InputFormField variant="outlined" name="firstName" />}
        />
        <FormRow
          label={tf('lastName')}
          field={<InputFormField variant="outlined" name="lastName" />}
        />
        <FormRow
          label={tf('dateOfBirth')}
          field={
            <DatePickerFormField
              variant="outlined"
              name="dateOfBirth"
              size="small"
              maxDate={today()}
            />
          }
        />
        <FormRow
          label={tf(adminAreaKey)}
          field={
            <SelectFormField
              name="province"
              variant="outlined"
              options={availableAreas.map(({ isoCode, name }) => ({
                label: getProvinceName(isoCode, name, t),
                value: isoCode,
              }))}
            />
          }
        />
        {isEmployeeIdOrg && (
          <FormRow
            label={tf('communicationEmail')}
            field={<InputFormField hideLabel variant="outlined" name="communicationEmail" />}
          />
        )}
        <FormRow
          label={tf('activationDate')}
          field={
            <DatePickerFormField
              variant="outlined"
              name="activationDate"
              minDate={activationMinDate}
              openTo="day"
              size="small"
              helperText={t(
                communicationsAllowed
                  ? 'addEditMemberDialog.communication.allowed'
                  : 'addEditMemberDialog.communication.notAllowed',
              )}
            />
          }
        />
      </CardContent>
      {!!selectedPlan && (
        <>
          <Typography variant="h2">{t('addEditMemberDialog.planSection.title')}</Typography>
          <Grid container>
            <Grid item xs={12} md={6} xxl={4}>
              <PlanCard
                plan={selectedPlan}
                {...(hasMultiplePlans && {
                  actionButton: (
                    <Button variant="outlined" onClick={() => onChangeView(FormViewTypes.PlanInfo)}>
                      {t('eligibilityRecordPage.buttons.changePlan')}
                    </Button>
                  ),
                })}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
