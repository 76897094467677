import { TextField, TextFieldProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type InputFormFieldProps = TextFieldProps & {
  name: string
  hideLabel?: boolean
}

const InputFormField = ({ name, helperText, hideLabel = false, ...props }: InputFormFieldProps) => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TextField
          fullWidth
          size="small"
          {...field}
          {...props}
          label={hideLabel ? '' : props.label}
          inputProps={{
            'data-testid': `inputfield-${name}`,
            ...props.inputProps,
            ...(hideLabel && { 'aria-label': props.label?.toString() }),
          }}
          error={!!error?.message}
          helperText={
            error?.message
              ? (t(error?.message, { label: props.label, defaultValue: '' }) as React.ReactNode)
              : helperText
          }
        />
      )}
    />
  )
}

export default InputFormField
