import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
  SxProps,
  Theme,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

type Props = RadioGroupProps & {
  name: string
  options: {
    value: any
    label: string
  }[]
  optionProps?: SxProps<Theme>
  type: 'string' | 'boolean'
  helperText?: string
}

const RadioGroupFormField = ({
  name,
  options,
  helperText,
  type = 'string',
  optionProps,
  ...props
}: Props) => {
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <RadioGroup
            {...field}
            {...props}
            onChange={(e) =>
              field.onChange(type === 'boolean' ? e.target.value === 'true' : e.target.value)
            }
          >
            {options.map(({ label, value }) => (
              <FormControlLabel
                label={label}
                value={value}
                control={<Radio />}
                sx={optionProps}
                key={label}
              />
            ))}
          </RadioGroup>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </>
      )}
    />
  )
}

export default RadioGroupFormField
