import { FC } from 'react'

import { Typography } from '@mui/material'
import { useController } from 'react-hook-form'

interface WithProtectedComponentProps {
  isViewMode: boolean
  name: string
  displayName?: string
}

function withInputFieldViewMode<P>(
  WrappedComponent: FC<Omit<WithProtectedComponentProps & P, 'isViewMode' | 'displayName'>>,
) {
  return ({ isViewMode, displayName, ...rest }: WithProtectedComponentProps & P) => {
    const {
      field: { value },
    } = useController({ name: rest.name })
    if (isViewMode)
      return (
        <Typography variant="body2" data-testid={`input-value-${rest.name}`}>
          {displayName || value}
        </Typography>
      )
    return <WrappedComponent {...rest} />
  }
}
export default withInputFieldViewMode
