import { useCallback, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import { deleteAdministrator } from 'app/lib/legacy-api/scribe.api'
import { organizationAddAdmin } from 'app/lib/routes'
import { Administrator, OrganizationRoute } from 'app/models/scribe.models'
import { useGetOrganizationQuery } from 'app/redux/scribeApi'

import { colors } from '../../../theme'

import { RemoveDialog } from './RemoveDialog'

export const OrganizationAdmins: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const { organizationId } = useParams() as OrganizationRoute
  const [selectedAdmin, setSelectedAdmin] = useState<Administrator | null>(null)

  const { data: organization, refetch } = useGetOrganizationQuery(organizationId)
  const { administrators } = organization || {}

  const navigateToAddAdminPage = useCallback(() => {
    navigate(organizationAddAdmin.get(organizationId))
  }, [navigate, organizationId])

  const onRemoveAdmin = useCallback(() => {
    return getAccessTokenSilently()
      .then((token) => deleteAdministrator(token, organizationId, selectedAdmin?.id as string))
      .then(() => {
        setSelectedAdmin(null)
        refetch()
      })
  }, [organizationId, selectedAdmin, getAccessTokenSilently, refetch])

  const rows = (administrators || []).map((administrator: Administrator) => {
    return (
      <TableRow key={administrator.id} style={{ backgroundColor: colors.white }}>
        <TableCell sx={{ width: '30%' }}>
          {administrator.firstName} {administrator.lastName}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'pre' }}>{administrator.email}</TableCell>
        <TableCell>
          <Button
            data-testid={'remove-admin-' + administrator.email}
            variant="text"
            onClick={() => setSelectedAdmin(administrator)}
          >
            {t('global.dialog.remove')}
          </Button>
        </TableCell>
      </TableRow>
    )
  })

  return (
    <>
      <RemoveDialog
        email={selectedAdmin ? selectedAdmin.email : ''}
        open={!!selectedAdmin}
        removeAdmin={onRemoveAdmin}
        onClose={() => setSelectedAdmin(null)}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: 3, p: 2 }}
      >
        <Typography variant="h2">{t('organizationProfileAdmins.title')}</Typography>
        <Button sx={{ alignSelf: 'end' }} variant="contained" onClick={navigateToAddAdminPage}>
          {t('organizationProfileAdmins.buttons.add')}
        </Button>
      </Stack>
      <Table style={{ boxShadow: '0px 2px 4px #F1F1F1' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('organizationProfileAdmins.name')}</TableCell>
            <TableCell colSpan={2}>{t('organizationProfileAdmins.email')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </>
  )
}
