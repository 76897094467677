import { User } from 'app/models/User'

export type Pagination = Readonly<{
  offset: number
  limit: number
  total: number
}>

export type PaginationV2 = Readonly<{
  offset: number
  total: number
}>

export interface RequestBody<T> {
  body: T
}

export enum Language {
  EN = 'en',
  FR = 'fr',
}

type LocalizedString = {
  [key in Language]: string
}

export enum OrganizationReportingColumns {
  REPORTING_COLUMN_ONE = 'reportingColumnOne',
  REPORTING_COLUMN_TWO = 'reportingColumnTwo',
  REPORTING_COLUMN_THREE = 'reportingColumnThree',
  REPORTING_COLUMN_FOUR = 'reportingColumnFour',
  REPORTING_COLUMN_FIVE = 'reportingColumnFive',
  REPORTING_COLUMN_SIX = 'reportingColumnSix',
  REPORTING_COLUMN_SEVEN = 'reportingColumnSeven',
}

export type OrganizationMeta = Record<string, string>

export enum BillingMethod {
  CHEQUE = 'cheque',
  PRE_AUTHORIZED_DEBIT = 'pre_authorized_debit',
  ELECTRONIC_FUNDS_TRANSFER = 'electronic_funds_transfer',
}

export enum AccountingMethod {
  Automatic = 'automatic',
  Manual = 'manual',
}
export enum BrandIDEnum {
  Dia = 'dia',
}

export enum CreditCardEnum {
  CREDIT_CARD = 'credit_card',
}

export type BillingMethodDeprecated = CreditCardEnum | BillingMethod

export enum LanguagePreference {
  ENGLISH = 'english',
  FRENCH = 'french',
  BILINGUAL_ENGLISH_FRENCH = 'bilingual-english-french',
  BILINGUAL_FRENCH_ENGLISH = 'bilingual-french-english',
}

export enum MemberIdType {
  EMAIL = 'email',
  EMPLOYEE_ID = 'employee_id',
}

export enum FamilyMemberType {
  ADULT = 'adult',
  CHILD = 'child',
  GUARDIAN = 'guardian',
  DEPENDENT = 'dependent',
}

export type Organization = Readonly<{
  id: string
  billingEmail: string | null
  billingMethod: BillingMethodDeprecated
  chargebeeId: string | null
  billingStartDate: string | null
  communicationsAllowed: boolean
  invitationEmailTime?: string
  created: string
  emailPreference: LanguagePreference
  displayName: LocalizedString
  memberIdType: MemberIdType
  name: string
  website: string | null
  reportsEnabled: boolean
  members: {
    active: number
    future: number
    inactive: number
  }
  attributeSchema?: {
    reportingColumnOne: string
    reportingColumnTwo: string
    reportingColumnThree: string
    reportingColumnFour: string
    reportingColumnFive: string
    reportingColumnSix: string
    reportingColumnSeven: string
    signupIdentifier: string
  }
  brand: Brand
  defaultCreditCard?: CreditCard
  defaultPlan?: Plan
  addresses: Address[]
  clientNotes?: string
  careTeamNotes?: string
  forcedMfa?: boolean
  accountingMethod?: string
  meta?: {
    organizationEnrolmentCode?: string
  } | null
}>

export type OrganizationV2 = Readonly<{
  id: number
  created_at: string
  name: string
  email_preference: string
  billing_email?: string
  accounting_method?: AccountingMethod
  billing_start_date?: string
  billing_method?: BillingMethodDeprecated
  tax_province?: string
  hellosign_contract_url?: string
  member_id_type: MemberIdType
  client_notes?: string
  care_team_notes?: string
  communications_allowed: boolean
  website?: string
  forced_mfa: boolean
  reports_enabled: boolean
  meta: {
    [key: string]: string | null
  }
  attribute_schema: {
    [key: string]: string | null
  }
  display_name?: {
    en: string
    fr: string
  }
  content_tags: {
    names: string[]
  }
  address?: {
    street_address?: string
    suite?: string
    city?: string
    admin_area_iso_code: string
    postal_code?: string
  }
  administrators: Administrator[]
}>

export type OrganizationEdit = Readonly<{
  billingEmail: string | null
  communicationsAllowed: boolean
  invitationEmailTime?: string
  emailPreference: LanguagePreference
  displayNameEnglish: string
  displayNameFrench: string
}>

export type Address = Readonly<{
  id: string
  streetAddress: string | null
  suite: string | null
  city: string | null
  adminAreaIsoCode: string | null
  postalCode: string | null
}>

export type Brand = {
  data: {
    id: string
    type: string
  }
}

export type Administrator = Readonly<{
  id?: string
  email: string
  firstName: string
  lastName: string
  phoneNumber?: string
}>

export type CreditCard = Readonly<{
  id: string
  brand: string
  cardholderName: string | null
  expirationMonth: number
  expirationYear: number
  funding: string
  last4Digits: string | null
}>

export enum InvoiceStatus {
  PAID = 'paid',
  POSTED = 'posted',
  PAYMENT_DUE = 'payment_due',
  NOT_PAID = 'not_paid',
  VOIDED = 'voided',
  PENDING = 'pending',
}

export type OrganizationInvoice = {
  id: string
  total_amount: number
  status: InvoiceStatus
  issue_date: string
  download_link?: string
  usage_range: UsageRange
}

export type UsageRange = {
  start_date: string
  end_date: string
}

export enum ChargeStrategy {
  DYNAMIC = 'dynamic',
  FIXED = 'fixed',
  PREPAID_PRORATION = 'prepaid_proration',
  PREPAID_MONTHLY = 'prepaid_monthly',
  USAGE_BASED = 'usage_based',
}

export enum DateOfBirthType {
  PARTIAL_DATE_OF_BIRTH = 'partial_date_of_birth',
  FULL_DATE_OF_BIRTH = 'full_date_of_birth',
}

export type Plan = Readonly<{
  id: string
  attributes: {
    name: string
    nameEn: string
    nameFr: string
    label: string
    chargePrice: number
    chargeStrategy: ChargeStrategy
    during: {
      lower: string
      upper: string
    }
    features: Feature[]
    isDefault: boolean
  }
  relationships: {
    cobrand: {
      data: { id: string; type: string }
    }
  }
}>

export type Options = { [key: string]: string }
export type Services = { [key: string]: Options }
export type ServicesByBrand = { [key: string]: Services }

export type PlanWithServices = Readonly<{
  id: number
  name: LocalizedString
  description: LocalizedString
  label: string
  chargePrice: number
  chargeStrategy: ChargeStrategy
  organization: {
    id: number
    brandId: string
    name: string
  }
  startDate: string
  endDate: string
  services: ServicesByBrand
  isDefault: boolean
}>

export type Feature = Readonly<{
  label: string
  localizedName: {
    [key: string]: string
  }
}>

export enum ServiceOptionLabels {
  WORKPLACE_REFERRAL = 'workplaceReferrals',
}

export enum ServiceLabels {
  WELLNESS_CHALLENGES = 'wellnessChallenges',
}

export type EligibilityDirectiveError = Readonly<{
  lineNumber: number
  uniqueIdentifier: string
  field: string
  message: string
}>

export enum EligibilityRecordStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum SignupIdentifier {
  PC = 'pc',
}

export enum EligibilityDirectiveOperation {
  CREATED = 'created',
  UPDATED = 'updated',
  NOT_CREATED = 'not_created',
  DELETED = 'deleted',
}

export type EligibilityRecordAttributes = Readonly<{
  uniqueIdentifier: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  province?: string
  communicationEmail?: string
  [extraKey: string]: string | undefined
}>

export type EligibleInterval = Readonly<{
  planId: number
  startDate: string
  endDate: string
  isActive: boolean
}>

export type EligibilityRecord = Readonly<{
  id: number
  attributes: EligibilityRecordAttributes
  status: EligibilityRecordStatus
  organizationId: number
  organization: {
    id: number
    name: string
    brandId: string
  }
  eligibleIntervals: Array<EligibleInterval>
  participant?: User
}>

export type EligiblePeriod = Readonly<{
  eligibilityRecord: {
    id: number
    attributes: EligibilityRecordAttributes
  }
  eligibleDays: number
  startDate: string
  endDate: string
  planId: string
}>

export type OptionalOrganizationRoute = {
  organizationId?: string
}

export type OrganizationRoute = {
  organizationId: string
}

export type ChallengeRoute = OrganizationRoute & {
  challengeId: string
}

export type EligibilityRecordRoute = OrganizationRoute & {
  eligibilityRecordId: string
}

export type PlanRoute = OrganizationRoute & {
  planId: string
}

export type LocalizedUrl = Readonly<Record<Language, string>>
export type LocalizedUrlsByProgram = {
  execSummary: LocalizedUrl
  primaryCare?: LocalizedUrl
  mentalHealth?: LocalizedUrl
  employeeAssistanceProgram?: LocalizedUrl
  wellness?: LocalizedUrl
}

export type EngagementReportUrls = Readonly<{
  ihpReportsUrls: LocalizedUrlsByProgram | null
}>

export type AdminArea = Readonly<{
  isoCode: string
  name: string
}>

export type ContentTag = {
  id: number
  name: string
  tenant_id: number
  created: string
}

export type Cobrand = {
  id: string
  type: string
  attributes: {
    name: string
  }
  relationships: Record<string, string>
  links: Record<string, string>
}

export type Brands = {
  id: string
  name: string
  branch_io_key: string
}

export type CobrandsResponse = {
  data: Cobrand[]
  links: {
    self: {
      href: string
    }
  }
  relationships: Record<string, string>
}

export type UserChargeAttributes = {
  amount: number
  description: string
  status: string
}

export type UserChargesData = {
  id: string
  type: string
  attributes: UserChargeAttributes
}

export type UserCharges = {
  data: UserChargesData[]
}

export type UserEpisodesData = {
  id: string
  updatedAt: string
  state: string
}

export type UserEpisodes = {
  data: UserEpisodesData[]
  meta: {
    offset: number
    totalItems: number
    totalPages: number
    pageNumber: number
    activeEpisodesCount: number
  }
}

export type UserEligibilityRecordData = {
  id: number
  attributes: {
    uniqueIdentifier: string
    organizationEnrolmentCode?: string
  }
  status: string
  organization: {
    id: number
    name: string
  }
  participant: {
    id: number
    firstName: string
    lastName: string
  }
}

export type UserAccountDetails = {
  id: string
  firstName: string
  lastName: string
  email: string
  authId: string
  dateOfBirth: string
}

export type UserEligibilityRecord = {
  data: UserEligibilityRecordData[]
  meta: {
    offset: number
    totalItems: number
  }
}
export type FamilyMembersData = {
  id: string
  type: string
  attributes: {
    email: string
    firstName: string
    lastName: string
  }
}

export type FamilyMembers = {
  data: FamilyMembersData[]
}

export type ResendInvitationRequestBody = {
  email: string
  brandId: string
  eligibilityId?: number
}

export interface ResendInvitationRequestParams extends RequestBody<ResendInvitationRequestBody> {}
