import { useCallback } from 'react'

import { Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { BasicDialog } from 'app/lib/components/dialogs'
import { Organization } from 'app/models/scribe.models'
import { useResendWelcomeEmailMutation } from 'app/redux/scribeApi'

interface Props {
  open: boolean
  onClose: () => void
  organization: Organization
}

export const ResendEmailDialog = ({ organization, open, onClose }: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { name, members, id } = organization

  const [resendEmail, { isLoading }] = useResendWelcomeEmailMutation()

  const handleConfirm = useCallback(async () => {
    await resendEmail(id)
      .unwrap()
      .then(() =>
        enqueueSnackbar(t('resendWelcomeEmail.successfulQueryAllMembers'), { variant: 'success' }),
      )
      .catch(() => enqueueSnackbar(t('resendWelcomeEmail.errorQuery'), { variant: 'error' }))

    onClose()
  }, [id, enqueueSnackbar, onClose, resendEmail, t])

  return (
    <BasicDialog
      open={open}
      title={t('resendWelcomeEmail.dialog.title')}
      confirmText={t('resendWelcomeEmail.dialog.submit')}
      onClose={onClose}
      onConfirm={handleConfirm}
      loading={isLoading}
    >
      <Typography variant="body2" mb={1}>
        {t('resendWelcomeEmail.dialog.confirmationAllMembers', {
          orgName: name,
          count: members.active,
        })}
      </Typography>
    </BasicDialog>
  )
}
