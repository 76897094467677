import { Typography, Box, Stack } from '@mui/material'

type NoResultsProps = Readonly<{
  title: string
  img: any
  subtitle?: string
  actionElement?: JSX.Element
}>

export const NoResults: React.FC<NoResultsProps> = ({ title, img, subtitle, actionElement }) => {
  return (
    <Stack alignItems="center" padding={5} gap="28px">
      <Stack alignItems="center">
        {img}
        <Typography variant="h2" align="center" data-testid="no-result-title">
          {title}
        </Typography>
      </Stack>
      <Typography variant="body1" align="center">
        {subtitle}
      </Typography>
      <Box>{actionElement}</Box>
    </Stack>
  )
}
