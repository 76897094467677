import { useEffect } from 'react'

import { Box, Button, Divider, Grid, Link, List, ListItem, Stack, Typography } from '@mui/material'
import { trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { useTranslation } from 'react-i18next'

import Page from 'app/layout/Page'
import { getSnowplowSchema } from 'app/lib/utils/helpers'
import { Language } from 'app/models/scribe.models'
import { colors } from 'app/theme'

import { RequestForm } from './RequestForm'

const languages = Object.values(Language)

const requestForms = {
  [Language.EN]: 'EAP REQUEST - Workplace Referral - EN',
  [Language.FR]: 'DEMANDE PAE - Interventions dirigees - FR',
}

const formStepIndex = 0

const Step: React.FC<{ label: string; children: React.ReactNode }> = ({ label, children }) => (
  <Stack direction="row">
    <Typography variant="subtitle2" minWidth="70px">
      {label}
    </Typography>
    {children}
  </Stack>
)

export const OrganizationEAPRequestPage: React.FC = () => {
  const { t } = useTranslation()

  useEffect(() => {
    trackPageView({ title: 'eap-requests' })
  }, [])

  const stepTr = t('organizationEapRequests.stepsSection.step')
  const stepsSectionItems = t('organizationEapRequests.stepsSection.steps', { returnObjects: true })

  const expectSectionItems = t('organizationEapRequests.expectSection.descriptions', {
    returnObjects: true,
  })

  const trackDownloadAnalytics = (lang: Language) => {
    trackSelfDescribingEvent({
      event: {
        schema: getSnowplowSchema('button_click', '1-0-0'),
        data: {
          button_value: `Download EAP Request Referral Form - ${lang.toUpperCase()}`,
        },
      },
    })
  }

  return (
    <Page isLoading={false}>
      <Typography variant="h1" sx={{ marginBottom: 3 }} data-testid="page-title">
        {t('organizationEapRequests.title')}
      </Typography>
      <Grid container spacing={{ xs: 4, lg: 12 }}>
        <Grid item xs={12} lg={6}>
          <Stack spacing={4}>
            <Box>
              <Typography variant="subtitle2" mb={2}>
                {t('organizationEapRequests.whatSection.heading')}
              </Typography>
              <Typography variant="body2">
                {t('organizationEapRequests.whatSection.description')}
              </Typography>
            </Box>
            <Divider sx={{ borderColor: 'accent.main' }} />
            <Box>
              <Typography variant="subtitle2" mb={2}>
                {t('organizationEapRequests.stepsSection.heading')}
              </Typography>
              <Stack spacing={2}>
                {stepsSectionItems.map((step, index) => (
                  <Step key={step} label={`${stepTr} ${index + 1}:`}>
                    <Stack>
                      <Typography variant="body2">{step}</Typography>
                      {index === formStepIndex && (
                        <Stack direction="row" ml={-1.75}>
                          {languages.map((lang) => (
                            <Link
                              href={`/assets/eap-forms/${requestForms[lang]}.pdf`}
                              key={lang}
                              download
                            >
                              <Button
                                variant="text"
                                sx={{ color: colors.dialoguePeach, fontSize: 14 }}
                                onClick={() => trackDownloadAnalytics(lang)}
                              >
                                {t(`organizationEapRequests.stepsSection.actions.${lang}`)}
                              </Button>
                            </Link>
                          ))}
                        </Stack>
                      )}
                    </Stack>
                  </Step>
                ))}
              </Stack>
            </Box>
            <Divider sx={{ borderColor: 'accent.main' }} />
            <Box>
              <Typography variant="subtitle2" mb={2}>
                {t('organizationEapRequests.concernSection.heading')}
              </Typography>
              <Typography variant="body2">
                {t('organizationEapRequests.concernSection.description')}
              </Typography>
            </Box>
            <Divider sx={{ borderColor: 'accent.main' }} />
            <Box>
              <Typography variant="subtitle2">
                {t('organizationEapRequests.expectSection.heading')}
              </Typography>
              <List sx={{ listStyleType: 'disc', pl: 2 }}>
                {expectSectionItems.map((label) => (
                  <ListItem key={label} sx={{ display: 'list-item', p: 0 }}>
                    <Typography variant="body2">{label}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <RequestForm />
        </Grid>
      </Grid>
    </Page>
  )
}
