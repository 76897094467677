import React, { useCallback, useState } from 'react'

import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LockOutlined from '@mui/icons-material/LockOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, CircularProgress, Tab } from '@mui/material'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { LocalizedUrlsByProgram } from 'app/models/scribe.models'
import { Investigate, Manage, NoActiveRecords, Refresh } from 'assets/images'

import { STRETCH_FULL_HEIGHT } from '../constants'
import { useLanguage } from '../hooks/use-language'

import { NoResults } from './NoResults'

export const MINIMUM_ACTIVE_MEMBERS = 35
export const MINIMUM_DAYS_SINCE_LAUNCH_DATE = 30

export enum ReportAccessType {
  ENABLED = 'enabled',
  INSUFFICIENT_MEMBERS = 'insufficientMembers',
  NOT_YET_AVAILABLE = 'notYetAvailable',
  UNKNOWN_ERROR = 'unknownError',
}

export type ReportAccessProps =
  | Readonly<{
      access: ReportAccessType.ENABLED
    }>
  | Readonly<{
      access: ReportAccessType.INSUFFICIENT_MEMBERS
      numberOfMissingMembers: number
    }>
  | Readonly<{
      access: ReportAccessType.NOT_YET_AVAILABLE
      remainingDays: number
    }>
  | Readonly<{
      access: ReportAccessType.UNKNOWN_ERROR
    }>

type EngagementReportsProps = { urlsPerProgram: LocalizedUrlsByProgram | null } & ReportAccessProps

enum TabType {
  SUMMARY = 'execSummary',
  PRIMARY_CARE = 'primaryCare',
  MENTAL_HEALTH = 'mentalHealth',
  EAP = 'employeeAssistanceProgram',
  WELLNESS = 'wellness',
}

export const EngagementReports: React.FC<EngagementReportsProps> = (props) => {
  const { urlsPerProgram } = props

  const { t } = useTranslation(undefined, { keyPrefix: 'organizationReportsPage.tabs' })
  const language = useLanguage()

  const [searchParams, setSearchParams] = useSearchParams()

  const tabValue = (searchParams.get('tab') as TabType) || TabType.SUMMARY

  const handleTabChange = useCallback(
    (_: any, newValue: string) => {
      setSearchParams({ tab: newValue })
    },
    [setSearchParams],
  )

  const urlForProgram = useCallback(
    (tabType: TabType) => {
      return (
        (urlsPerProgram && urlsPerProgram[tabType] && urlsPerProgram[tabType]![language]) ||
        undefined
      )
    },
    [urlsPerProgram, language],
  )

  const generateTabs = useCallback(() => {
    const enabledTabs: JSX.Element[] = []
    const disabledTabs: JSX.Element[] = []

    Object.values(TabType).forEach((tabType) => {
      const generateTab = (_enabled: boolean, _tabType: TabType) => (
        <Tab
          icon={_enabled ? undefined : <LockOutlined fontSize="small" />}
          iconPosition="end"
          label={t(_tabType)}
          key={`${tabType}-${language}`}
          value={_tabType}
          data-testid="report-tab"
        />
      )
      if (!!urlForProgram(tabType)) {
        enabledTabs.push(generateTab(true, tabType))
      } else {
        disabledTabs.push(generateTab(false, tabType))
      }
    })
    return [...enabledTabs, ...disabledTabs]
  }, [t, language, urlForProgram])

  return (
    <TabContext value={tabValue}>
      <Box>
        <TabList onChange={handleTabChange} aria-label="status-tabs">
          {generateTabs()}
        </TabList>
      </Box>
      <TabPanel value={tabValue} key={`${tabValue}-${language}`} sx={STRETCH_FULL_HEIGHT}>
        <TabContent reportsAccess={props} programUrl={urlForProgram(tabValue)} tabType={tabValue} />
      </TabPanel>
    </TabContext>
  )
}

interface TabContentProps {
  reportsAccess: ReportAccessProps
  programUrl?: string
  tabType: TabType
}

const TabContent: React.FC<TabContentProps> = ({ reportsAccess, programUrl, tabType }) => {
  const { access } = reportsAccess
  const { t } = useTranslation(undefined, { keyPrefix: 'organizationReportsPage.messages' })
  switch (access) {
    case ReportAccessType.UNKNOWN_ERROR: {
      const title = t(`${access}.title`)
      const button = (
        <Button variant="contained" href={t('unknownError.supportUrl')}>
          {t(`${access}.buttonText`)}
        </Button>
      )
      return (
        <NoResults
          title={title}
          subtitle={t(`${access}.subtitle`)}
          img={<Refresh title={title} />}
          actionElement={button}
        />
      )
    }
    case ReportAccessType.INSUFFICIENT_MEMBERS: {
      const { numberOfMissingMembers } = reportsAccess
      const title = t(`${access}.title`, { count: numberOfMissingMembers })
      return (
        <NoResults
          title={title}
          subtitle={t(`${access}.subtitle`, {
            minimumActiveMembers: MINIMUM_ACTIVE_MEMBERS.toString(),
          })}
          img={<NoActiveRecords title={title} />}
        />
      )
    }
    case ReportAccessType.NOT_YET_AVAILABLE: {
      const { remainingDays } = reportsAccess
      const title = t(`${access}.title`, { count: remainingDays })
      return (
        <NoResults
          title={title}
          subtitle={t(`${access}.subtitle`, {
            minimumDays: MINIMUM_DAYS_SINCE_LAUNCH_DATE.toString(),
          })}
          img={<Manage title={access} />}
        />
      )
    }
    default: {
      if (!programUrl) {
        const title = t('programNotEnabled.title', { programName: tabType })
        const button = (
          <Button
            href={t(`programNotEnabled.progamInfoUrl.${tabType}`)}
            variant="contained"
            target="_blank"
          >
            {t('programNotEnabled.buttonText')}
            <ExitToAppIcon sx={{ ml: 1, color: 'accent.main' }} />
          </Button>
        )
        return (
          <NoResults
            title={title}
            subtitle={t(`programNotEnabled.subtitle.${tabType}`)}
            img={<Investigate title={title} />}
            actionElement={button}
          />
        )
      }
      return <ReportDashboard url={programUrl} />
    }
  }
}

interface ReportDashboardProps {
  url: string
}
const ReportDashboard: React.FC<ReportDashboardProps> = ({ url }) => {
  const [reportIframeIsLoading, setReportIframeIsLoading] = useState<boolean>(true)
  return (
    <>
      {reportIframeIsLoading && (
        <Box
          sx={{
            position: 'absolute',
            transform: 'translate(50%)',
            top: '50%',
            left: '50%',
            zIndex: 1,
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      <iframe
        id="sisenseContainer"
        title="sisenseDashboard"
        width="100%"
        height="100%"
        frameBorder="0"
        onLoad={() => setReportIframeIsLoading(false)}
        src={url}
        data-testid="sisense-container"
      />
    </>
  )
}
