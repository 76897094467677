import { FormControlLabel, FormControlLabelProps, Switch } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

type Props = Omit<FormControlLabelProps, 'control'> & {
  name: string
  options?: string[]
}

const SwitchFormField = ({ name, ...props }: Props) => {
  const { control } = useFormContext()

  const isChecked = (field: any) =>
    props.options ? props.options[1] === field.value : !!field.value

  const onChange = (field: any, event: any) => {
    if (props.options) {
      field.onChange(isChecked(field) ? props.options[0] : props.options[1])
      return
    }

    return field.onChange(event)
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          {...props}
          onChange={(e) => onChange(field, e)}
          control={<Switch checked={isChecked(field)} />}
        />
      )}
    />
  )
}

export default SwitchFormField
