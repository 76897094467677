import { FC } from 'react'

import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type LanguageButtonProps = Readonly<{ lang: 'en' | 'fr' }>

const LanguageButton: FC<LanguageButtonProps> = ({ lang }) => {
  const { i18n } = useTranslation()
  const selected = i18n.resolvedLanguage === lang
  const changeLanguage = () => i18n.changeLanguage(lang)

  return (
    <Button
      sx={{ minWidth: 'initial', p: 1 }}
      aria-selected={selected}
      onClick={changeLanguage}
      data-testid={lang}
    >
      <Typography color={selected ? 'accent.main' : 'secondary'} variant="button">
        {lang}
      </Typography>
    </Button>
  )
}

export const LanguageSelector = () => (
  <Box sx={{ mr: 4, display: 'flex', alignItems: 'center' }}>
    <LanguageButton lang="en" />
    <Typography color="secondary.dark">|</Typography>
    <LanguageButton lang="fr" />
  </Box>
)
