import { useCallback, useMemo } from 'react'

import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import Page from 'app/layout/Page'
import { PlanCard } from 'app/lib/components/plans/PlanCard'
import { organizationMemberProfile } from 'app/lib/routes'
import { getCurrentAndOtherPlans, isArchived } from 'app/models/Organization'
import { EligibilityRecordRoute } from 'app/models/scribe.models'
import {
  useGetEligibilityRecordQuery,
  useLegacyGetOrganizationPlansQuery,
  useSubmitEligibilityDirectiveMutation,
} from 'app/redux/scribeApi'

export const MemberChangePlan: React.FC = () => {
  const { t } = useTranslation()
  const { organizationId, eligibilityRecordId } = useParams() as EligibilityRecordRoute
  const navigate = useNavigate()
  const { data: plans = [], isLoading: isLoadingOrg } =
    useLegacyGetOrganizationPlansQuery(organizationId)
  const [submitDirective] = useSubmitEligibilityDirectiveMutation()
  const { data: eligibilityRecord, isLoading: isLoadingRecord } =
    useGetEligibilityRecordQuery(eligibilityRecordId)

  const [currentPlan, otherPlans] = useMemo(() => {
    const [current, other = []] = getCurrentAndOtherPlans(plans, eligibilityRecord)
    return [current, other.filter((el) => !isArchived(el))]
  }, [plans, eligibilityRecord])

  const { firstName, lastName } = eligibilityRecord?.attributes || {}

  const memberChangePlanTitle =
    firstName && lastName
      ? t('memberChangePlan.title.named', { firstName, lastName })
      : t('memberChangePlan.title.anonymous')

  const hasOtherPlans = otherPlans.length > 0

  const navigateToMemberProfile = useCallback(() => {
    navigate(organizationMemberProfile.get(organizationId, eligibilityRecordId))
  }, [navigate, organizationId, eligibilityRecordId])

  const updateRecordPlan = useCallback(
    (planLabel: string) => {
      const body = {
        uniqueIdentifier: eligibilityRecord?.attributes.uniqueIdentifier || '',
        plan: planLabel,
      }
      submitDirective({ organizationId, body }).unwrap().then(navigateToMemberProfile)
    },
    [submitDirective, navigateToMemberProfile, eligibilityRecord, organizationId],
  )

  return (
    <Page isLoading={isLoadingOrg || isLoadingRecord}>
      <Stack>
        <Typography variant="h1">{memberChangePlanTitle}</Typography>
        {currentPlan ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 4 }}
            >
              <Typography variant="h3" mb={2}>
                {t('organizationProfilePlans.currentPlan')}
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="outlined" onClick={navigateToMemberProfile}>
                  {t('global.dialog.cancel')}
                </Button>
              </Box>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} xxl={4}>
                <PlanCard plan={currentPlan} isArchived={isArchived(currentPlan)} />
              </Grid>
            </Grid>
            <Typography variant="h3" mb={2} mt={4}>
              {t('organizationProfilePlans.otherPlans')}
            </Typography>
            {hasOtherPlans ? (
              <Grid container spacing={2}>
                {otherPlans.map((plan) => (
                  <Grid item key={plan.id} xs={12} md={6} xxl={4}>
                    <PlanCard
                      plan={plan}
                      selectable
                      actionButton={
                        <Button
                          variant="contained"
                          onClick={() => updateRecordPlan(plan.attributes.label)}
                        >
                          {t('memberChangePlan.selectButton')}
                        </Button>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body2">{t('organizationProfilePlans.noOtherPlans')}</Typography>
            )}
          </>
        ) : (
          <Typography variant="body2">{t('organizationProfilePlans.noPlans')}</Typography>
        )}
      </Stack>
    </Page>
  )
}
