import { ChangeEvent } from 'react'

import { Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  label: string
  accept: string
  selectedFile: File | null
  onChange: (file: File) => void
  onReset?: () => void
}

export const UploadFormField: React.FC<Props> = ({
  label,
  accept,
  selectedFile,
  onChange,
  onReset,
}) => {
  const { t } = useTranslation()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }
    const file = e.target.files[0]
    onChange(file)
    e.target.value = ''
  }

  const handleRemove = () => {
    onReset?.()
  }

  const isFileSelected = Boolean(selectedFile?.name)

  return (
    <Stack direction="row" alignItems="center" spacing={4}>
      <Button variant="contained" component="label" sx={{ flexShrink: 0 }}>
        {label}
        <input
          accept={accept}
          type="file"
          onChange={handleChange}
          hidden
          data-testid="request-file"
        />
      </Button>
      {isFileSelected && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            variant="subtitle2"
            sx={{ textDecoration: 'underline' }}
            data-testid="selected-file-name"
          >
            {selectedFile?.name}
          </Typography>
          <Button variant="text" color="error" onClick={handleRemove}>
            {t('global.dialog.remove')}
          </Button>
        </Stack>
      )}
    </Stack>
  )
}
