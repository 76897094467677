import { CSSProperties } from 'react'

import type {} from '@mui/lab/themeAugmentation'
import { tableCellClasses } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import { createTheme } from '@mui/material/styles'

import { colors } from './colors'

export { colors } from './colors'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dark: true
    link: true
    disabled: true
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
    caption1: true
    caption2: true
  }
  interface Typography {
    body3: true
    caption1: true
    caption2: true
    link: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary']
    accent: Palette['primary']
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary']
    accent: PaletteOptions['primary']
  }
  interface TypographyVariants {
    body3: CSSProperties
    caption1: CSSProperties
    caption2: CSSProperties
    link: CSSProperties
  }
  interface BreakpointOverrides {
    xxl: true
  }
}

const muiHelper = createTheme({
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    action: {
      disabledBackground: colors.silver,
      disabled: colors.tertiary,
    },
    primary: {
      main: colors.dialogueCharcoal,
    },
    secondary: {
      main: colors.dialogueLinen,
      dark: colors.dirtyLinen,
    },
    success: {
      main: colors.dialogueGreen,
    },
    background: {
      default: colors.smoke,
    },
    text: {
      primary: colors.dialogueCharcoal,
      secondary: colors.dialogueLinen,
      disabled: colors.tertiary,
    },
    info: {
      main: colors.sky,
    },
    tertiary: {
      main: colors.tertiary,
    },
    accent: {
      main: colors.dialoguePeach,
    },
    error: {
      main: colors.alarm,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1720,
    },
  },
})

let theme = createTheme(muiHelper, {
  shadows: [
    'none',
    '0px 2px 4px #F1F1F1',
    '0px 2px 4px #131313',
    '0px 4px 12px rgba(0, 0, 0, 0.15)',
    ...muiHelper.shadows.slice(4),
  ],
  typography: {
    h1: {
      fontFamily: 'Poynter',
      fontSize: muiHelper.typography.pxToRem(32),
      fontWeight: 600,
      lineHeight: muiHelper.typography.pxToRem(42),
      letterSpacing: 'initial',
    },
    h2: {
      fontFamily: 'Poynter',
      fontSize: muiHelper.typography.pxToRem(26),
      fontWeight: 600,
      lineHeight: muiHelper.typography.pxToRem(34),
      letterSpacing: 'initial',
    },
    h3: {
      fontFamily: 'Poynter',
      fontSize: muiHelper.typography.pxToRem(22),
      fontWeight: 600,
      lineHeight: muiHelper.typography.pxToRem(29),
      letterSpacing: 'initial',
    },
    h4: {
      fontFamily: 'Poynter',
      fontSize: muiHelper.typography.pxToRem(18),
      fontWeight: 600,
      lineHeight: muiHelper.typography.pxToRem(24),
      letterSpacing: 'initial',
    },
    subtitle1: {
      fontWeight: muiHelper.typography.fontWeightBold,
      fontSize: muiHelper.typography.pxToRem(12),
      letterSpacing: muiHelper.typography.pxToRem(0.5),
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: muiHelper.typography.pxToRem(14),
      fontWeight: 500,
      lineHeight: muiHelper.typography.pxToRem(16),
      letterSpacing: 'initial',
    },
    body1: {
      fontSize: muiHelper.typography.pxToRem(18),
      fontWeight: muiHelper.typography.fontWeightLight,
    },
    body2: {
      letterSpacing: 'initial',
    },
    body3: {
      fontSize: muiHelper.typography.pxToRem(12),
      fontWeight: muiHelper.typography.fontWeightRegular,
      fontFamily: muiHelper.typography.fontFamily,
    },
    caption1: {
      fontSize: muiHelper.typography.pxToRem(12),
      fontWeight: muiHelper.typography.fontWeightMedium,
      fontFamily: muiHelper.typography.fontFamily,
    },
    caption2: {
      fontSize: muiHelper.typography.pxToRem(10),
      fontWeight: muiHelper.typography.fontWeightRegular,
      fontFamily: muiHelper.typography.fontFamily,
    },
    button: {
      fontSize: muiHelper.typography.pxToRem(16),
      fontWeight: muiHelper.typography.fontWeightBold,
      textTransform: 'capitalize',
      lineHeight: 1.5,
    },
    link: {
      color: muiHelper.palette.accent.main,
      fontSize: muiHelper.typography.pxToRem(16),
      fontWeight: muiHelper.typography.fontWeightBold,
      textDecoration: 'none',
    },
  },
})

theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          letterSpacing: 'initial',
          backgroundColor: '#fffcfa',
          display: 'flex',
          flexDirection: 'column',
          '#root': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          borderLeft: '2px solid red',
          borderRadius: 0,
          marginBottom: '16px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          ...theme.typography.link,
        },
      },
      variants: [
        {
          props: { variant: 'subtitleLink' },
          style: {
            color: colors.navy,
            fontSize: '14px',
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'none',
            },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '.Searchbar-Textfield': {
            backgroundColor: 'transparent',
            '&:hover, &.Mui-focused': {
              backgroundColor: 'transparent',
            },
          },
          '.MuiTypography-root': {
            color: 'inherit',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) => ({
          alignSelf: 'start',
          padding: '8px 14px',
          border: ownerState.variant === 'outlined' ? '' : '1px solid transparent',
          '&.Mui-disabled': {
            color: ['outlined', 'text'].includes(ownerState.variant || '')
              ? colors.silver
              : colors.tertiary,
          },
        }),
        link: {
          color: colors.navy,
          textTransform: 'none',
          fontWeight: muiHelper.typography.fontWeightMedium,
          fontSize: muiHelper.typography.pxToRem(14),
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: colors.dialogueLinen,
            '.MuiButton-startIcon': {
              color: colors.dialoguePeach,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            color: colors.dialogueCharcoal,
            '.MuiButton-startIcon': {
              color: colors.dialogueCharcoal,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            '.MuiButton-startIcon': {
              color: colors.dialoguePeach,
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            color: colors.navy,
            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'initial',
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.tertiary,
          '&.Mui-focused': {
            ...theme.typography.caption1,
          },
        },
        shrink: {
          ...theme.typography.caption1,
          color: colors.tertiary,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          [`&.borderless .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              cursor: 'pointer',
              background: colors.dialogueLinen,
            },
          },
          '&.highlighted': {
            background: colors.lightGrey2,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: colors.smoke,
          overflowWrap: 'break-word',
          '.InlineTableCell': {
            width: '30%',
            backgroundColor: 'transparent',
            color: colors.dialogueCharcoal,
            textTransform: 'none',
          },
        },
      },
      variants: [
        {
          props: { variant: 'head' },
          style: {
            ...theme.typography.subtitle1,
            backgroundColor: colors.smoke,
            color: colors.tertiary,
          },
        },
        {
          props: { variant: 'body' },
          style: {
            ...theme.typography.body2,
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
          backgroundColor: colors.smoke,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: colors.tertiary,
          backgroundColor: 'white',
          boxShadow: theme.shadows[1],
          marginRight: '1px',
          marginBottom: '1px',
          borderTop: '2px solid rgba(0,0,0,0)',
          width: '187px',
          height: '48px',
          ...theme.typography.subtitle2,
        },
        labelIcon: {
          minHeight: '48px',
        },
      },
      variants: [
        {
          props: { selected: true },
          style: {
            borderTop: '2px solid ' + colors.dialoguePeach,
            backgroundColor: colors.smoke,
          },
        },
      ],
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
          backgroundColor: 'white',
          boxShadow: theme.shadows[1],
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          gap: 8,
          padding: 24,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: colors.silver,
        },
        li: {
          a: {
            ...theme.typography.body2,
            color: colors.tertiary,
          },
          p: {
            ...theme.typography.subtitle2,
            color: colors.dialogueCharcoal,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.StripeInputBox-underline': {
            paddingBottom: '5px',
            borderBottom: '1px solid ' + colors.darkGrey,
            '&:hover': {
              borderBottom: '2px solid ' + colors.dialogueCharcoal,
            },
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.MuiContainer-disableGutters': {
            backgroundColor: colors.dialogueLinen,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.tertiary,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.dialogueCharcoal,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 8,
          height: 30,
          width: 44,
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              color: '#FFF',
              transform: 'translateX(13px)',

              '& + .MuiSwitch-track': {
                backgroundColor: colors.dialoguePeach,
                opacity: 1,
              },
            },
            '& + .MuiSwitch-track': {
              backgroundColor: colors.tertiary,
              opacity: 1,
              borderRadius: 22 / 2,
            },
          },
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 12,
            height: 12,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.dialogueCharcoal,
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          button: {
            '&:disabled': {
              color: colors.silver,
            },
          },
          '.MuiTypography-caption': {
            color: colors.dialogueCharcoal,
            fontWeight: 600,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.subtitle2,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...theme.typography.h2,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.dialogueLinen,
        },
        barColorPrimary: {
          backgroundColor: colors.dialoguePeach,
        },
      },
    },
  },
})

export default theme
