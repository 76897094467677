import { FC, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { jwtDecode } from 'jwt-decode'

interface WithProtectedComponentProps {
  permission?: string
}

export const useUserPermissions = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [permissions, setPermissions] = useState<string[] | undefined>(undefined)
  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      let decodedToken: { permissions?: string[] } | undefined
      decodedToken = jwtDecode(token)
      setPermissions(decodedToken?.permissions)
    })
  }, [getAccessTokenSilently])
  return permissions
}

function withProtectedComponent<P>(
  WrappedComponent: FC<Omit<WithProtectedComponentProps & P, 'permission'>>,
) {
  return ({ permission, ...rest }: WithProtectedComponentProps & P) => {
    const userPermissions = useUserPermissions()
    if (!permission) return <WrappedComponent {...rest} />
    if (userPermissions?.includes(permission)) return <WrappedComponent {...rest} />
    return null
  }
}
export default withProtectedComponent
