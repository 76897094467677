import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Page from 'app/layout/Page'
import { ClickableRow } from 'app/lib/components/ClickableRow'
import TableContent from 'app/lib/components/TableContent'
import { ChallengeRoute } from 'app/models/scribe.models'
import { useListChallengeLeaderboardQuery } from 'app/redux/activeMinutesApi'

import { ExportButton } from './ExportButton'

export const ChallengeLeaderboard: React.FC = () => {
  const { organizationId, challengeId } = useParams() as ChallengeRoute
  const { t } = useTranslation(undefined, { keyPrefix: 'challengeLeaderboardPage' })

  const {
    data: { participants, participantsCount, totalScoreValue } = {
      participants: [],
      partiparticipantsCount: 0,
      totalScoreValue: 0,
    },
    isFetching,
  } = useListChallengeLeaderboardQuery({
    organizationId,
    challengeId,
  })
  // FIXME: Metric for the total score is not defined in the API response (why, I don't know)
  // so we use the metric from the first participant and hope it's correct.
  const totalMetric = participants.length ? participants[0].metric : null

  const rows = participants.map(({ rank, userDisplayName, metricValue, metric }, idx) => {
    let backgroundColor = 'transparent'
    switch (rank) {
      case 1:
        backgroundColor = '#D4AF37'
        break
      case 2:
        backgroundColor = 'silver'
        break
      case 3:
        backgroundColor = '#cd7f32'
        break
    }
    return (
      <ClickableRow key={idx} to={''}>
        <TableCell style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
          <Chip label={rank} style={{ backgroundColor, color: rank < 4 ? 'white' : 'black' }} />
        </TableCell>
        <TableCell>{userDisplayName}</TableCell>
        <TableCell>
          {Number(metricValue).toLocaleString()} {t(`metric.${metric}`, { defaultValue: metric })}
        </TableCell>
      </ClickableRow>
    )
  })

  return (
    <Page>
      <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Box flexGrow={1}>
          <Stack direction="column" alignItems="flex-start" flexWrap="wrap" gap={4}>
            <Typography variant="h1" data-testid="page-title" display="flex" alignItems="top">
              {t('title')}
            </Typography>
            <Grid container spacing={3} width="33%">
              <Grid item xs={6}>
                <Card sx={{ borderRadius: '20px', boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)' }}>
                  <CardContent>
                    <Typography variant="h3" style={{ color: 'orange' }}>
                      {participantsCount}
                    </Typography>
                    <Typography variant="body2">{t('cards.participants')}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ borderRadius: '20px', boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)' }}>
                  <CardContent>
                    <Typography variant="h3" style={{ color: 'orange' }}>
                      {totalScoreValue}
                    </Typography>
                    <Typography variant="body2">
                      {t('cards.total', {
                        metric: t(`metric.${totalMetric}`, { defaultValue: totalMetric || '-' }),
                      })}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <ExportButton leaderboard={participants} challengeId={challengeId} />
      </Stack>
      <Box
        sx={{
          mb: 3,
          mt: 4,
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <TableContent
          isLoading={isFetching}
          count={participants.length}
          notFound={t('notFound')}
          searchNoMatch={t('notFound')}
          iconName="no_member_found"
        >
          <Table>
            <TableHead>
              <TableCell>{t('table.headers.place')}</TableCell>
              <TableCell>{t('table.headers.name')}</TableCell>
              <TableCell>{t('table.headers.score')}</TableCell>
            </TableHead>
            <TableBody data-testid="data-table">{rows}</TableBody>
          </Table>
        </TableContent>
      </Box>
    </Page>
  )
}
