import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'app/lib/utils/date'
import { exportInvoiceDetailsToCsv } from 'app/lib/utils/export-records-to-csv'
import { getSnowplowSchema } from 'app/lib/utils/helpers'
import { getCurrencySymbol } from 'app/lib/utils/regions'
import { OrganizationInvoice, Pagination, Plan, UsageRange } from 'app/models/scribe.models'
import {
  useLazyGetAllEligiblePeriodsQuery,
  useLazyLegacyGetOrganizationPlansQuery,
} from 'app/redux/scribeApi'
import { DownloadIcon } from 'assets/images'

const TABLE_HEADERS = [
  ['organizationInvoiceListPage.organizationInvoiceTable.headers.issue_date', 'left'],
  ['organizationInvoiceListPage.organizationInvoiceTable.headers.status', 'left'],
  ['organizationInvoiceListPage.organizationInvoiceTable.headers.amount', 'right'],
  ['organizationInvoiceListPage.organizationInvoiceTable.headers.PDF', 'center'],
  ['organizationInvoiceListPage.organizationInvoiceTable.headers.invoiceDetails', 'center'],
] as const

const currencySymbol = getCurrencySymbol()

type TableProps = Readonly<{
  organizationId: string
  organizationInvoices: Array<OrganizationInvoice>
  pagination: Pagination
  onPageChange: (page: number) => void
}>

export const OrganizationInvoicesTable: React.FC<TableProps> = ({
  organizationId,
  organizationInvoices,
  pagination,
  onPageChange,
}) => {
  const [fetchAllEligiblePeriods] = useLazyGetAllEligiblePeriodsQuery()
  const [fetchOrganizationPlans] = useLazyLegacyGetOrganizationPlansQuery()

  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const cells = TABLE_HEADERS.map(([i18nKey, alignment]) => (
    <TableCell key={i18nKey} align={alignment} padding="normal">
      {t(i18nKey)}
    </TableCell>
  ))

  const downloadInvoiceDetails = (usageRange: UsageRange, invoiceId: OrganizationInvoice['id']) => {
    fetchOrganizationPlans(organizationId, true)
      .unwrap()
      .then((plans: Plan[]) => {
        const ids = plans.map(({ id }) => id)
        fetchAllEligiblePeriods({ planIds: ids, params: usageRange })
          .unwrap()
          .then((periods) => {
            exportInvoiceDetailsToCsv(plans, organizationId, invoiceId, periods)
            enqueueSnackbar(t('organizationInvoiceListPage.download.success'), {
              variant: 'success',
            })
          })
          .catch(() => {
            enqueueSnackbar(t('global.unknownError'), {
              variant: 'error',
            })
          })
      })
  }

  const downloadInvoice = (download_link?: string) => {
    if (!download_link) {
      return
    }
    trackSelfDescribingEvent({
      event: {
        schema: getSnowplowSchema('presto_invoice_downloaded', '1-0-0'),
        data: { link: download_link },
      },
    })
    window.open(download_link, '_blank')
  }

  const rows = organizationInvoices.map((organizationInvoice) => (
    <TableRow key={organizationInvoice.id}>
      <TableCell>
        <Typography variant="subtitle2">
          {formatDate(organizationInvoice.issue_date, resolvedLanguage, 'long')}
        </Typography>
      </TableCell>
      <TableCell>
        {t(
          `organizationInvoiceListPage.organizationInvoiceTable.invoiceStatus.${organizationInvoice.status}`,
        )}
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        {t('organizationInvoiceListPage.amount', {
          amount: organizationInvoice.total_amount.toFixed(2),
          symbol: currencySymbol,
        })}
      </TableCell>
      <TableCell align="center">
        <Link component="button" onClick={() => downloadInvoice(organizationInvoice.download_link)}>
          {organizationInvoice.download_link && (
            <DownloadIcon
              title={t('organizationInvoiceListPage.organizationInvoiceTable.headers.PDF')}
              data-testid="download-invoice-pdf"
            />
          )}
        </Link>
      </TableCell>
      <TableCell align="center">
        <Link
          component="button"
          onClick={() =>
            downloadInvoiceDetails(organizationInvoice.usage_range, organizationInvoice.id)
          }
        >
          <DownloadIcon
            title={t('organizationInvoiceListPage.organizationInvoiceTable.headers.invoiceDetails')}
            data-testid="download-invoice-details"
          />
        </Link>
      </TableCell>
    </TableRow>
  ))

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{cells}</TableRow>
        </TableHead>
        <TableBody data-testid="invoice-table">{rows}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={Math.floor(pagination.offset / pagination.limit)}
              rowsPerPage={pagination.limit}
              rowsPerPageOptions={[]}
              count={pagination.total}
              showFirstButton
              showLastButton
              onPageChange={(_event, page) => onPageChange(page)}
              getItemAriaLabel={(type) => t(`global.pagination.${type}`)}
              labelDisplayedRows={({ from, to, count: itemsCount }) =>
                itemsCount > 0
                  ? t('global.pagination.of', { from, to, count: itemsCount })
                  : t('global.pagination.of_more_than', { from, to })
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
