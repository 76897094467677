import { Checkbox, FormControlLabel } from '@mui/material'
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel'
import { Controller, useFormContext } from 'react-hook-form'

type Props = Omit<FormControlLabelProps, 'control'> & {
  name: string
}

const CheckboxFormField = ({ name, ...props }: Props) => {
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControlLabel {...field} {...props} control={<Checkbox checked={field.value} />} />
      )}
    />
  )
}

export default CheckboxFormField
