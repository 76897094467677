import { LoadingButton } from '@mui/lab'
import { Box, Button, Link, Stack, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

type HeadingProps = {
  isEdit: boolean
  isReportingColumns: boolean
  isDirty: boolean
  isLoading?: boolean
  onCancel: () => void
}

const documentUrl = ` https://www.notion.so/godialogue/Reporting-Columns-57c908d80719489d987a8d484091b324#33a594ad793a4de581ab396ce2c83455`

export const Heading: React.FC<HeadingProps> = ({
  isEdit,
  isDirty,
  isReportingColumns,
  isLoading = false,
  onCancel,
}) => {
  const { t } = useTranslation()

  const getTitle = () => {
    if (isEdit) {
      return t('organizationPage.title.edit')
    } else if (isReportingColumns) {
      return t('organizationPage.title.reportingColumns')
    } else {
      return t('organizationPage.title.new')
    }
  }

  const title = getTitle()
  const buttonLabel = isReportingColumns ? t('actions.submit') : t('actions.save')

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">{title}</Typography>
        {(isEdit || isReportingColumns) && (
          <Stack direction="row" justifyItems="flex-end" spacing={2}>
            <Button variant="outlined" onClick={onCancel}>
              {t('global.dialog.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              key="preventDoubleSubmit"
              variant="contained"
              color="primary"
              disabled={!isDirty}
              loading={isLoading}
            >
              {buttonLabel}
            </LoadingButton>
          </Stack>
        )}
      </Stack>
      {isReportingColumns && (
        <Box>
          <Typography variant="body2">
            {t('organizationPage.form.reportingColumns.message')}
          </Typography>
          <Typography sx={{ marginTop: '20px' }} variant="body2">
            <Trans i18nKey="organizationPage.form.reportingColumns.moreInformationMessage">
              <Link
                href={documentUrl}
                sx={{ fontSize: 14, textDecoration: 'underline' }}
                color="inherit"
                target="_blank"
              ></Link>
            </Trans>
          </Typography>
        </Box>
      )}
    </>
  )
}
