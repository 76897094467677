import { configureStore } from '@reduxjs/toolkit'

import { activeMinutesApi } from './activeMinutesApi'
import { erApi } from './erApi'
import { multipassApi } from './multipassApi'
import rootReducer from './reducer'
import { scribeApi } from './scribeApi'

type RootState = ReturnType<typeof rootReducer>

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(scribeApi.middleware)
        .concat(erApi.middleware)
        .concat(multipassApi.middleware)
        .concat(activeMinutesApi.middleware),
    preloadedState,
  })
}

export const store = setupStore()

export default store
