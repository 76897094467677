import { useMemo } from 'react'

import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Attribute, AttributeTable } from 'app/lib/components/AttributeTable'
import { Label, LabelType } from 'app/lib/components/Label'
import { PlanServices } from 'app/lib/components/plans/PlanServices'
import { ScribePermission } from 'app/lib/constants'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { getCurrencySymbol } from 'app/lib/utils/regions'
import { Plan } from 'app/models/scribe.models'
import { colors } from 'app/theme'

enum CardContentType {
  SELECTABLE = 'selectable',
  ARCHIVED = 'archived',
  NORMAL = 'normal',
}

const CardStyle = {
  [CardContentType.SELECTABLE]: { backgroundColor: 'secondary.main' },
  [CardContentType.ARCHIVED]: { backgroundColor: colors.smoke },
  [CardContentType.NORMAL]: {},
}

type Props = {
  plan: Plan
  selectable?: boolean
  actionButton?: JSX.Element
  isArchived?: boolean
}

const currencySymbol = getCurrencySymbol()

export const PlanCard: React.FC<Props> = ({
  plan: { attributes, relationships },
  selectable,
  actionButton,
  isArchived,
}) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const userPermissions = useUserPermissions()
  const canReadBilling = userPermissions?.includes(ScribePermission.READ_ORGANIZATION_BILLING)
  const canReadCoBrand = userPermissions?.includes(ScribePermission.CREATE_PLAN)
  const chargeStrategy: string = t(`global.chargeStrategyStatus.${attributes.chargeStrategy}`)
  const services = attributes.features.map((el) => el.localizedName[resolvedLanguage] ?? el.label)
  const orderedServices = services.sort((a, b) => a.localeCompare(b))
  const price = (attributes.chargePrice || 0).toFixed(2)
  const isDefault = attributes.isDefault

  const planAttributes: Attribute[] = [
    ...(canReadBilling
      ? [
          {
            key: 'cost',
            name: t('planCard.cost'),
            value: t('planCard.planPrice', { price, symbol: currencySymbol }),
          },
          { key: 'chargingModel', name: t('planCard.chargingModel'), value: chargeStrategy },
        ]
      : []),
    ...(canReadCoBrand
      ? [
          {
            key: 'coBrand',
            name: t('planCard.coBrand'),
            value: relationships?.cobrand
              ? relationships?.cobrand?.data?.id?.toUpperCase()
              : t('planCard.noCoBrand'),
          },
        ]
      : []),
    { key: 'planLabel', name: t('planCard.planLabel'), value: attributes.label },
    {
      key: 'planServices',
      name: t('planCard.planServices'),
      value: <PlanServices services={orderedServices} />,
    },
  ]

  const cardContentType = useMemo<CardContentType>(() => {
    if (selectable) return CardContentType.SELECTABLE
    if (isArchived) return CardContentType.ARCHIVED

    return CardContentType.NORMAL
  }, [selectable, isArchived])

  return (
    <Card sx={{ padding: 0 }}>
      <CardContent
        sx={{
          height: '100%',
          ...CardStyle[cardContentType],
          '&.MuiCardContent-root': {
            p: 0.5,
            pb: 0,
          },
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3" p={2} pb={3}>
            {attributes.name}
          </Typography>
          {isDefault && (
            <Label
              data-testid={LabelType.DEFAULT}
              type={LabelType.DEFAULT}
              text={t('organizationProfilePlans.default')}
            />
          )}
          {isArchived && (
            <Label
              data-testid={LabelType.ARCHIVED}
              type={LabelType.ARCHIVED}
              text={t('organizationProfilePlans.archived')}
            />
          )}
          <Box p={1}>{actionButton}</Box>
        </Stack>
        <AttributeTable attributes={planAttributes} width="100%" />
      </CardContent>
    </Card>
  )
}
