import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const ProfileMenu: React.FC = () => {
  const { t } = useTranslation()
  const { user, logout } = useAuth0()
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const opened = anchor !== null

  const logOut = () => {
    localStorage.clear()
    logout()
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={({ currentTarget }) => setAnchor(currentTarget)}
        disableRipple
        disableFocusRipple
        sx={{ ':hover': { background: 'none' } }}
      >
        <Typography color="text.secondary" sx={{ fontWeight: 'normal' }}>
          {user?.email}
        </Typography>
        <KeyboardArrowDownIcon fontSize="large" sx={{ color: 'secondary.main' }} />
      </IconButton>
      <Menu
        open={opened}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem onClick={logOut}>{t('applicationBar.profileMenu.logout')}</MenuItem>
      </Menu>
    </>
  )
}
