import { REGIONS } from 'app/lib/constants'
import { colors } from 'app/theme'

import type { Config } from '.'

const hostOverrides: Record<string, Partial<Config>> = {
  'admin-sunlife.us.dialoguecorp.com': {
    auth0: {
      audience: 'https://api.dialoguecorp.com',
      clientId: 'Wpc5DyGI4TbAnus9VOVoYAHZuhldzDhj',
      domain: 'auth.us.dialoguecorp.com',
      connections: {},
    },
  },
  'admin-sunlife-integ.us.dialoguecorp.com': {
    auth0: {
      audience: 'https://api.dialoguecorp.com',
      clientId: 'MfkvX9PVPhsDR1ZrIGB4SURyKF3V8p9P',
      domain: 'auth.us.dialoguecorp.com',
      connections: {},
    },
  },
  'admin-sunlife-demo.us.dialoguecorp.com': {
    auth0: {
      audience: 'https://api.dialoguecorp.com',
      clientId: 'chSh9DQq1eFP0ZlZ8UtQnYnEvSvVNNcd',
      domain: 'auth.us.dialoguecorp.com',
      connections: {},
    },
  },
}

export const productionUsConfig: Config = {
  auth0: {
    audience: 'https://api.dialoguecorp.com',
    clientId: 'mx4cuNicx7sLpxY2gvHnVwum8cQCJiLe',
    domain: 'auth.us.dialoguecorp.com',
    connections: {},
  },
  launchDarkly: {
    clientId: '65fc56bd125aef0fdfce2105',
  },
  intercom: {
    appId: 'argp6vm6',
  },
  scribe: {
    baseUrl: 'https://scribe.us.dialoguecorp.com',
  },
  multipass: {
    baseUrl: 'https://multipass.us.dialoguecorp.com',
  },
  coredata: {
    baseUrl: 'https://coredata.us.dialoguecorp.com',
  },
  activeMinutes: {
    baseUrl: 'https://active-minutes.us.dialoguecorp.com',
  },
  stripePublicKey: 'NOT_SET',
  theme: {
    applicationBar: {
      backgroundColor: colors.dialogueCharcoal,
    },
  },
  assets_url: 'https://assets.us.dialoguecorp.com/maestro/master',
  snowplow: {
    appId: 'presto',
    namespace: 'prod-us',
    endpoint: 'https://lawnmower.us.dialoguecorp.com',
  },
  region: REGIONS.US,
  ...hostOverrides[window.location.hostname],
}
