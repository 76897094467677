import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { Logo } from 'assets/images'

interface UnauthorizedError {
  error: 'unauthorized'
  // from auth0 login rule
  error_description: 'error_email_unverified' | 'error_sending_email_verification'
}

export const AuthCheckPage = () => {
  const { error, isAuthenticated, logout } = useAuth0()

  if (isAuthenticated) {
    // FIXME: improve by using returnTo query param
    return <Navigate to="/" />
  } else if (error) {
    return (
      <AuthErrorDisplay
        // typecasting necessary because actual typing of error from `@auth0/auth0-react` is not correct
        error={error as unknown as UnauthorizedError}
        onLogout={logout}
        onRetry={() => (window.location.href = '/')}
      />
    )
  } else {
    return null
  }
}

interface DisplayProps {
  error: UnauthorizedError
  onLogout: () => void
  onRetry: () => void
}

export const AuthErrorDisplay = ({ error, onLogout, onRetry }: DisplayProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'authCheckPage' })

  const { error_description } = error

  const renderError = () => {
    switch (error_description) {
      case 'error_email_unverified':
        return <UnverifiedError onRetry={onRetry} />
      case 'error_sending_email_verification':
        return <DeliveryError onRetry={onRetry} />
      default:
        return <Error error={error} />
    }
  }

  return (
    <Container disableGutters data-testid="verify-your-email">
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 24 }}>
        <Paper elevation={4}>
          <Stack spacing={3} display="flex" justifyContent="center" sx={{ p: 6 }}>
            <Box display="flex" justifyContent="center">
              <Logo width="30%" title="dialogue logo" />
            </Box>
            {renderError()}
            <Button
              variant="outlined"
              sx={{ alignSelf: 'center' }}
              href="mailto:clients@dialogue.co"
            >
              {t('contactUs')}
            </Button>
            <Button variant="text" sx={{ alignSelf: 'center' }} onClick={onLogout}>
              {t('returnToLogin')}
            </Button>
          </Stack>
        </Paper>
      </Box>
    </Container>
  )
}

interface EmailErrorProps {
  onRetry: () => void
}

const UnverifiedError = ({ onRetry }: EmailErrorProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'authCheckPage' })

  return (
    <>
      <Typography variant="h1" textAlign="center">
        {t('verifyYourEmail')}
      </Typography>
      <Typography variant="body2" textAlign="center">
        {t('weJustSentAnEmail')}
        <br />
        {t('pleaseClickEmailLink')}
      </Typography>
      <Button variant="contained" sx={{ alignSelf: 'center' }} onClick={onRetry}>
        {t('emailVerified')}
      </Button>
    </>
  )
}

const DeliveryError = ({ onRetry }: EmailErrorProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'authCheckPage' })

  return (
    <>
      <Typography variant="h1" textAlign="center">
        {t('couldNotBeDelivered')}
      </Typography>
      <Typography variant="body2" textAlign="center">
        {t('thereWasAnError')}
        <br />
        {t('tryAgainLater')}
      </Typography>
      <Button variant="contained" sx={{ alignSelf: 'center' }} onClick={onRetry}>
        {t('retry')}
      </Button>
    </>
  )
}

interface ErrorProps {
  error: UnauthorizedError
}

const Error = ({ error }: ErrorProps) => (
  <>
    <Typography variant="h1" textAlign="center" textTransform="capitalize">
      {error.error}
    </Typography>
    <Typography variant="body2" textAlign="center">
      {error.error_description}
    </Typography>
  </>
)
