import { combineReducers } from 'redux'

import { activeMinutesApi } from './activeMinutesApi'
import { erApi } from './erApi'
import { multipassApi } from './multipassApi'
import { scribeApi } from './scribeApi'

const rootReducer = combineReducers({
  [scribeApi.reducerPath]: scribeApi.reducer,
  [activeMinutesApi.reducerPath]: activeMinutesApi.reducer,
  [erApi.reducerPath]: erApi.reducer,
  [multipassApi.reducerPath]: multipassApi.reducer,
})

export type ReduxState = ReturnType<typeof rootReducer>

export default rootReducer

// module augmentation so you don't need to specify state
// when using react-redux useSelector types
declare module 'react-redux' {
  export interface DefaultRootState extends ReduxState {}
}
