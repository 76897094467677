import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import { trackPageView } from '@snowplow/browser-tracker'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import Page from 'app/layout/Page'
import { HealthSessionsBanner } from 'app/lib/components/Banner'
import { GetStartedDialog } from 'app/lib/components/GetStarted'
import { NoResults } from 'app/lib/components/NoResults'
import { SearchBar } from 'app/lib/components/SearchBar'
import { listEligibilityRecords, SortOrder } from 'app/lib/legacy-api/scribe.api'
import { organizationAddMember } from 'app/lib/routes'
import { getPlansIdToLabel } from 'app/lib/utils/helpers'
import {
  EligibilityRecord,
  EligibilityRecordStatus,
  Organization,
  OrganizationRoute,
  PaginationV2,
  SignupIdentifier,
} from 'app/models/scribe.models'
import { useGetOrganizationPlansQuery, useLegacyGetOrganizationQuery } from 'app/redux/scribeApi'
import { NoSearchResults } from 'assets/images'

import { CsvUpload } from './csv-upload'
import { ExportButton } from './ExportButton'
import { EligibilityRecordsTable, SearchParams } from './table'

export const DEFAULT_LIMIT = 50

enum Dialog {
  GET_STARTED,
  CSV_UPLOAD,
}

export const OrganizationMemberListPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { getAccessTokenSilently } = useAuth0()
  const { organizationId } = useParams() as OrganizationRoute
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchBarValue, setSearchBarValue] = useState('')
  const [records, setRecords] = useState<ReadonlyArray<EligibilityRecord>>([])
  const [recordsCount, setRecordsCount] = useState<Organization['members']>({
    active: 0,
    future: 0,
    inactive: 0,
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [pagination, setPagination] = useState<PaginationV2>({
    offset: 0,
    total: 0,
  })
  const [currentDialog, setCurrentDialog] = useState<Dialog | null>(null)
  const [showExportButton, setShowExportButton] = useState<boolean>(false)

  const { data: organization, isLoading } = useLegacyGetOrganizationQuery(organizationId)

  const { data: plans = [] } = useGetOrganizationPlansQuery(organizationId)

  let plansIdToLabel: { [_: string]: string } = useMemo(() => getPlansIdToLabel(plans), [plans])
  useEffect(() => {
    if (organization?.members.active || organization?.members.future) {
      setShowExportButton(true)
    } else {
      setShowExportButton(false)
    }
  }, [organization])

  useEffect(() => {
    if (organization) {
      setRecordsCount(organization.members)

      const hasNotAddedMembersYet =
        organization.members.active === 0 &&
        organization.members.future === 0 &&
        organization.members.inactive === 0

      if (hasNotAddedMembersYet) {
        setCurrentDialog(Dialog.GET_STARTED)
      }
    }
  }, [organization])

  const fetchRecords = useCallback(
    (
      page: number,
      status?: string,
      eligible_on_or_after?: string,
      not_eligible_on_or_after?: string,
      search?: string,
    ) => {
      const params = {
        order: SortOrder.DESCENDING,
        offset: DEFAULT_LIMIT * page,
        limit: DEFAULT_LIMIT,
        status,
        eligible_on_or_after,
        not_eligible_on_or_after,
        search,
      }
      setLoading(true)
      getAccessTokenSilently().then((token) =>
        listEligibilityRecords(token, organizationId, params)
          .then(({ records: rec, pagination: pag }) => {
            setRecords(rec)
            setPagination(pag)
            setLoading(false)
          })
          .catch(() => {
            setRecords([])
          })
          .finally(() => setLoading(false)),
      )
    },
    [organizationId, getAccessTokenSilently],
  )

  const fetchNewRecords = useCallback(() => {
    fetchRecords(0, EligibilityRecordStatus.ACTIVE)
  }, [fetchRecords])

  const handleSubmitCSVUpload = () => {
    setCurrentDialog(null)
    fetchNewRecords()
    clearSearch()
  }

  const handleMemberSearch = (search: string) => {
    if (search) {
      setSearchParams({ page: '0', search })
    } else {
      clearSearch()
    }
  }

  const clearSearch = () => {
    setSearchParams({ page: '0', status: EligibilityRecordStatus.ACTIVE })
  }

  useEffect(() => {
    trackPageView({ title: 'organization-eligibility-record-list' })
  }, [])

  useEffect(() => {
    const params: SearchParams = Object.fromEntries(searchParams.entries()) as any
    if (params.search) {
      setSearchBarValue(params.search)
    } else {
      setSearchBarValue('')
    }
  }, [searchParams])

  const organizationMustUseCSV =
    organization?.attributeSchema?.signupIdentifier === SignupIdentifier.PC

  return (
    <Page isLoading={loading}>
      <HealthSessionsBanner />
      {currentDialog === Dialog.GET_STARTED && (
        <GetStartedDialog
          open={currentDialog === Dialog.GET_STARTED}
          onClose={() => setCurrentDialog(null)}
        />
      )}
      {currentDialog === Dialog.CSV_UPLOAD && organization && (
        <CsvUpload
          open={currentDialog === Dialog.CSV_UPLOAD}
          onClose={() => setCurrentDialog(null)}
          onSubmit={handleSubmitCSVUpload}
          organizationType={organization.memberIdType}
          sendEmailEnabled={organization.communicationsAllowed}
          organizationBillingStartDate={organization.billingStartDate}
        />
      )}
      <Typography variant="h1" mb={4} data-testid="page-title">
        {t('eligibilityRecordListPage.pageTitleOf')} {organization?.name}
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <SearchBar
          inputLabel={t('eligibilityRecordListPage.searchForAMember')}
          onSearch={handleMemberSearch}
          search={searchBarValue}
          minSearchTermLength={3}
        />
        <Stack direction="row" spacing={1} flexWrap="wrap">
          {showExportButton && <ExportButton />}
          <Button
            disabled={!organization}
            variant="contained"
            data-testid="import-csv-button"
            onClick={() => setCurrentDialog(Dialog.CSV_UPLOAD)}
          >
            {organizationMustUseCSV
              ? t('csvImport.fileImport.updateMembers')
              : t('csvImport.fileImport.title')}
          </Button>
          {!organizationMustUseCSV && (
            <Button
              disabled={!organization}
              variant="contained"
              data-testid="add-member"
              onClick={() => navigate(organizationAddMember.get(organizationId))}
            >
              {t('eligibilityRecordListPage.addMember')}
            </Button>
          )}
        </Stack>
      </Stack>
      {isLoading || organization ? (
        <Box sx={{ mb: 3, mt: 4 }}>
          <EligibilityRecordsTable
            plansIdToLabel={plansIdToLabel}
            records={records}
            pagination={pagination}
            onChange={fetchRecords}
            recordsCount={recordsCount}
          />
        </Box>
      ) : (
        <Paper sx={{ mb: 3, mt: 4 }} elevation={1}>
          <NoResults
            title={t('organizationListPage.noSearchResults')}
            img={<NoSearchResults title="no_results" />}
          />
        </Paper>
      )}
    </Page>
  )
}
