import { memo, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'

import { Heading } from './Heading'
import { ReportingColumnsProfile } from './ReportingColumnsProfile'
import { ReportingFormData, reportingColumnsSchema } from './schemas'

const baseDefaultValues = {
  name: '',
  attributeSchema: {},
}
interface OrganizationFormProps {
  onCancel: () => void
  onSubmit: (formData: any) => void
  validationSchema: typeof reportingColumnsSchema
  initialValues?: Partial<ReportingFormData> | null
  isLoading?: boolean
  isEdit?: boolean
  isReportingColumns?: boolean
  nonEditableFields?: string[]
}
export const ReportingColumns: React.FC<OrganizationFormProps> = memo(
  ({
    onCancel,
    onSubmit,
    validationSchema,
    initialValues = {},
    isEdit = false,
    isReportingColumns = false,
    isLoading = false,
  }) => {
    const formMethods = useForm<ReportingFormData>({
      defaultValues: {
        ...baseDefaultValues,
        ...initialValues,
      },
      resolver: yupResolver(validationSchema),
    })

    const {
      handleSubmit,
      formState: { isDirty },
      reset,
    } = formMethods

    useEffect(() => {
      if (initialValues) {
        reset({ ...baseDefaultValues, ...initialValues })
      }
    }, [initialValues, reset])

    return (
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={4}>
            <Heading
              isReportingColumns={isReportingColumns}
              isEdit={isEdit}
              isDirty={isDirty}
              onCancel={onCancel}
              isLoading={isLoading}
            />
            <ReportingColumnsProfile
              name={initialValues?.name || ''}
              attributeSchema={initialValues?.attributeSchema || {}}
            />
          </Stack>
        </form>
      </FormProvider>
    )
  },
)
