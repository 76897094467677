import { camelizeKeys } from 'humps'
import Papa from 'papaparse'

import { EligibilityDirective as Directive } from 'app/models/EligibilityRecord'
import { ErrorTypes } from 'app/pages/organization-eligibility-record-list/csv-upload'

import { FR_TO_EN_COLUMNS, translate } from './helpers'

const REQUIRED_HEADERS: { [orgType: string]: string[] } = {
  email: ['uniqueIdentifier'],
  employee_id: ['uniqueIdentifier', 'firstName', 'lastName', 'dateOfBirth'],
}
const RESERVED_COLUMNS = ['employeeId', 'email', 'birthdate', 'adminAreaIsoCode']

export class ValidationError extends Error {
  constructor(
    public type: ErrorTypes,
    public column?: string,
  ) {
    super()
    this.type = type
    this.column = column
  }
}

const validateHeaders = (directives: Directive[], organizationType: string) => {
  // Verify that at least one directive has all the required headers
  const requiredHeaders = REQUIRED_HEADERS[organizationType]
  const directiveHasRequiredHeader = directives.some((directive) => {
    const directiveFields = Object.keys(directive)
    return requiredHeaders.every((requiredHeader) => directiveFields.includes(requiredHeader))
  })
  if (!directiveHasRequiredHeader) {
    throw new ValidationError(ErrorTypes.MISSING_COLUMNS)
  }

  // Verify that none of the directives has a reserved column
  directives.forEach((directive) => {
    RESERVED_COLUMNS.forEach((reservedColumn) => {
      if (reservedColumn in directive) {
        throw new ValidationError(ErrorTypes.RESERVED_ATTRIBUTE, reservedColumn)
      }
    })
  })
}

const parseToJson = (file: File): Promise<Directive[]> => {
  return new Promise((resolve, reject) => {
    Papa.parse<Record<string, string>>(file, {
      header: true,
      encoding: 'utf-8',
      skipEmptyLines: true,
      transformHeader: (header) => translate(header, FR_TO_EN_COLUMNS),
      complete(results) {
        if (results.errors.length) {
          reject(new ValidationError(ErrorTypes.ENCODING))
        }
        const directives = camelizeKeys(results.data) as Directive[]
        resolve(directives)
      },
    })
  })
}

export const parseCsv = async (file: File, organizationType: string): Promise<Directive[]> => {
  if (!file) {
    throw new ValidationError(ErrorTypes.ENCODING)
  }
  const Mb = 1024 ** 2
  if (file.size > 10 * Mb) {
    throw new ValidationError(ErrorTypes.SIZE)
  }
  const directives = await parseToJson(file)
  validateHeaders(directives, organizationType)
  return directives
}
