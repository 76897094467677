import {
  CreditCard,
  EligibilityRecord,
  EngagementReportUrls,
  InvoiceStatus,
  OrganizationInvoice,
  Pagination,
  PaginationV2,
} from 'app/models/scribe.models'
import { getApplicationConfig } from 'config'

import { calculateUsageRange, parseJson, withAuthorization, withSearchParams } from './helpers'
import { V1CreditCard, V1StripeRegistrations, fromV1StripeRegistration } from './v1'
import {
  V2EligibilityRecordListResponse,
  V2FetchOrganizationReportsResponse,
  fromV2EligibilityRecord,
  fromV2FetchOrganizationReports,
  fromV2ListQueryMeta,
} from './v2'

const { scribe } = getApplicationConfig()

type OrganizationInvoiceListV2Response = {
  data: Array<{
    id: string
    total_amount: number
    status: InvoiceStatus
    issue_date: string
    download_link: string
    usage_range: string
  }>
  meta: {
    offset: number
    total_items: number
  }
}

type ListOrganizationInvoicesResponse = {
  organizationInvoices: Array<OrganizationInvoice>
  pagination: Pagination
}

export function listOrganizationInvoices(
  token: string,
  organizationId: string,
  offset: number,
  limit: number,
): Promise<ListOrganizationInvoicesResponse> {
  const url = withSearchParams(`${scribe.baseUrl}/v2/organizations/${organizationId}/invoices`, {
    offset: offset,
    limit: limit,
  })

  return fetch(url, { headers: withAuthorization(token) })
    .then<OrganizationInvoiceListV2Response>(parseJson)
    .then((response) => {
      const organizationInvoices = getOrganizationsInvoicesFromV2(response)
      const pagination = getPaginationFromV2(response)

      return { organizationInvoices, pagination }
    })
}

function getPaginationFromV2({ meta }: OrganizationInvoiceListV2Response): Pagination {
  return {
    offset: meta.offset,
    limit: 10,
    total: meta.total_items,
  }
}

function getOrganizationsInvoicesFromV2(
  response: OrganizationInvoiceListV2Response,
): Array<OrganizationInvoice> {
  return response.data.map<OrganizationInvoice>((datum) => {
    const { id, status, total_amount, issue_date, download_link } = datum
    const usage_range = calculateUsageRange(issue_date)

    const organizationInvoice: OrganizationInvoice = {
      id,
      total_amount: Number(total_amount.toFixed(2)),
      status,
      issue_date,
      download_link,
      usage_range,
    }

    return organizationInvoice
  })
}

export enum SortOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

type ListEligibilityRecordsParams = Readonly<{
  offset: number
  limit: number
  order?: SortOrder
  status?: string
  eligible_on_or_after?: string
  attributes?: Record<string, any>
}>
type ListEligibilityRecordsResponse = Readonly<{
  records: ReadonlyArray<EligibilityRecord>
  pagination: PaginationV2
}>

export function deleteAdministrator(
  token: string,
  organizationId: string,
  administratorId: string,
): Promise<void> {
  const url = `${scribe.baseUrl}/v1/organizations/${organizationId}/administrators/${administratorId}`

  return fetch(url, {
    headers: withAuthorization(token, {}),
    method: 'DELETE',
  }).then<void>(parseJson)
}

export function listEligibilityRecords(
  token: string,
  organizationId: string | null,
  params: ListEligibilityRecordsParams,
): Promise<ListEligibilityRecordsResponse> {
  const url = withSearchParams(`${scribe.baseUrl}/v2/eligibility_records`, {
    ...params,
    organization_id: organizationId,
  })

  return fetch(url, { headers: withAuthorization(token) })
    .then<V2EligibilityRecordListResponse>(parseJson)
    .then((response) => {
      const records = response.data.map(fromV2EligibilityRecord)
      const pagination = fromV2ListQueryMeta(response.meta)
      return { records, pagination }
    })
}

export function registerOrganizationCard(
  token: string,
  organizationId: string,
  stripeToken: string,
): Promise<CreditCard> {
  const url = `${scribe.baseUrl}/v1/organizations/${organizationId}/stripe_registrations`

  const body: V1StripeRegistrations = {
    data: {
      type: 'organization_stripe_registration',
      attributes: { token: stripeToken },
    },
  }

  return fetch(url, {
    headers: withAuthorization(token, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then<{ data: V1CreditCard }>(parseJson)
    .then(({ data }) => fromV1StripeRegistration(data))
}

export function fetchOrganizationReports(
  token: string,
  organizationId: string,
): Promise<EngagementReportUrls> {
  const url = `${scribe.baseUrl}/v2/organizations/${organizationId}/ihp_reports`

  return fetch(url, { headers: withAuthorization(token) })
    .then<V2FetchOrganizationReportsResponse>(parseJson)
    .then((response) => {
      return fromV2FetchOrganizationReports(response.data)
    })
}
