import { memo, useEffect } from 'react'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { trackPageView } from '@snowplow/browser-tracker'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Page from 'app/layout/Page'
import { NoResults } from 'app/lib/components/NoResults'
import { SearchBar } from 'app/lib/components/SearchBar'
import { Manage } from 'assets/images'

import { Table } from './Table'

export const UsersListPage = memo(() => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const querySearchValue = searchParams.get('search')

  const handleMemberSearch = (search: string) => {
    const params = Object.fromEntries(searchParams.entries())
    if (search) setSearchParams({ ...params, search })
    else setSearchParams({})
  }

  useEffect(() => {
    trackPageView({ title: 'user-search' })
  }, [])

  return (
    <Page>
      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={4}>
        <Typography variant="h1" data-testid="page-title" display="flex" alignItems="top">
          {t('usersList.pageTitle')}
          <Tooltip
            title={t('usersList.titleTooltip')}
            componentsProps={{ tooltip: { sx: { maxWidth: 'none' } } }}
            arrow
          >
            <HelpOutlineIcon sx={{ fontSize: 14, ml: 0.5, mt: 1, cursor: 'pointer' }} />
          </Tooltip>
        </Typography>
        <Box>
          <SearchBar
            inputLabel={t('usersList.searchLabel')}
            onSearch={handleMemberSearch}
            search={querySearchValue || ''}
          />
        </Box>
      </Stack>
      <Box
        sx={{
          mb: 3,
          mt: 4,
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {querySearchValue ? (
          <Table />
        ) : (
          <NoResults
            title={t('usersList.noResults.searchUser')}
            subtitle={t('usersList.noResults.typeInTheSearch')}
            img={<Manage title="no_member_found" />}
          />
        )}
      </Box>
    </Page>
  )
})
