import { useCallback, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Stack } from '@mui/material'
import { trackPageView } from '@snowplow/browser-tracker'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Page from 'app/layout/Page'
import { HealthSessionsBanner } from 'app/lib/components/Banner'
import { NoResults } from 'app/lib/components/NoResults'
import { registerOrganizationCard } from 'app/lib/legacy-api/scribe.api'
import { Organization, OrganizationRoute } from 'app/models/scribe.models'
import { useLegacyGetOrganizationQuery } from 'app/redux/scribeApi'
import { NoSearchResults } from 'assets/images'

import { OrganizationAdmins } from './admin'
import { OrganizationInfo } from './Info'
import { OrganizationPayment } from './Payment'
import { OrganizationPlans } from './plan'

type Props = Readonly<{
  organization: Organization
  onCreditCardSubmit: (tokenId: string) => Promise<void>
}>

const Profile: React.FC<Props> = ({ organization, onCreditCardSubmit }) => {
  return (
    <>
      <HealthSessionsBanner />
      <Stack spacing={5}>
        <OrganizationInfo organization={organization} />
        {organization.chargebeeId && organization.billingMethod === 'credit_card' && (
          <OrganizationPayment
            creditCard={organization.defaultCreditCard}
            onCreditCardSubmit={onCreditCardSubmit}
          />
        )}
        <OrganizationAdmins />
        <OrganizationPlans organizationId={organization.id} />
      </Stack>
    </>
  )
}

export const OrganizationProfilePage: React.FC = () => {
  const { t } = useTranslation()
  const { getAccessTokenSilently } = useAuth0()
  const { organizationId } = useParams() as OrganizationRoute

  const {
    data: organization,
    isLoading,
    isError,
    refetch,
  } = useLegacyGetOrganizationQuery(organizationId)

  useEffect(() => {
    trackPageView({ title: 'organization-profile' })
  }, [])

  const handleCreditCardSubmit = useCallback(
    (tokenId: string): Promise<any> => {
      return getAccessTokenSilently().then((token) => {
        return registerOrganizationCard(token, organizationId, tokenId)
          .then((response) => {
            if (!response.id) throw Error
          })
          .then(refetch().unwrap)
      })
    },
    [getAccessTokenSilently, refetch, organizationId],
  )

  let content = null

  if (isError) {
    content = (
      <NoResults
        title={t('organizationListPage.noSearchResults')}
        img={<NoSearchResults title="no_results" />}
      />
    )
  } else if (organization) {
    content = <Profile organization={organization} onCreditCardSubmit={handleCreditCardSubmit} />
  }

  return <Page isLoading={isLoading}>{content}</Page>
}
