import { REGIONS } from 'app/lib/constants'
import { colors } from 'app/theme'

import type { Config } from '.'

export const productionConfig = Object.freeze<Config>({
  auth0: {
    audience: 'https://api.dialoguecorp.com',
    clientId: 'ArDCt32ocvqgkvSa4eecdaNG7XNPN3ie',
    domain: 'auth.dialogue.co',
    connections: {
      adp: 'adp-admin',
    },
  },
  launchDarkly: {
    clientId: '6295189ae11a2e14fd1b74cf',
  },
  intercom: {
    appId: 'argp6vm6',
  },
  scribe: {
    baseUrl: 'https://scribe.dialoguecorp.com',
  },
  multipass: {
    baseUrl: 'https://multipass.dialoguecorp.com',
  },
  coredata: {
    baseUrl: 'https://coredata.dialoguecorp.com',
  },
  activeMinutes: {
    baseUrl: 'https://active-minutes.dialoguecorp.com',
  },
  stripePublicKey: 'pk_live_mMeepfha0s3atBB59RWG9mIj',
  theme: {
    applicationBar: {
      backgroundColor: colors.dialogueCharcoal,
    },
  },
  assets_url: 'https://assets.dialoguecorp.com/maestro/master',
  snowplow: {
    appId: 'presto',
    namespace: 'prod-ca',
    endpoint: 'https://lawnmower.dialoguecorp.com',
  },
  region: REGIONS.CANADA,
})
