import { format } from 'date-fns'

import { OrganizationAttributes } from 'app/models/Organization'
import { DateOfBirthType } from 'app/models/scribe.models'
import { OrganizationFormData } from 'app/pages/organization-profile/form/schemas'

const ignoredFields = ['accountingMethod']
const billingFields = ['billingEmail', 'billingMethod', 'billingStartDate']

export const generateOrganizationAttributes = (
  formData: Partial<OrganizationFormData>,
  isEdit: boolean = false,
  stripBillingDetails: boolean = false,
): Partial<OrganizationAttributes> => {
  const {
    displayNameEnglish,
    displayNameFrench,
    enrolmentCode,
    invitationEmailTime,
    dateOfBirthType,
    ...rest
  } = formData

  const hasEnglishDisplayName = displayNameEnglish !== undefined
  const hasFrenchDisplayName = displayNameFrench !== undefined
  const hasEnrolmentCode = enrolmentCode !== undefined

  const attributes = rest as Partial<OrganizationAttributes>

  if (hasEnglishDisplayName || hasFrenchDisplayName) {
    attributes.displayName = {
      en: displayNameEnglish,
      fr: displayNameFrench,
    }
  }

  if (hasEnrolmentCode) {
    attributes.meta = {
      organizationEnrolmentCode: enrolmentCode || null,
    }
  }

  if (invitationEmailTime) {
    attributes.invitationEmailTime = format(invitationEmailTime, 'HH:mm:ss')
  }

  if (dateOfBirthType == DateOfBirthType.PARTIAL_DATE_OF_BIRTH) {
    attributes.dateOfBirthType = DateOfBirthType.PARTIAL_DATE_OF_BIRTH
  }

  const safeAttributes: Record<string, any> = {}

  Object.entries(attributes).forEach((elem) => {
    // Removes general fields to be ignored
    if (ignoredFields.includes(elem[0])) {
      return
    }

    // Need to strip billing values from the form for users who
    // aren't allowed to update them. IDEALLY they wouldn't
    // be included in the form on page load at all, but the async
    // fetching of user permissions requires them to be included.
    // See DIA-67440 for the bug details.
    if (stripBillingDetails && billingFields.includes(elem[0])) {
      return
    }

    safeAttributes[elem[0]] = elem[1]
  })

  return isEdit ? safeAttributes : attributes
}
