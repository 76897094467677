import React from 'react'

import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'app/lib/utils/date'
import { useGetUserQuery } from 'app/redux/scribeApi'

type Props = {
  userId: string
}

type Column = {
  label: string
  value: string | undefined
}

export const UserInfoTable: React.FC<Props> = ({ userId }) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const { data: user } = useGetUserQuery(userId)

  const firstColumn: Column[] = [
    { label: t('userProfile.info.userId'), value: user?.id },
    { label: t('userProfile.info.firstName'), value: user?.firstName },
    { label: t('userProfile.info.lastName'), value: user?.lastName },
    {
      label: t('userProfile.info.registrationStatus'),
      value: user?.authId ? t('userProfile.info.signedUp') : t('userProfile.info.notSignedUp'),
    },
  ]

  const secondColumn: Column[] = [
    { label: t('userProfile.info.preferredName'), value: user?.preferredName },
    {
      label: t('userProfile.info.dateOfBirth'),
      value: user?.dateOfBirth && formatDate(user?.dateOfBirth, resolvedLanguage),
    },
    { label: t('userProfile.info.phoneNumber'), value: user?.phoneNumber },
    {
      label: t('userProfile.info.language'),
      value: user?.language && t(`userProfile.language.${user?.language}`),
    },
  ]

  const columns = [firstColumn, secondColumn]

  return (
    <Grid container spacing={2}>
      {columns.map((columnData, index) => (
        <Grid container item xs={12} md={6} spacing={2} key={index}>
          {columnData.map(({ label, value }) => (
            <React.Fragment key={label}>
              <Grid item xs={6} md={4}>
                <Typography variant="subtitle2">{label}:</Typography>
              </Grid>
              <Grid item xs={6} md={8}>
                <Typography variant="body2">{value}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ))}
    </Grid>
  )
}
