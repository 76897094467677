import { MixedSchema, date, mixed, number, string } from 'yup'

export const requiredStringSchema = string()
  .trim('form.errors.required')
  .required('form.errors.required')

export const emailSchema = string().email('form.errors.invalid_email')

export const requiredEmailSchema = requiredStringSchema.email('form.errors.invalid_email')

export const dateSchema = date().nullable().typeError('form.errors.invalidDate')

export const requiredDateSchema = dateSchema.required('form.errors.required')

export const numericSchema = number().typeError('form.errors.invalidNumber')

export const fileSchema: MixedSchema<any> = mixed().required('form.errors.required')
