export const colors = Object.freeze({
  darkGrey: 'rgba(0, 0, 0, 0.3)',
  dialogueCharcoal: '#212020',
  dialogueLinen: '#FFEFE2',
  dirtyLinen: '#CCBFB4',
  dialoguePeach: 'rgba(236, 152, 118, 1)',
  dialogueGreen: 'rgba(39, 197, 149, 1)',
  alarm: '#DC0505',
  navy: '#103767',
  lightGrey2: 'rgba(229, 237, 239, 1)',
  darkSlate: '#373736',
  tertiary: '#636362',
  silver: '#BCBCBC',
  smoke: '#F4F4F4',
  snow: '#FFFCFA',
  sky: '#C4E6F1',
  white: '#FFFFFF',
})
