import { TableRow } from '@mui/material'
import { useHref, useNavigate } from 'react-router'

type ClickableRowProps = Readonly<{
  to: any
  testId?: number
  children?: React.ReactNode
  newTabOnClick?: boolean
}>

export const ClickableRow: React.FC<ClickableRowProps> = (props) => {
  const { to, children, newTabOnClick } = props
  const navigate = useNavigate()
  const hrefTo = useHref(to)

  const handleRowClick = (e: React.MouseEvent, path: any) => {
    if (e.ctrlKey || e.metaKey) {
      // Mimick ctrl-click / cmd-click behavior
      window.open(hrefTo, '_blank')
    } else if (newTabOnClick) {
      window.open(hrefTo, '_blank')
    } else {
      navigate(path)
    }
  }
  return (
    <TableRow hover onClick={(e) => handleRowClick(e, to)} data-testid={props.testId}>
      {children}
    </TableRow>
  )
}
