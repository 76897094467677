import { useCallback, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, CardMedia, Typography, styled } from '@mui/material'
import { useNavigate } from 'react-router'

import { colors } from 'app/theme'
import { Logo } from 'assets/images'
import { WelcomeVideo } from 'assets/videos'
import { getApplicationConfig } from 'config'

import ConnectionError from './ConnectionError'

interface BaseConnectionProps {
  buttonText: string
  connection: string
  title: string
  customPopup?: {
    height: string
    width: string
  }
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  video: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    maskImage: 'linear-gradient(black 70%, transparent 99%)',
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',

    video: {
      maskImage: 'linear-gradient(black 50%, transparent 80%)',
    },
  },
}))

enum TOLERATED_ERRORS {
  POPUP_BLOCKED = 'Unable to open a popup',
  POPUP_CLOSED = 'Popup closed',
}

const errorIsTolerated = (error?: Error): boolean => {
  const allowedErrorMessages = [TOLERATED_ERRORS.POPUP_BLOCKED, TOLERATED_ERRORS.POPUP_CLOSED]

  return allowedErrorMessages.some((msg) => error?.message.includes(msg))
}

const config = getApplicationConfig()
const auth0Connections = config.auth0.connections

const BaseConnection = ({ title, buttonText, connection, customPopup }: BaseConnectionProps) => {
  const navigate = useNavigate()
  const { error, isAuthenticated, isLoading, loginWithPopup } = useAuth0()

  const handleLogin = useCallback(() => {
    loginWithPopup(
      {
        authorizationParams: {
          connection: auth0Connections[connection],
        },
      },
      customPopup
        ? { popup: window.open('', '', `height=${customPopup.height},width=${customPopup.width}`) }
        : {},
    )
  }, [connection, customPopup, loginWithPopup])

  useEffect(() => {
    if (isAuthenticated) return navigate('/')
    handleLogin()
  }, [isAuthenticated, handleLogin, navigate])

  if (error && !errorIsTolerated(error) && !isLoading) {
    return <ConnectionError onLogin={handleLogin} />
  }

  return (
    <Container>
      <Box
        display="flex"
        bgcolor={colors.dialogueLinen}
        height={{ xs: '70%', md: '100%' }}
        width={{ xs: '100%', md: '50%' }}
      >
        <CardMedia src={WelcomeVideo} component="video" autoPlay muted loop />
      </Box>
      <Box
        display="flex"
        bgcolor={{ xs: colors.dialogueLinen, md: 'unset' }}
        height={{ xs: '30%', md: '100%' }}
        width={{ xs: '100%', md: '50%' }}
        alignItems="center"
        justifyContent="center"
      >
        <Box display="flex" flexDirection="column" alignItems="center" mt={{ xs: -10, md: 0 }}>
          <Logo />
          <Typography variant="h3" pt={2} pb={{ xs: 2, md: 6 }}>
            {title}
          </Typography>
          <Button variant="contained" onClick={handleLogin} sx={{ alignSelf: 'auto' }}>
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default BaseConnection
