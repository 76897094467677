import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHref } from 'react-router'

import { Attribute, AttributeTable } from 'app/lib/components/AttributeTable'
import { userProfile } from 'app/lib/routes'
import { formatDate } from 'app/lib/utils/date'
import { UserAccountDetails } from 'app/models/scribe.models'

type Props = {
  userAccountDetails: UserAccountDetails
}

export const UserInfo: React.FC<Props> = ({ userAccountDetails }) => {
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()
  const hrefTo = useHref(userProfile.get(userAccountDetails.id))

  const navigateToUserProfile = () => {
    window.open(hrefTo, '_blank')
  }

  const userAccountAttributes: Attribute[] = [
    {
      key: 'email',
      name: t('userAccount.loginEmail'),
      value: userAccountDetails.email,
    },
    {
      key: 'firstName',
      name: t('userAccount.firstName'),
      value: userAccountDetails.firstName,
    },
    { key: 'lastName', name: t('userAccount.lastName'), value: userAccountDetails.lastName },
    {
      key: 'dateOfBirth',
      name: t('userAccount.dateOfBirth'),
      value:
        userAccountDetails.dateOfBirth &&
        formatDate(userAccountDetails.dateOfBirth, resolvedLanguage),
    },
    {
      key: 'registrationStatus',
      name: t('userAccount.registrationStatus'),
      value: userAccountDetails.authId
        ? t('userProfile.info.signedUp')
        : t('userProfile.info.notSignedUp'),
    },
  ]

  return (
    <Card sx={{ pb: 1 }}>
      <CardContent
        sx={{
          height: '100%',
          '&.MuiCardContent-root': {
            p: 0.5,
            pb: 0,
          },
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3" p={2} pb={3}>
            {userAccountDetails.id}
          </Typography>
          <Button
            onClick={() => navigateToUserProfile()}
            variant="link"
            sx={{ alignSelf: 'end', padding: '24px 32px' }}
          >
            {t('userAccount.viewUser')}
          </Button>
        </Stack>
        <AttributeTable attributes={userAccountAttributes} width="100%" />
      </CardContent>
    </Card>
  )
}
