import { Navigate, Route, Routes } from 'react-router'

import connections from './connections'

export const Login = () => (
  <Routes>
    {Object.entries(connections).map(([key, { Component }]) => (
      <Route key={key} path={`/${key}`} element={<Component />} />
    ))}
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
)
