import { createApi } from '@reduxjs/toolkit/query/react'

import customBaseQuery from 'app/lib/utils/customBaseQuery'
import { Challenge, ChallengeLeaderboard } from 'app/models/ActiveMinutes'
import { Collection, Item } from 'app/models/api'
import { getApplicationConfig } from 'config'

const {
  activeMinutes: { baseUrl: activeMinutesBaseUrl },
} = getApplicationConfig()

export const activeMinutesApi = createApi({
  reducerPath: 'activeMinutesApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    listChallenges: builder.query<Challenge[], { organizationId: string }>({
      query: ({ organizationId }) => ({
        url: `${activeMinutesBaseUrl}/v1/organizations/${organizationId}/challenges`,
        headers: new Headers({ 'X-Timezone-Offset': String(new Date().getTimezoneOffset()) }),
      }),
      transformResponse: (response: Collection<Challenge>) => response.data,
    }),
    listChallengeLeaderboard: builder.query<
      ChallengeLeaderboard,
      { organizationId: string; challengeId: string }
    >({
      query: ({ organizationId, challengeId }) => ({
        url: `${activeMinutesBaseUrl}/v1/organizations/${organizationId}/challenges/${challengeId}/leaderboard`,
      }),
      transformResponse: (response: Item<ChallengeLeaderboard>) => response.data,
    }),
  }),
})

export const { useListChallengesQuery, useListChallengeLeaderboardQuery } = activeMinutesApi
