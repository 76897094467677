import { useMemo, useState } from 'react'

import { DatePicker, DateValidationError } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'

interface Props {
  activationDate: Date | null
  activationMinDate: Date
  onActivationDateChange: (date: Date | null) => void
  onValidate: (isValid: boolean) => void
}

export const MemberActivationDate = ({
  activationDate,
  activationMinDate,
  onActivationDateChange,
  onValidate,
}: Props) => {
  const { t } = useTranslation()
  const [error, setError] = useState<DateValidationError | null>(null)

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'minDate': {
        return t('global.notAllowedDate')
      }

      case 'invalidDate': {
        return t('global.invalidDate')
      }

      default: {
        return ''
      }
    }
  }, [error, t])

  const handleError = (newError: DateValidationError | null) => {
    setError(newError)
    onValidate(!newError)
  }

  return (
    <DatePicker
      format="yyyy-MM-dd"
      views={['year', 'month', 'day']}
      openTo="month"
      value={activationDate}
      onChange={onActivationDateChange}
      minDate={activationMinDate}
      onError={handleError}
      slotProps={{
        field: {
          clearable: true,
        },
        textField: {
          helperText: errorMessage,
          variant: 'outlined',
          size: 'small',
          sx: {
            maxWidth: '320px',
            my: 1,
          },
        },
      }}
    />
  )
}
