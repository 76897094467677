import Adp from './connection-pages/ADP'
import Cognito from './connection-pages/Cognito'
import Okta from './connection-pages/Okta'

export enum CONNECTION_TYPES {
  COGNITO = 'cognito',
  OKTA = 'okta',
  ADP = 'adp',
}

interface Connection {
  Component: React.ComponentType
}

const connections: Record<string, Connection> = {
  [CONNECTION_TYPES.COGNITO]: {
    Component: Cognito,
  },
  [CONNECTION_TYPES.OKTA]: {
    Component: Okta,
  },
  [CONNECTION_TYPES.ADP]: {
    Component: Adp,
  },
}

export default connections
