import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SearchBar } from 'app/lib/components/SearchBar'

type OrganizationsToolbarProps = Readonly<{
  onSearch: (text: string) => void
}>

export const OrganizationsToolbar: React.FC<OrganizationsToolbarProps> = ({ onSearch }) => {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid item xs={6}>
        <SearchBar
          inputLabel={t('organizationListPage.searchForAnOrganization')}
          onSearch={onSearch}
        />
      </Grid>
    </Grid>
  )
}
