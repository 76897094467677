import { Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

interface WithEditModeFieldProps {
  name: string
  isEditMode?: boolean
}

function withEditModeField<P>(
  WrappedComponent: React.FC<Omit<WithEditModeFieldProps & P, 'isEditMode'>>,
) {
  return ({ isEditMode = false, ...rest }: WithEditModeFieldProps & P) => {
    const { watch } = useFormContext()
    const fieldValue = watch(rest.name)
    if (isEditMode) return <WrappedComponent {...rest} />
    return <Typography variant="body2">{fieldValue}</Typography>
  }
}

export default withEditModeField
