import { FormEvent, useEffect, useState } from 'react'

import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  inputLabel: string
  onSearch: (text: string) => void
  search?: string
  autofocus?: boolean
  minSearchTermLength?: number
}>

export const SearchBar: React.FC<Props> = ({
  inputLabel,
  onSearch,
  search,
  autofocus,
  minSearchTermLength,
}) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const { t } = useTranslation()

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (minSearchTermLength && searchValue.trim().length > 0) {
      const validSearchTerms = searchValue
        .split(/\s+/)
        .filter((v) => v.length >= minSearchTermLength)
      if (validSearchTerms.length === 0) {
        setError(
          t('global.searchTermsTooSimpleError', { minSearchTermLength: minSearchTermLength }),
        )
        return
      }
    }
    setError(undefined)
    onSearch(searchValue)
  }

  useEffect(() => {
    if (search) {
      setSearchValue(search)
    } else {
      setSearchValue('')
    }
  }, [search])

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'background.default',
        borderRadius: '50px',
        minWidth: '500px',
        pl: 3,
      }}
      component="form"
      onSubmit={onSubmit}
    >
      <TextField
        fullWidth
        variant="filled"
        label={inputLabel}
        value={searchValue}
        error={error !== undefined}
        helperText={error}
        onChange={(event) => setSearchValue(event.target.value)}
        autoFocus={autofocus}
        InputProps={{
          endAdornment: searchValue && (
            <IconButton
              onClick={() => {
                setSearchValue('')
                onSearch('')
              }}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          ),
          className: 'Searchbar-Textfield',
          disableUnderline: true,
          sx: {
            input: {
              pt: 2,
            },
          },
        }}
        InputLabelProps={{
          sx: {
            transform: 'translate(12px,13px) scale(1)',
            '&.Mui-focused, &.MuiFormLabel-filled': {
              transform: 'translate(12px,7px) scale(0.75)',
            },
          },
        }}
        inputProps={{
          'data-testid': 'search-input',
        }}
      />
      <Button
        disabled={!searchValue.trim()}
        variant="contained"
        type="submit"
        sx={{ minWidth: '50px', maxWidth: '50px', height: '50px', borderRadius: '50%' }}
        data-testid="search-submit"
      >
        <SearchIcon fontSize="small" sx={{ p: 0 }} />
      </Button>
    </Box>
  )
}
