import { useEffect, useMemo } from 'react'

import { Button, Grid, Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PlanCard } from 'app/lib/components/plans/PlanCard'
import { getDefaultAndOtherPlans, isArchived } from 'app/models/Organization'
import { Plan } from 'app/models/scribe.models'

import { FormViewTypes } from '.'

interface SelectPlanProps {
  plans: Plan[]
  onChangeView: (view: string) => void
}

export const SelectPlan: React.FC<SelectPlanProps> = ({ plans, onChangeView }) => {
  const [t] = useTranslation()

  const { setValue, watch } = useFormContext()
  const selectedPlanLabel = watch('plan')

  const [defaultPlan, otherPlans = []] = useMemo(() => {
    const activePlans = plans.filter((el) => !isArchived(el))
    return getDefaultAndOtherPlans(activePlans)
  }, [plans])

  const onCancel = () => onChangeView(FormViewTypes.GeneralInfo)

  const onSelectPlan = (label: string) => {
    if (selectedPlanLabel !== label) {
      setValue('plan', label)
    }
    onCancel()
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <Button variant="outlined" onClick={onCancel}>
          {t('global.dialog.cancel')}
        </Button>
      </Stack>
      {defaultPlan && (
        <>
          <Typography variant="h3">{t('organizationProfilePlans.defaultPlan')}</Typography>
          <Grid container>
            <Grid item xs={12} md={6} xxl={4}>
              <PlanCard
                plan={defaultPlan}
                actionButton={
                  <Button
                    variant="contained"
                    onClick={() => onSelectPlan(defaultPlan.attributes.label)}
                  >
                    {t('memberChangePlan.selectButton')}
                  </Button>
                }
              />
            </Grid>
          </Grid>
        </>
      )}
      <Stack>
        <Typography variant="h3" mb={3}>
          {t('addEditMemberDialog.planSection.otherPlans')}
        </Typography>
        <Grid container spacing={2}>
          {otherPlans.map((plan) => (
            <Grid item key={plan.id} xs={12} md={6} xxl={4}>
              <PlanCard
                plan={plan}
                selectable
                actionButton={
                  <Button variant="contained" onClick={() => onSelectPlan(plan.attributes.label)}>
                    {t('memberChangePlan.selectButton')}
                  </Button>
                }
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  )
}
