import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { TabPanel } from '@mui/lab'
import { Box, Button, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { object } from 'yup'

import CheckboxFormField from 'app/lib/components/form/CheckboxFormField'
import InputFormField from 'app/lib/components/form/InputFormField'
import { NoResults } from 'app/lib/components/NoResults'
import { UserInfoTable } from 'app/lib/components/UserInfoTable'
import { userProfile } from 'app/lib/routes'
import { requiredStringSchema } from 'app/lib/utils/yupSchemas'
import { FamilyMemberType } from 'app/models/scribe.models'
import { useAddExistingFamilyMemberMutation, useGetUserQuery } from 'app/redux/scribeApi'
import { NoSearchResults } from 'assets/images'

type Values = {
  targetUserId: string
  isGuardian?: boolean
}

const defaultValues: Values = {
  targetUserId: '',
  isGuardian: true,
}

const schema = object({
  targetUserId: requiredStringSchema,
}).required()

export const ExistingUserTab = () => {
  const { t } = useTranslation()
  const { userId = '' } = useParams()
  const [memberId, setMemberId] = useState('')
  const { data: user, error } = useGetUserQuery(memberId)
  const [searchParams] = useSearchParams()
  const isAdultType = searchParams.get('type') === FamilyMemberType.ADULT
  const [addExistingFamilyMember] = useAddExistingFamilyMemberMutation()
  const navigate = useNavigate()
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const watch = useMemo(() => methods.watch, [methods.watch])
  const showError = Boolean(error)

  const fetch = useCallback(
    (inputValue: string) => {
      setMemberId(inputValue)
    },
    [setMemberId],
  )

  const processChanges = useMemo(() => debounce(fetch, 600), [fetch])

  const navigateToUserProfile = () => {
    navigate(userProfile.get(userId))
  }

  const onInputChange = useCallback(
    (value: string) => {
      const inputValue = value.trimStart()
      processChanges(inputValue)
    },
    [processChanges],
  )

  const onSubmit = (formData: Values) => {
    const { targetUserId, isGuardian } = formData
    let type: FamilyMemberType
    if (isAdultType) {
      type = isGuardian ? FamilyMemberType.GUARDIAN : FamilyMemberType.DEPENDENT
    } else {
      type = FamilyMemberType.CHILD
    }
    const body = {
      userId: targetUserId,
      type,
    }
    addExistingFamilyMember({ userId, body })
      .unwrap()
      .then(() => {
        navigateToUserProfile()
      })
  }

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // This is how correctly track input changes inside the form based on a documentation.
      if (name === 'targetUserId') onInputChange(value.targetUserId || '')
    })
    return () => subscription.unsubscribe()
  }, [watch, onInputChange])

  return (
    <TabPanel value="existingUser" sx={{ p: 4, maxWidth: 700 }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <InputFormField
            label={t('form.labels.userId')}
            helperText={t('form.helpText.matchMemberRecords')}
            name="targetUserId"
            sx={{ my: 4 }}
            required
          />
          {isAdultType && (
            <CheckboxFormField name="isGuardian" label={t('form.labels.guardianMember')} />
          )}
          {showError ? (
            <NoResults
              title={t('userProfile.notFound')}
              img={<NoSearchResults title="no_results" />}
            />
          ) : (
            user?.id && (
              <>
                <Typography variant="subtitle1" my={6} color="text.disabled">
                  {t('addMember.hereWhatWeFound')}
                </Typography>
                <UserInfoTable userId={user.id} />
              </>
            )
          )}
          <Box display="flex" justifyContent="flex-end" gap={2} mt={6}>
            <Button variant="outlined" onClick={navigateToUserProfile}>
              {t('global.dialog.cancel')}
            </Button>
            <Button variant="contained" type="submit">
              {t('addMember.actionAddToFamily')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </TabPanel>
  )
}
