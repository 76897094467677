import { yupResolver } from '@hookform/resolvers/yup'
import { TabPanel } from '@mui/lab'
import { Box, Button } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { object } from 'yup'

import DatePickerFormField from 'app/lib/components/form/DatePickerFormField'
import InputFormField from 'app/lib/components/form/InputFormField'
import { userProfile } from 'app/lib/routes'
import { minChildDateOfBirth, today, toISOdate } from 'app/lib/utils/date'
import { requiredDateSchema, requiredStringSchema } from 'app/lib/utils/yupSchemas'
import { FamilyMemberType } from 'app/models/scribe.models'
import { useAddChildFamilyMemberMutation } from 'app/redux/scribeApi'

type Values = { firstName: string; lastName: string; birthdate: Date }

const defaultValues: Values = {
  firstName: '',
  lastName: '',
  birthdate: new Date(),
}

const schema = object({
  firstName: requiredStringSchema,
  lastName: requiredStringSchema,
  birthdate: requiredDateSchema
    .max(today(), 'form.errors.invalidDateOfBirthFuture')
    .min(minChildDateOfBirth(), 'form.errors.invalidChildDateOfBirth'),
}).required()

export const NewChildUserTab = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userId } = useParams() as { userId: string }
  const [addChildFamilyMember] = useAddChildFamilyMemberMutation()

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const navigateToUserProfile = () => {
    navigate(userProfile.get(userId))
  }
  const onSubmit = (formData: Values) => {
    const body = {
      data: {
        type: FamilyMemberType.CHILD,
        attributes: {
          ...formData,
          birthdate: toISOdate(formData.birthdate as unknown as Date),
        },
      },
    }
    addChildFamilyMember({ userId, body })
      .unwrap()
      .then(() => {
        navigateToUserProfile()
      })
  }

  return (
    <TabPanel value="newUser" sx={{ p: 4, maxWidth: 700 }}>
      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <Box
            sx={{
              p: 4,
              maxWidth: 700,
              gap: 3,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <InputFormField name="firstName" label={t('form.labels.firstName')} required />
            <InputFormField name="lastName" label={t('form.labels.lastName')} required />
            <InputFormField name="preferredName" label={t('form.labels.preferredName')} />
            <DatePickerFormField name="birthdate" label={t('form.labels.dateOfBirth')} required />
            <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
              <Button variant="outlined" onClick={() => navigate(userProfile.get(userId))}>
                {t('global.dialog.cancel')}
              </Button>
              <Button variant="contained" type="submit">
                {t('addMember.actionAddToFamily')}
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </TabPanel>
  )
}
